import React, { useState } from 'react';
import { useEffectOnce } from '../useEffectOnce';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AppleButton from './AppleButtonS';
import GoogleButton from './GoogleButtonS';
import bcrypt from 'bcryptjs-react';

async function createUser(credentials) {
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  return fetch(API_URL + 'pj/createAccount', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'token': 'XkjQzRQEsm2Ptvcj6kd6LqWL' },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

export default function Signup () {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(0);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(0);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(0);
  const [passwordLength, setPasswordLength] = useState(0);
  const [passwordUpper, setPasswordUpper] = useState(0);
  const [passwordNumber, setPasswordNumber] = useState(0);
  const [passwordSpecial, setPasswordSpecial] = useState(0);
  const [password2, setPassword2] = useState('');
  const [password2Error, setPassword2Error] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [betaSignup, setBetaSignup] = useState(0);
  const salt = bcrypt.genSaltSync(10);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  useEffectOnce(() => {
    // If key parameter exists in URL, call function to check that key is valid
    if (params.get('key') !== undefined && params.get('key') !== null && params.get('key') !== '' && params.get('key').length === 36) {
      checkKey();
    }
    else navigate('/enroll');
  });

  const checkKey = e => {
    const keyData = { key: params.get('key') };
    axios.post(API_URL + `pj/checkKey`, keyData, { headers: { 'token': 'XkjQzRQEsm2Ptvcj6kd6LqWL' } })
         .then((keyData) => { if (Object.keys(keyData.data).length > 0 && keyData.data.length > 0 && keyData.data[0].used === 0) setBetaSignup(1); else navigate('/enroll'); });
  }

  const handleSubmit = async e => {
    e.preventDefault();

    // Declare variables and clear errors
    var createAccount = true;
    setNameError(0);
    setEmailError(0);
    setPasswordError(0);
    setPasswordLength(0);
    setPasswordUpper(0);
    setPasswordNumber(0);
    setPasswordSpecial(0);
    setPassword2Error(0);
    setErrorMsg('');
    setAccountCreated(false);

    // If passwords don't match
    if (password !== password2) { setErrorMsg("Passwords do not match"); setPassword2Error(1); createAccount = false; }
    // If password doesn't meet complexity requirements
      // Length
      if (password.length < 8) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordLength(1); createAccount = false; }
      // Uppercase
      if (!testUppercase(password)) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordUpper(1); createAccount = false; }
      // Number
      if (!testNumber(password)) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordNumber(1); createAccount = false; }
      // Special Character
      if (!testSpecial(password)) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordSpecial(1); createAccount = false; }
      // Illegal Character
      if (testIllegal(password)) { setErrorMsg('illegal'); setPasswordError(1); setPassword2Error(1); createAccount = false; }
    // If email is not valid
    // if (email.trim().indexOf('@') < 1 || email.trim().length < 5 || email.trim().indexOf('.') < 1) { setErrorMsg("Enter valid email"); setEmailError(1); createAccount = false; }
    if (emailValidator(email.trim()) === null) { setErrorMsg("Enter valid email"); setEmailError(1); createAccount = false; }

    // If name is empty
    if (name.trim().length < 1) { setErrorMsg("All fields must be filled"); setNameError(1); createAccount = false; }
    // If email is empty
    if (email.trim().length < 1) { setErrorMsg("All fields must be filled"); setEmailError(1); createAccount = false; }
    // If password is empty
    if (password.length < 1) { setErrorMsg("All fields must be filled"); setPasswordError(1); createAccount = false; }
    // If password2 is empty
    if (password2.length < 1) { setErrorMsg("All fields must be filled"); setPassword2Error(1); createAccount = false; }
    
    // If all criteria met, create account
    if (createAccount) {
      const account = await createUser({
        name: name.trim().replace(/\b[a-z]/g, function(letter) { return letter.toUpperCase(); }),
        email: email.trim().toLowerCase(),
        password: bcrypt.hashSync(password, salt),
        beta_key: params.get('key')
      });

      // If account already exists, inform user
      if (Object.keys(account).length > 0 && account.uuid === 'EXISTS') setErrorMsg("Account with email already exists");
      // If account already exists, inform user
      //TODO: After beta, remove with everything attached to this
      else if (Object.keys(account).length > 0 && account.uuid === 'USED') setErrorMsg("Beta key has already been used. Please join the waitlist or contact support if this is a mistake");
      // If account created, clear fields
      //TODO: After beta, forward right to login
      else if (Object.keys(account).length > 0 && account.uuid.length > 0) {
        document.getElementById('name').value = '';
        document.getElementById('email').value = '';
        document.getElementById('password').value = '';
        document.getElementById('password2').value = '';

        setAccountCreated(true);
      }
    }
  }
  
  /*
   * Helper function to validate email format
  */
  const emailValidator = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  /*
   * Helper function to show complexity requirements as user enters password
  */
  const checkPassword = (e) => {
    setErrorMsg('');
    setPasswordError(0);
    setPasswordLength(0);
    setPasswordUpper(0);
    setPasswordNumber(0);
    setPasswordSpecial(0);

    // If password doesn't meet complexity requirements
      // Length
      if (e.target.value.length < 8) { setErrorMsg('password'); setPasswordError(1); setPasswordLength(1); }
      // Uppercase
      if (!testUppercase(e.target.value)) { setErrorMsg('password'); setPasswordError(1); setPasswordUpper(1); }
      // Number
      if (!testNumber(e.target.value)) { setErrorMsg('password'); setPasswordError(1); setPasswordNumber(1); }
      // Special Character
      if (!testSpecial(e.target.value)) { setErrorMsg('password'); setPasswordError(1); setPasswordSpecial(1); }
      // Illegal Character
      if (testIllegal(e.target.value)) { setErrorMsg('illegal'); setPasswordError(1); }
  }
  
  /*
   * Helper functions to check password complexity requirements
  */
  const testUppercase = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (character.match(/[a-z]/i) && character === character.toUpperCase()) return true;
    }
    return false;
  }
  const testNumber = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (!isNaN(character * 1)) return true;
    }
    return false;
  }
  const testSpecial = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (character.match(/[!@#$%^&*]/i)) return true;
    }
    return false;
  }
  const testIllegal = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (!character.match(/[0-9a-z!@#$%^&*]/i)) return true;
    }
    return false;
  }

  return (
    <div className="container d-flex justify-content-center mt-5">
      <div className="custom-shadow p-3 w-350 rounded">
        {/* <h6 className="text-header mb-3">We are currently in beta, new accounts will need to wait to be activated</h6> */}

        <form className="mb-3" onSubmit={handleSubmit} data-bs-theme="dark">
          <div className="form-floating form-floating-sm mb-3">
            <input type="text" className={"form-control text-white border " + (nameError === 1 ? "border-error" : "")} id="name" placeholder="Name" onChange={e => setName(e.target.value)} />
            <label className={"form-control-label-light " + (nameError === 1 ? "text-error" : "")} htmlFor="name"><strong>Name</strong></label>
          </div>

          <div className="form-floating mb-3">
            <input type="text" className={"form-control text-white " + (emailError === 1 ? "border-error" : "")} id="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            <label className={"form-control-label-light " + (emailError === 1 ? "text-error" : "")} htmlFor="email"><strong>Email</strong></label>
          </div>

          <div className="form-floating mb-3">
            <input type="password" className={"form-control text-white " + (passwordError === 1 ? "border-error" : "")} id="password" placeholder="Password" onChange={e => { setPassword(e.target.value); checkPassword(e); }} />
            <label className={"form-control-label-light " + (passwordError === 1 ? "text-error" : "")} htmlFor="password"><strong>Password</strong></label>
          </div>

          <div className="form-floating mb-4">
            <input type="password" className={"form-control text-white " + (password2Error === 1 ? "border-error" : "")} id="password2" placeholder="Password" onChange={e => setPassword2(e.target.value)} />
            <label className={"form-control-label-light " + (password2Error === 1 ? "text-error" : "")} htmlFor="password2"><strong>Confirm Password</strong></label>
          </div>
          
          <button type="submit" className="btn btn-success w-100 px-4 mb-3"><strong>Sign Up</strong></button>
        </form>

        <hr className="login-hr" />

        <AppleButton />

        <GoogleButton />

        {errorMsg !== '' && errorMsg !== 'password' && errorMsg !== 'illegal' ? <div className="text-danger fs-14 mt-3">{errorMsg}</div> : ''}
        {errorMsg === 'password' ? <div className="text-danger fs-14 mt-3">Passwords must include: 
            <ul>
              <li className={passwordLength === 1 ? 'text-danger' : 'text-success'}>at least 8 characters</li>
              <li className={passwordUpper === 1 ? 'text-danger' : 'text-success'}>an uppercase letter</li>
              <li className={passwordNumber === 1 ? 'text-danger' : 'text-success'}>a number</li>
              <li className={passwordSpecial === 1 ? 'text-danger' : 'text-success'}>at least one of the following:
                <ul>
                  <li>! @ # $ % ^ & *</li>
                </ul>
              </li>
            </ul>
          </div>
        : ''}
        {errorMsg === 'illegal' ? <div className="text-danger fs-14 mt-3">Only the following special characters are allowed: 
            <ul>
              <li>! @ # $ % ^ & *</li>
            </ul>
          </div>
        : ''}

        {accountCreated ? <h4 className="text-success text-center mt-5"><strong>Account created</strong></h4> : ''}
      </div>
    </div>
  );
}