import { useEffect, useState } from 'react'
import { useStatePersist as useStateP } from 'use-state-persist';
import { useEffectOnce } from '../useEffectOnce';
import useToken from '../Auth/useToken';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import PropBubble from '../PropsList/PropBubble';
import '../PropsList.css';
 
const PropsList = (props) => {
  const navigate = useNavigate();
  const {token} = useToken();

  const [cached, setCached] = useStateP('@NBACached', 0);
  const [loading, setLoading] = useState(true);

  const [lastDBLoadTime, setLastDBLoadTime] = useStateP('@NBALastDBLoadTime', '');
  const [lastDBLoadTimeLocal, setLastDBLoadTimeLocal] = useStateP('@NBALastDBLoadTimeLocal', '');
  const [lastDataLoadTime, setLastDataLoadTime] = useStateP('@NBALastDataLoadTime', '');
  const [games, setGames] = useStateP('@NBAgames', []);
  const [data, setData] = useStateP('@NBAdata', []);
  const [filteredData, setFilteredData] = useStateP('@NBAfilteredData', []);
  const [searchText, setSearchText] = useStateP('@NBAsearchText', '');
  // const [last_season, setLastSeason] = useState(new Date().getFullYear());
  // const [this_season, setThisSeason] = useState(new Date().getFullYear() + 1);
  const [statFilter, setStatFilter] = useStateP('@NBAstatF', []);
  const [positionFilter, setPositionFilter] = useStateP('@NBAposF', []);
  const [sortFilter, setSortFilter] = useStateP('@NBAsortF', '');
  const [gameFilter, setGameFilter] = useStateP('@NBAgameF', []);
  const [bookFilter, setBookFilter] = useStateP('@NBAbookF', []);
  const [hasHover, setHasHover] = useState(0);
  const [filterView, setFilterView] = useStateP('@NBAfilterView', 'show');
  const [initFilterView, setInitFilterView] = useState(filterView);
  const [buttonDisabled ,setButtonDisabled] = useState(false);

  var booksName = {'d': 'DraftKings', 'f': 'FanDuel', 'm': 'MGM'};
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';
  
  /*
   * Run once on load
  */
  useEffectOnce(() => {
    props.setActive('nba');

    // Eliminate scroll animation
    document.documentElement.style.scrollBehavior = 'auto';

    // // Get last season's & this season's years
    // let today = new Date();
    // if (today.getMonth() < 7) {
    //   setLastSeason(today.getFullYear() - 1);
    //   setThisSeason(today.getFullYear());
    // }
    // else {
    //   setLastSeason(today.getFullYear());
    //   setThisSeason(today.getFullYear() + 1);
    // }

    getLastLoadTime();
    // getGames();             // Moved to getLastLoadTime so it can pass lastDBLoadTime directly, instead of missing new state change
    // getData();             // Moved to getLastLoadTime so it can pass lastDBLoadTime directly, instead of missing new state change
    watchForHover();
  });

  /*
   * Run when of variables in array below are changed
  */
  useEffect(() => {
    // Loop through data to get what lines to display depending on book filters selected
    data.forEach(f => {
      // Define arrays to collect values for each book selected
      f.over_values_arr = [];
      f.over_odds_arr = [];
      f.over_books_arr = [];
      f.under_values_arr = [];
      f.under_odds_arr = [];
      f.under_books_arr = [];
      f.over_index = -1;
      f.under_index = -1;

      // Reset values to stop mistakes
      f.book_over_value = null;
      f.book_over_odds = null;
      f.book_over_book = f.book;
      f.book_under_value = null;
      f.book_under_odds = null;
      f.book_under_book = f.under_book;

      // Loop through each book selected, push values & odds into arrays
      if (bookFilter.length > 0) {
        bookFilter.forEach(g => {
          if (f[g + '_over_value'] != null) {
            f.over_values_arr.push(f[g + '_over_value']);
            f.over_odds_arr.push(f[g + '_over_odds']);
            f.over_books_arr.push(booksName[g]);
          }
          if (f[g + '_under_value'] != null) {
            f.under_values_arr.push(f[g + '_under_value']);
            f.under_odds_arr.push(f[g + '_under_odds']);
            f.under_books_arr.push(booksName[g]);
          }
        });

        // Get the lowest over and the corresponding odds
        if (f.over_values_arr.length > 0) {
          f.book_over_value = Math.min.apply(Math, f.over_values_arr);
          f.over_index = f.over_values_arr.indexOf(f.book_over_value.toString());
          f.book_over_odds = f.over_odds_arr[f.over_index];
          f.book_over_book = f.over_books_arr[f.over_index];
        }
        // Get the highest under and the corresponding odds
        if (f.under_values_arr.length > 0) {
          f.book_under_value = Math.max.apply(Math, f.under_values_arr);
          f.under_index = f.under_values_arr.indexOf(f.book_under_value.toString());
          f.book_under_odds = f.under_odds_arr[f.under_index];
          f.book_under_book = f.under_books_arr[f.under_index];
        }
      }
      else {
        f.book_over_value = null;
        f.book_over_odds = null;
        f.book_over_book = f.book;
        f.book_under_value = null;
        f.book_under_odds = null;
        f.book_under_book = f.under_book;
      }
    });

    // var cDateTime = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
    var cDateTime = DateTime.now().setZone("America/New_York");
    // Subtract 10 minutes so that props stay showing until game locks on books
    // cDateTime = subtractMinutes(cDateTime, 10);
    cDateTime = cDateTime.minus({minutes:10}).toISO();

    //* ISSUE: games that are filtered out below also need filtered out of gameFilter
    // // Filter out games that have already started
    // setGames(games.filter(g => 
    //   (DateTime.fromFormat(g.date + " " + g.time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toISO() >= cDateTime)
    // ));

    // Filter out props of games that have already started
    setFilteredData(data.filter(d => 
      (DateTime.fromFormat(d.date + " " + d.time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toISO() >= cDateTime && (statFilter.length === 0 || statFilter.indexOf(d.stat) >= 0) && (positionFilter.length === 0 || positionFilter.indexOf(d.position) >= 0) && (gameFilter.length === 0 || gameFilter.indexOf(d.home_team) >= 0))
    ).sort(compare));

    // If cache has already been loaded, hide loading spinner here
    if (cached === 1) setLoading(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statFilter, positionFilter, sortFilter, gameFilter, bookFilter, data]);

  /*
   * Get most recent time that props were loaded in DB
  */
  const getLastLoadTime = async () => {
    const response = await axios.get(API_URL + 'pj/nba/loadtime', { headers: { 'x-access-token': token } });
    
    // If token expired or doesn't exist, redirect to login page
    if (response.data.errorMessage) {
      // If user's account is inactive, redirect them to /account
      if (response.data.errorMessage === 'Inactive User') {
        navigate("/account");
      }
      else {
        sessionStorage.setItem('lastError', response.data.errorMessage);
        sessionStorage.removeItem('token');
        window.location.reload();
        navigate("/login");
      }
    }
    // Otherwise, process data
    else {
      if (response.data.length > 0) {
        // const dateTime1 = new Date(new Date(response.data[0].time).toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
        // console.log(dateTime1);
        // setLastDBLoadTime(formatDateTimeSec(dateTime));
        // setLastDBLoadTimeLocal(formatDateTime(new Date(response.data[0].time)));
        // getGames(dateTime);
        // getData(dateTime);
        // console.log(DateTime.fromFormat("2023-02-21 00:00:00", "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toISO());

        // Create date object from last time the DB was loaded
        const dateTime = DateTime.fromISO(response.data[0].time.replace(' ', 'T')).setZone("America/New_York");
        // Set last DB load time in format (2023-01-01T00:00:00.000-05:00)
        setLastDBLoadTime(dateTime.toISO());
        //! BETA
        console.log('DB Last Loaded: ' + dateTime.toISO());
        // Set last DB load time in user's local time in format (2023-01-01 00:00)
        setLastDBLoadTimeLocal(dateTime.toLocal().toFormat('yyyy-MM-dd HH:mm'));
        //! BETA
        console.log('DB Last Loaded (Local): ' + dateTime.toLocal().toFormat('yyyy-MM-dd HH:mm'));
        // Get games and props
        getGames();
        getData(dateTime.toISO());
      }

      sessionStorage.removeItem('lastError');
    }
  }

  /*
   * Get games that have not started yet
  */
  // const getGames = async (lastLoad) => {
  const getGames = async () => {
    // // Only query DB if the database has been loaded more recent than the current data was or games is not yet set
    // if (lastDataLoadTime === '' || new Date(lastDataLoadTime) < new Date(lastDBLoadTime) || new Date(lastDataLoadTime) < lastLoad || games.length === 0) {
      // Get current date & time in EST
      // let today = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
      let today = DateTime.now().setZone("America/New_York");
      // Get date from DateTime object
      // let date = formatDate(today);
      let date = today.toFormat('yyyy-MM-dd');
      // Subtract 10 minutes so that props stay showing until game locks on books
      // today = subtractMinutes(today, 10);
      today = today.minus({minutes:10});
      // let time = today.toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}).substring(12);
      // let time = today.toString().substring(16, 24);
      let time = today.toFormat('HH:mm:ss');
      
      // Get data
      const response = await axios.get(API_URL + `pj/nba/day/${date}/${time}`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response);

      // If token expired or doesn't exist, redirect to login page
      if (response.data.errorMessage) {
        // If user's account is inactive, redirect them to /account
        if (response.data.errorMessage === 'Inactive User') {
          navigate("/account");
        }
        else {
          sessionStorage.setItem('lastError', response.data.errorMessage);
          sessionStorage.removeItem('token');
          navigate("/login");
        }
      }
      // Otherwise, process data
      else {
        if (Object.keys(response.data).length > 0) {
          // If games have already started, reset games filter so it doesn't cause issues
          if (games.length !== response.data.games.length) setGameFilter([]);
          
          // Remove time from date string
          response.data.games.map(g => g.date = g.date.substring(0, 10));

          setGames(response.data.games);
        }

        sessionStorage.removeItem('lastError');
      }
    // }
  }

  /*
   * Get props for games that have not started yet
  */
  const getData = async (lastLoad) => {
    // Only query DB if the database has been loaded more recent than the current data was or filteredData is not yet set
    if (lastDataLoadTime === '' || lastDataLoadTime < lastDBLoadTime || lastDataLoadTime < lastLoad || filteredData.length === 0) {
      // Get current date & time in EST
      // let today = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
      let today = DateTime.now().setZone("America/New_York");
      // Get date from DateTime object
      // let date = formatDate(today);
      let date = today.toFormat('yyyy-MM-dd');
      // Subtract 10 minutes so that props stay showing until game locks on books
      // today = subtractMinutes(today, 10);
      today = today.minus({minutes:10});
      // let time = today.toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}).substring(12);
      // let time = today.toString().substring(16, 24);
      let time = today.toFormat('HH:mm:ss');

      // Get data
      const response = await axios.get(API_URL + `pj/nba/props/${date}/${time}`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response);

      // If token expired or doesn't exist, redirect to login page
      if (response.data.errorMessage) {
        // If user's account is inactive, redirect them to /account
        if (response.data.errorMessage === 'Inactive User') {
          navigate("/account");
        }
        else {
          sessionStorage.setItem('lastError', response.data.errorMessage);
          sessionStorage.removeItem('token');
          navigate("/login");
        }
      }
      // Otherwise, process data
      else {
        if (Object.keys(response.data).length > 0) {
          // Remove time from date string
          response.data.map(d => d.date = d.date.substring(0, 10));
          
          setData(response.data);
          setFilteredData(response.data);
          setLastDataLoadTime(DateTime.now().setZone("America/New_York").toISO());

          // Clear game filter when reloading props
          setGameFilter([]);
        }

        // Remove loading spinner and store value that the cache has been loaded
        setLoading(false);
        setCached(1);

        sessionStorage.removeItem('lastError');
      }
    }
    // Otherwise, filter out any games that have already started
    else {
      // var cDateTime = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
      var cDateTime = DateTime.now().setZone("America/New_York");
      // Subtract 10 minutes so that props stay showing until game locks on books
      // cDateTime = subtractMinutes(cDateTime, 10);
      cDateTime = cDateTime.minus({minutes:10}).toISO();
      setData(data.filter(d => (DateTime.fromFormat(d.date + " " + d.time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toISO() >= cDateTime)));
    }
  }

  /*
   * Functions to set filter state variables
  */
  const saveText = (e) => {
    setSearchText(e.target.value.toLowerCase());
  }

  const saveStatFilter = (e) => {
    if (statFilter.indexOf(e.target.innerText) < 0) setStatFilter([...statFilter, e.target.innerText]);
    else setStatFilter(statFilter.filter(stat => stat !== e.target.innerText));
  }

  const savePositionFilter = (e) => {
    if (positionFilter.indexOf(e.target.innerText) < 0) setPositionFilter([...positionFilter, e.target.innerText]);
    else setPositionFilter(positionFilter.filter(position => position !== e.target.innerText));
  }

  const saveSortFilter = (e) => {
    if (sortFilter !== e) setSortFilter(e);
    else setSortFilter('');
  }

  const saveGameFilter = (e) => {
    if (gameFilter.indexOf(e) < 0) setGameFilter([...gameFilter, e]);
    else setGameFilter(gameFilter.filter(position => position !== e));
  }

  const saveBookFilter = (e) => {
    if (bookFilter.indexOf(e) < 0) setBookFilter([...bookFilter, e]);
    else setBookFilter(bookFilter.filter(book => book !== e));
  }

  const saveFilterView = (e) => {
    if (filterView === 'show') setFilterView('');
    else setFilterView('show');

    setButtonDisabled(true);

    setTimeout(() => setButtonDisabled(false), 350);
  }

  // Helper function to sort by hit percentage bubbles
  const compare = (a, b) => {
    if ( a[sortFilter] < b[sortFilter] ) return 1;
    if ( a[sortFilter] > b[sortFilter] ) return -1;
    return 0;
  }

  // // Helper function to format date
  // const formatDate = (date) => {
  //     var d = new Date(date),
  //         month = '' + (d.getMonth() + 1),
  //         day = '' + d.getDate(),
  //         year = d.getFullYear();
  
  //     if (month.length < 2) 
  //         month = '0' + month;
  //     if (day.length < 2) 
  //         day = '0' + day;
  
  //     return [year, month, day].join('-');
  // }

  // Helper function to remove :hover css on touch devices, and add on mouse devices
  function watchForHover () {
    let lastTouchTime = 0;
  
    function enableHover() {
      if (new Date() - lastTouchTime < 500) return;
      setHasHover(1);
    }
  
    function disableHover() {
      setHasHover(0);
    }
  
    function updateLastTouchTime() {
      lastTouchTime = new Date();
    }
  
    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
  
    enableHover();
  }

  // // Helper function to format and color rank
  // const formatRank = (rank) => {
  //   if (rank !== undefined) {
  //     rank = rank.toString();
  //     let rankStr = '';
  //     if (rank.substring(rank.length - 1) === '1') rankStr = rank + 'st';
  //     else if (rank.substring(rank.length - 1) === '2') rankStr = rank + 'nd';
  //     else if (rank.substring(rank.length - 1) === '3') rankStr = rank + 'rd';
  //     else rankStr = rank + 'th';

  //     if (rank <= 10) rank = <span className="text-danger2">{rankStr}</span>;
  //     else if (rank >= 21) rank = <span className="text-success2">{rankStr}</span>;
  //     else rank = <span>{rankStr}</span>;
  //   }

  //   return rank;
  // }

  // // Helper function to format date into date time string
  // const formatDateTime = (date) => {
  //   var month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
  //   var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  //   var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  //   var minutes = date.getMinutes();
  //   minutes = minutes < 10 ? '0'+minutes : minutes;
  //   var strTime = hours + ':' + minutes;
  //   return date.getFullYear() + "-" + month + "-" + day + " " + strTime;
  // }

  // // Helper function to format date into date time string
  // const formatDateTimeSec = (date) => {
  //   var month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
  //   var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  //   var hours = date.getHours();
  //   var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  //   var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  //   var strTime = hours + ':' + minutes + ':' + seconds;
  //   return date.getFullYear() + "-" + month + "-" + day + " " + strTime;
  // }

  // // Helper function to format date & time into day time string
  // const formatDayTime = (date, time) => {
  //   // const d = new Date(date + " " + time);
  //   // return getDayName(d).substring(0, 3) + ' ' + (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ':' + (d.getMinutes() > 0 ? (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) : '00') + (d.getHours() >= 12 ? ' PM' : ' AM');
  //   var newFormat = {weekday: 'short', ...DateTime.TIME_SIMPLE};
  //   return DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toLocal().toLocaleString(newFormat);
  // }

  // Helper function to format date & time into time string
  const formatTime = (date, time) => {
    // const d = new Date(date + " " + time);
    // return (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ':' + (d.getMinutes() > 0 ? (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) : '00') + (d.getHours() >= 12 ? ' PM' : ' AM');
    return DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toLocal().toLocaleString(DateTime.TIME_SIMPLE);
  }

  // // Helper function to get name of day of week
  // const getDayName = (dateStr) => {
  //   var date = new Date(dateStr);
  //   return date.toLocaleDateString('en-US', { weekday: 'long' });        
  // }

  // // Helper function to subtract minutes from a date
  // function subtractMinutes(date, minutes) {
  //   return new Date(date.getTime() - (minutes * 60000));
  // }

  return (
    <div className="px-12">
      <div className="row">
        <div className="col-auto header-col">
          <h3 className="text-light orbitron mt-2 mb-0">Today's Props</h3>
        </div>
        <div className="col" data-bs-theme="dark">
          <input type="text" className="col form-control search float-end shadow-none px-2 mt-07" id="search" placeholder="Search" onChange={e => saveText(e)} autoComplete="off" defaultValue={searchText}/>
        </div>
      </div>

      {/* <div className="row m-0">
        <div className="col text-end text-secondary fs-12 mt-004 p-0">{data.length} Props Last Loaded at {lastDBLoadTimeLocal}</div>
      </div> */}

      <div className="w-100 text-end p-0 m-0">
        <button className="btn filters-btn bg-dark2 text-orange p-0 m-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilters" onClick={e => saveFilterView(e)} disabled={buttonDisabled}>Filters <i className={"bi bi-caret-" + (filterView === 'show' ? 'up' : 'down')}></i></button>
      </div>
      <div className={"collapse " + initFilterView} id="collapseFilters">
        <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Assists') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Assists</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Points') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Points</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Rebounds') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Rebounds</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Threes') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Threes</div>
            <div className="col"></div>
          </div>
        </div>

        <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('C') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>C</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('PF') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>PF</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('PG') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>PG</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('SF') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>SF</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('SG') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>SG</div>
            {/* <div className="col-auto b-left"><div className={"px-25 bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'last_season' ? " bubble-selected" : "")} onClick={e => saveSortFilter('last_season')}>2022</div></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'all_season' ? " bubble-selected" : "")} onClick={e => saveSortFilter('all_season')}>2023</div> */}
            <div className="col-auto b-left"><div className={"bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'all_season' ? " bubble-selected" : "")} onClick={e => saveSortFilter('all_season')}>Season</div></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_ten' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_ten')}>L10</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_five' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_five')}>L5</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_three' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_three')}>L3</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_one' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_one')}>L1</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'vs' ? " bubble-selected" : "")} onClick={e => saveSortFilter('vs')}>vs Opp</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'opp_rank' ? " bubble-selected" : "")} onClick={e => saveSortFilter('opp_rank')}>Def</div>
            <div className="col"></div>
          </div>
        </div>

        {games.length > 1 ?
        <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            {games.map((g, index) => (
              <div key={index} className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (gameFilter.indexOf(g.home_team) >= 0 ? " bubble-selected" : "")} onClick={e => saveGameFilter(g.home_team)}>
                {g.away_team + ' @ ' + g.home_team}
                <div className="game-date-time">{formatTime(g.date, g.time)}</div>
              </div>
            ))}
            <div className="col"></div>
          </div>
        </div>
        : ''}

        {/* <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            <div className={"col-auto bg-black bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('d') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('d')} data-bs-toggle="tooltip" title="DraftKings">
              <img className="align-text-bottom" src="./DraftKings.png" alt="DraftKings" width="16"/>
            </div>
            <div className={"col-auto bg-blue bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('f') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('f')} data-bs-toggle="tooltip" title="FanDuel">
              <img className="align-text-bottom" src="./FanDuel.png" alt="FanDuel" width="16"/>
            </div>
            <div className={"col-auto bg-black bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('m') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('m')} data-bs-toggle="tooltip" title="BetMGM">
              <img className="align-text-bottom" src="./MGM.png" alt="BetMGM" width="16"/>
            </div>
            <div className="col"></div>
          </div>
        </div> */}
      </div>

      {loading ? 
        <div className="loader" id="loader"></div>
        :
        filteredData.map((p, index) => (
        (p.player_name.toLowerCase().includes(searchText) && (bookFilter.length === 0 || (bookFilter.length > 0 && (p.book_over_value != null || p.book_under_value != null))) ?
          <Link to={`/nba/player/${p.player_user}/${p.stat}`} key={index}>
            <PropBubble player={p} />
          </Link>
          : '')
        ))
      }
    </div>
  );
}
 
export default PropsList;