const HitCountBubble = (props) => {
    // Calculate shade of background color depending on hit percentage
    const calcBackground = (perc) => {
        // GREATER THAN 50%
        if (perc > .5) {
            if (perc >= .6) return 'rgba(2, 150, 2, ' + ((perc - .5) / .5) + ')';
            else return 'rgba(2, 150, 2, .15)';
        }
        // LESS THAN 50%
        else if (perc < .5) {
            if (perc <= .4) return 'rgba(255, 51, 51, ' + ((.5 - perc) / .5) + ')';
            else return 'rgba(255, 51, 51, .15)';
        }
        // EQUALS 50%
        else return '#505050';
    }

    return (
        <div className="col-auto perc text-center px-0 me-2" style={{background: calcBackground(props.perc)}}>
            {props.text}: {Math.round((props.perc * 100), 1)}%
        </div>
    );
}

export default HitCountBubble;