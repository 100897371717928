import React from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";

const GoogleButtonS = () => {
  const navigate = useNavigate();

  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  const googleResponse = (response) => {
    if (!response.error) {
      axios
        .post(API_URL + "pj/gauthS", response)
        .then(res => {
          navigate('/login?id=' + res.data);
          // window.location.reload();
        })
        .catch(err => console.log(err));
    }
  }

  return (
    <div className="mt-3 text-center">
      <div>
        <GoogleOAuthProvider clientId="194756799792-flaiptdstpvgu9ki6mn9kje2j1jv55o7.apps.googleusercontent.com">
          <GoogleLogin
            theme="filled_black"
            width="318px"
            onSuccess={googleResponse}
            text="signup_with"
          />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}

export default GoogleButtonS;