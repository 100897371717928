import React, { useState } from 'react';
import { useEffectOnce } from '../useEffectOnce';
import { useLocation, useNavigate } from "react-router-dom";
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";
import axios from "axios";

async function sendEmail(data) {
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  return fetch(API_URL + 'pj/forgotPassword', {
    method: 'POST',
    // credentials: 'include',
    headers: { 'Content-Type': 'application/json', 'token': 'XkjQzRQEhdtPtvcj6xS2LqWL' },
    body: JSON.stringify(data)
  })
  .then(data => data.json())
}

export default function ForgotPassword () {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);

  // Clear all filters and data in local storage
  //TODO just for beta testing?
  localStorage.clear();

  // useEffectOnce(() => {
  //   // If id parameter exists in URL (redirect from API after SSO login)
  //   if (params.get('id') !== undefined && params.get('id') !== null && params.get('id') !== '' && params.get('id').length > 15) {
  //     handleRedirectTo();
  //   }
  // });

  const handleSubmit = async e => {
    e.preventDefault();

    // Declare variables and clear errors
    var sendEmail = true;
    setEmailError(0);
    setErrorMsg('');

    // If email is empty
    if (email.trim().length < 1) { setErrorMsg("Enter email associated with your account"); setEmailError(1); sendEmail = false; }

    // Email filled, send password reset link
    if (sendEmail) sendingEmail(email.trim());
  }

  const sendingEmail = async (email) => {
    // Clear text box
    document.getElementById('email').value = '';

    // Get user location & IP
    var ip = '', city = '', state = '', postal = '', country = '';
    try {
      var res = await axios.get('https://geolocation-db.com/json/');
      ip = res.data.IPv4;
      city = res.data.city;
      state = res.data.state;
      postal = res.data.postal;
      country = res.data.country_code;
    } catch (e) { console.log('private'); }
    
    // Call backend to send email to user, if account exists
    sendEmail({
      email: email,
      browserName: browserName,
      browserVersion: browserVersion,
      osName: osName,
      osVersion: osVersion,
      ip: ip,
      city: city,
      state: state,
      zip: postal,
      country: country
    });

    // Notify user a link has been emailed
    setSuccessMsg('If an account with that email exists, a password reset link has been sent to it');
  }

  return (
    <div className="container d-flex justify-content-center mt-5">
      <div className="custom-shadow p-3 w-350 rounded">
        <h6 className="text-header mb-3">Enter email for password reset link to be sent to</h6>

        <form className="mb-3" onSubmit={handleSubmit} data-bs-theme="dark">
          <div className="form-floating mb-3">
            <input type="text" className={"form-control text-white " + (emailError === 1 ? "border-error" : "")} id="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            <label className={"form-control-label-light " + (emailError === 1 ? "text-error" : "")} htmlFor="email"><strong>Email</strong></label>
          </div>

          <button type="submit" className="btn btn-success w-100"><strong>Send reset email</strong></button>
        </form>

        {errorMsg !== '' ? <div className="text-danger fs-6 mt-3">{errorMsg}</div> : ''}

        <button type="button" className="btn btn-primary d-none" id="emailSentModalBtn" data-bs-toggle="modal" data-bs-target="#emailSentModal"></button>

        {successMsg !== '' ?
          <div className="mt-4 text-success fs-14">{successMsg}</div>
        : ''}
      </div>
      {/* <div className="modal fade" id="emailSentModal" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-dark text-white">
            <div className="modal-body text-center">
              <h3 className="text-success mb-4"><i className="fas fa-check-circle text-success"></i> Email Sent</h3>
              <a className="underline" href="/login">Back to login</a>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}