import { useState } from 'react';
import { useEffectOnce } from './useEffectOnce';
import useToken from './Auth/useToken';
import axios from "axios";
 
const HomePage = (props) => {
  const {token} = useToken();
  const [btnDisabled, setBtnDisabled] = useState(true);

  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  useEffectOnce(() => {
    props.setActive('home');

    // Clear all filters and data in local storage
    //TODO just for beta testing?
    localStorage.clear();

    // If this is user's first login OR they haven't accepted the T&Cs yet, show welcome modal
    if (sessionStorage.getItem('firstLogin') === '1' || sessionStorage.getItem('acceptTC') === '0') document.getElementById("welcome_modal_btn").click();
  });

  const changeSlide = (e) => {
    if (document.getElementById('carousel_1').offsetHeight > 0) document.getElementById('carousel_3').style.height = document.getElementById('carousel_1').offsetHeight + 'px';
    if (document.getElementById('carousel_2').offsetHeight > 0) document.getElementById('carousel_3').style.height = document.getElementById('carousel_2').offsetHeight + 'px';
  }

  // When accepted T&Cs checkbox is checked, enable button
  const understood = (e) => {
    if (e.target.checked) setBtnDisabled(false);
    else setBtnDisabled(true);
  }

  // Record that user has accepted T&Cs
  const confirmUnderstood = (e) => {
    if (document.getElementById('understood_chk').checked === true) {
      axios.post(API_URL + 'pj/acceptTC', { understood: 1 }, { headers: { 'x-access-token': token } });
      sessionStorage.removeItem('firstLogin');
      sessionStorage.removeItem('acceptTC');
    }
  }

  return (
    <div className="text-white bg-home orbitron">
      <section className="p-5 p-lg-0 pt-lg-5 text-center text-sm-start">
        <div className="container">
          <div className="d-lg-flex align-items-center justify-content-between">
            <div>
              <h2 className="ls-sm"><strong>The one-stop shop for all player prop betting research.</strong></h2>
              <p className="lead ff-reg my-4">Utilize our advanced algorithms that analyze player props and help you visualize trends in order to give you the best tools to beat the books!</p>
            </div>
            <div className="image1-div mx-lg-5"></div>
            <div className="d-none">Device Mockup created from <a href="https://deviceframes.com/templates/iphone-13">iPhone mockups</a></div>
          </div>
        </div>
      </section>

      {/* <section className="p-5">
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center">
            <h3 className="mb-3 mb-md-0">Sign Up For Newsletter</h3>
            <div className="input-group news-input">
              <input type="text" className="form-control" placeholder="Email" />
              <button type="button" className="btn btn-dark btn-lg">Button</button>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="p-5">
        <div className="container">
          <div className="row text-center g-4">
            <div className="col-md">
              <div className="card bg-dark text-light">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    <i className="bi bi-laptop"></i>
                  </div>
                  <h3 className="card-title"></h3>
                  <p className="card-text"></p>
                  <a href="#" className="btn btn-primary">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card bg-secondary text-light">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    <i className="bi bi-person-square"></i>
                  </div>
                  <h3 className="card-title"></h3>
                  <p className="card-text"></p>
                  <a href="#" className="btn btn-dark">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card bg-dark text-light">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    <i className="bi bi-people"></i>
                  </div>
                  <h3 className="card-title"></h3>
                  <p className="card-text"></p>
                  <a href="#" className="btn btn-primary">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="learn" className="px-3 py-5">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg">
              <div className="image2-div"></div>
              <div className="d-none">Device Mockup created from <a href="https://deviceframes.com/templates/iphone-13">iPhone mockups</a></div>
            </div>
            <div className="col-sm p-5">
              <h2 className="ls-sm"><strong>As bookmakers' methods evolve, so should the way that you research!</strong></h2>
              <p className="leader ff-reg">Stop wasting your time going to a multitude of different sites, to dig through dozens of cluttered tables.</p>
              <p className="ff-reg">Prop Jockey is here to centralize and help visualize the data pertinent in making decisions on what you want to bet on.</p>
              {/* <a href="" className="btn btn-light mt-3">
                <i className="bi bi-chevron-right"></i> Read More
              </a> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="p-5 text-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md p-5">
              <h2></h2>
              <p className="leader"></p>
              <p></p>
              <a href="" className="btn btn-light mt-3">
                <i className="bi bi-chevron-right"></i> Read More
              </a>
            </div>
            <div className="col-md">
              <img className="img-fluid w-50" src="./startup.svg" alt="" />
            </div>
          </div>
        </div>
      </section> */}

      <section id="sports" className="py-5 px-3">
        <div className="container-fluid text-black">
          <h2 className="text-center text-white orbitron">Our Sports</h2>
          <p className="lead text-center text-white mb-5">Here are the leagues that we currently support!</p>
          <div className="row g-3">
            <div className="col-md-6 col-xl-3">
              <div className="card text-white card-design h-100 shadow">
                <div className="card-body text-center">
                  <h3 className="card-title border-bottom border-2 orbitron pb-3"><strong>MLB</strong></h3>
                  <div className="card-text">
                    <div className="rounded shadow p-2 mb-3">Analyze lines for <strong>hits</strong>, <strong>runs</strong>, <strong>homeruns</strong>, <strong>RBI</strong>, <strong>stolen bases</strong> and <strong>strikeouts</strong></div>
                    <div className="rounded shadow p-2 mb-3">Breakdown by <strong>season</strong>, <strong>last 10/5/3/1</strong>, and <strong>versus opponent</strong></div>
                    <div className="rounded shadow p-2 mb-3">View player game logs</div>
                    <div className="rounded shadow p-2 mb-3"><strong><u>Coming Soon:</u></strong> lines for singles, doubles, and triples</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card text-white card-design h-100 shadow">
                <div className="card-body text-center">
                  <h3 className="card-title border-bottom border-2 orbitron pb-3"><strong>NBA</strong></h3>
                  <div className="card-text">
                    <div className="rounded shadow p-2 mb-3">Analyze lines for <strong>points</strong>, <strong>rebounds</strong>, <strong>assists</strong> and <strong>threes made</strong></div>
                    <div className="rounded shadow p-2 mb-3">Breakdown by <strong>season</strong>, <strong>last 10/5/3/1</strong>, and <strong>versus opponent</strong></div>
                    <div className="rounded shadow p-2 mb-3">View player game logs</div>
                    <div className="rounded shadow p-2 mb-3"><strong><u>Coming Soon:</u></strong> lines for player combos</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card text-white card-design h-100 shadow">
                <div className="card-body text-center">
                  <h3 className="card-title border-bottom border-2 orbitron pb-3"><strong>NFL</strong></h3>
                  <div className="card-text">
                    <div className="rounded shadow p-2 mb-3">Analyze lines for <strong>yards</strong> (passing, rushing, & receiving), <strong>interceptions</strong> & <strong>touchdowns</strong> thrown, <strong>rush attempts</strong>, <strong>receptions</strong>, and <strong>touchdown scorers</strong></div>
                    <div className="rounded shadow p-2 mb-3">Breakdown by <strong>season</strong>, <strong>last 7/5/3/1</strong>, and <strong>versus opponent</strong></div>
                    <div className="rounded shadow p-2 mb-3">View player game logs</div>
                    <div className="rounded shadow p-2 mb-3"><strong><u>Coming Soon:</u></strong> lines for kicking props</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card text-white card-design h-100 shadow">
                <div className="card-body text-center">
                  <h3 className="card-title border-bottom border-2 orbitron pb-3"><strong>NHL</strong></h3>
                  <div className="card-text">
                    <div className="rounded shadow p-2 mb-3">Analyze lines for <strong>assists</strong>, <strong>goals</strong>, <strong>points</strong>, <strong>power play points</strong>, and <strong>shots on goals</strong></div>
                    <div className="rounded shadow p-2 mb-3">Breakdown by <strong>season</strong>, <strong>last 10/5/3/1</strong>, and <strong>versus opponent</strong></div>
                    <div className="rounded shadow p-2 mb-3">View player game logs</div>
                    <div className="rounded shadow p-2 mb-3"><strong><u>Coming Soon:</u></strong> lines for saves</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="s" className="py-5 px-3">
        <div className="container-fluid text-black">
          <h2 className="text-center text-white orbitron">Our Subscriptions</h2>
          <p className="lead text-center text-white mb-5"></p>
          <div className="row g-4">
            <div className="col-md-6 col-xl-3">
              <div className="bg-white rounded">
                <h3 className="card-title border-bottom orbitron pb-3"><strong>NHL</strong></h3>
                <ul className="card-text card-design text-start sports pt-2 ps-4">
                  <li>Analyze lines for <strong>yards</strong> (passing, rushing, & receiving), <strong>interceptions</strong> & <strong>touchdowns</strong> thrown, <strong>rush attempts</strong>, <strong>receptions</strong>, and <strong>touchdown scorers</strong></li>
                  <li>Breakdown by <strong>season</strong>, <strong>last 7/5/3/1</strong>, and <strong>versus opponent</strong></li>
                  <li>View player game logs</li>
                  <li><strong><u>Coming Soon:</u></strong> lines for kicking props</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="subscriptions" className="py-5 px-3">
        <div className="container-fluid text-black">
          <h2 className="text-center text-white orbitron">Our Subscriptions</h2>
          <p className="lead text-center text-white mb-5">Find the plan that fits your betting style best!</p>
          <div className="row g-4">
            <div className="col-md-12 col-lg-4">
              <div className="card text-white card-design-reverse h-100 shadow">
                <div className="card-body d-flex flex-column text-center">
                  <h3 className="card-title border border-white rounded p-1 orbitron"><strong>Monthly Pass</strong></h3>
                  <div className="card-text">
                    <div className="rounded shadow p-2 mb-3"><strong>Month by month</strong> full access to <strong>all</strong> sports</div>
                    <div className="rounded shadow p-2 mb-3">Prop ranking & analysis by our advanced algorithms</div>
                    <div className="rounded shadow p-2 mb-3">Updated lines and odds from major books</div>
                    <div className="rounded shadow p-2 mb-3">User friendly visualizations & game log breakdowns</div>
                    {/* <div className="rounded shadow p-2 mb-3">Access to website & Discord (Mobile App Coming Soon!)</div> */}
                  </div>
                  <h1 className="mt-auto"><strong>$9.99</strong></h1>
                  <button className="btn bg-orange shadow fs-5 py-1 w-100 mt-1"><strong>Start Free Trial</strong></button>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="card text-white card-design-reverse h-100 shadow">
                <div className="card-body d-flex flex-column text-center">
                  <h3 className="card-title border border-white rounded p-1 orbitron"><strong>Season Pass</strong></h3>
                  <div className="card-text">
                    <div className="rounded shadow p-2 mb-3"><strong>Season-long</strong> full access to <strong>one</strong> sport</div>
                    <div className="rounded shadow p-2 mb-3">Prop ranking & analysis by our advanced algorithms</div>
                    <div className="rounded shadow p-2 mb-3">Updated lines and odds from major books</div>
                    <div className="rounded shadow p-2 mb-3">User friendly visualizations & game log breakdowns</div>
                    {/* <div className="rounded shadow p-2 mb-3">Access to website & Discord (Mobile App Coming Soon!)</div> */}
                  </div>
                  <h1 className="mt-auto"><strong>$49.99</strong></h1>
                  <button className="btn bg-orange shadow fs-5 py-1 w-100 mt-1"><strong>Start Free Trial</strong></button>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="card text-white card-design-reverse h-100 shadow">
                <div className="card-body d-flex flex-column text-center">
                  <h3 className="card-title border border-white rounded p-1 orbitron"><strong>Yearly Pass</strong></h3>
                  <div className="card-text">
                    <div className="rounded shadow p-2 mb-3"><strong>Year-round</strong> full access to <strong>all</strong> sports</div>
                    <div className="rounded shadow p-2 mb-3">Prop ranking & analysis by our advanced algorithms</div>
                    <div className="rounded shadow p-2 mb-3">Updated lines and odds from major books</div>
                    <div className="rounded shadow p-2 mb-3">User friendly visualizations & game log breakdowns</div>
                    {/* <div className="rounded shadow p-2 mb-3">Access to website & Discord (Mobile App Coming Soon!)</div> */}
                  </div>
                  <h1 className="mt-auto"><strong>$99.99</strong></h1>
                  <button className="btn bg-orange shadow fs-5 py-1 w-100 mt-1"><strong>Start Free Trial</strong></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="questions" className="px-3 py-5">
        <div className="container">
          <h2 className="text-center orbitron mb-4">FAQs</h2>
          <div className="accordion accordion-flush shadow rounded p-2" id="questions">
            {/* ONE */}
            <div className="accordion-item bg-transparent bb-purple text-white">
              <h2 className="accordion-header">
              <button className="accordion-button text-white bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question-one">
                <strong>What is Prop Jockey?</strong>
              </button>
              </h2>
              <div id="question-one" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body ff-reg">Prop Jockey is a user friendly player prop research tool. We analyze player data and props for the day and give you a one-stop shop for all your betting research, with beautiful and intelligent visualizations.</div>
              </div>
            </div>
            {/* TWO */}
            <div className="accordion-item bg-transparent bb-purple text-white">
              <h2 className="accordion-header">
              <button className="accordion-button text-white bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question-two">
              <strong>Are props listed in some order?</strong>
              </button>
              </h2>
              <div id="question-two" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body ff-reg">Yes. Our algorithms analyze each prop and order them depending on many factors.<br/><br/><u>However, this <strong>DOES NOT</strong> mean that the top x number of props are locks!</u></div>
              </div>
            </div>
            {/* THREE */}
            <div className="accordion-item bg-transparent bb-purple text-white">
              <h2 className="accordion-header">
              <button className="accordion-button text-white bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question-three">
              <strong>How expensive is this?</strong>
              </button>
              </h2>
              <div id="question-three" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body ff-reg">Monthly - $9.99<br/>One Sport's Full Season - $49.99<br/>Yearly - $99.99</div>
              </div>
            </div>
            {/* FOUR */}
            {/* <div className="accordion-item bg-transparent bb-purple">
              <h2 className="accordion-header">
              <button className="accordion-button text-white bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question-four">
              <strong>How do I cancel my subscription?</strong>
              </button>
              </h2>
              <div id="question-four" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body ff-reg">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Commodi velit nulla at dignissimos nemo, fugit, excepturi aut doloribus libero odit illum reprehenderit consequuntur veniam aliquid amet eius porro vero modi? A assumenda non eius molestiae pariatur officia sit cumque magni accusamus ducimus fugiat, illum sed deserunt laborum facere neque dicta.</div>
              </div>
            </div> */}
            {/* FIVE */}
            {/* <div className="accordion-item bg-transparent bb-purple">
              <h2 className="accordion-header">
              <button className="accordion-button text-white bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#question-five">
              <strong>Question</strong>
              </button>
              </h2>
              <div id="question-five" className="accordion-collapse collapse" data-bs-parent="#questions">
                <div className="accordion-body ff-reg">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Commodi velit nulla at dignissimos nemo, fugit, excepturi aut doloribus libero odit illum reprehenderit consequuntur veniam aliquid amet eius porro vero modi? A assumenda non eius molestiae pariatur officia sit cumque magni accusamus ducimus fugiat, illum sed deserunt laborum facere neque dicta.</div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* <section className="p-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md">
              <h2 className="text-center mb-4">Contact Info</h2>
              <ul className="list-group list-group-flush lead">
                <li className="list-group-item">
                  <span className="f2-bold">Main Location:</span> 50 Main Street, Boston, MA
                </li>
                <li className="list-group-item">
                  <span className="f2-bold">Enrollment Phone:</span> (555) 555-5555
                </li>
                <li className="list-group-item">
                  <span className="f2-bold">Student Phone:</span> (555) 555-5555
                </li>
                <li className="list-group-item">
                  <span className="f2-bold">Enrollment Email:</span> enroll@frontendbc.test
                </li>
                <li className="list-group-item">
                  <span className="f2-bold">Student Email:</span> student@frontendbc.test
                </li>
              </ul>
            </div>
            <div className="col-md">
              <div id="map"></div>
            </div>
          </div>
        </div>
      </section> */}


      <button className="d-none" id="welcome_modal_btn" data-bs-toggle="modal" data-bs-target="#welcomeModal"></button>
      <div className="modal fade" id="welcomeModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="welcomeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content bg-dark3">
            <div className="modal-header border-0 py-2">
              <h1 className="modal-title orbitron fs-3" id="welcomeModalLabel">Welcome!</h1>
            </div>
            <div className="modal-body py-0">
              <div id="carouselWelcome" className="carousel slide">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#carouselWelcome" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" onClick={e => changeSlide(e)}></button>
                  <button type="button" data-bs-target="#carouselWelcome" data-bs-slide-to="1" aria-label="Slide 2" onClick={e => changeSlide(e)}></button>
                  <button type="button" data-bs-target="#carouselWelcome" data-bs-slide-to="2" aria-label="Slide 3" onClick={e => changeSlide(e)}></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active" id="carousel_1">
                    <img src="/mac-nba.png" className="d-block w-100" alt="..."/>
                    <div className="carousel-caption position-relative start-0 end-0 mt-3">
                      <p className="orbitron fs-6 mb-1">Props are ordered by rank, which are calculated by our advanced algorithms</p>
                      <p className="orbitron fs-6 mb-1">Props & players can be searched and filtered for</p>
                      <p className="orbitron fs-6 mb-1">Click on a player's prop to open that player's page</p>
                    </div>
                  </div>
                  <div className="carousel-item" id="carousel_2">
                    <img src="/mac-nba-player.png" className="d-block w-100" alt="..."/>
                    <div className="carousel-caption position-relative start-0 end-0 mt-3">
                      <p className="orbitron fs-6 mb-1">All relevant player data is easily visualized</p>
                      <p className="orbitron fs-6 mb-1">You can change which stat line populates the game log graph</p>
                      <p className="orbitron fs-6 mb-1">Filter the graph down by selecting the bubbles below it</p>
                    </div>
                  </div>
                  <div className="carousel-item" id="carousel_3">
                    <div className="h-100 px-lg-3 mx-lg-5 mt-3 mt-lg-5">
                      {/* <div className="orbitron fs-6 mb-4">Select the right-most icon on the navigation bar to enter a bug report or submit feedback</div>
                      <div className="orbitron fs-6 mb-5">Please feel free to reach out with any questions, feature suggestions, or anything that doesn't seem to be working properly</div>
                      <div className="orbitron fs-6 text-purple my-5">Prop Jockey is simply a centralized research tool and is in no way suggesting any bets as guarantees and in no way can be held responsible.</div> */}

                      <div className="bg-white shadow rounded text-black fs-14 h-75 px-3 mx-lg-5">
                        <h2>Terms and Conditions</h2>
                        <p>I understand and agree that <span className="orbitron">PROP JOCKEY</span> is strictly a research tool.</p>
                        <p><span className="orbitron">PROP JOCKEY</span>, in no way, can be held reliable for any losses or misfortunate that fall upon the user.</p>
                        <p><span className="orbitron">PROP JOCKEY</span> is not guaranteeing any selection, no matter it's ranking, it is only providing data analysis.</p>
                        
                        <div className="form-check position-absolute bottom-10">
                          <input className="form-check-input" type="checkbox" id="understood_chk" onChange={e => understood(e)}/>
                          <label className="form-check-label text-purple" htmlFor="understood_chk">I understand & agree to <span className="orbitron">PROP JOCKEY</span> Terms & Conditions.</label>
                        </div>
                        <br/><br/><br/><br/>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselWelcome" data-bs-slide="prev" onClick={e => changeSlide(e)}>
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselWelcome" data-bs-slide="next" onClick={e => changeSlide(e)}>
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="modal-footer border-0 py-1">
              <button type="button" className="btn bg-purple text-white" data-bs-dismiss="modal" onClick={e => confirmUnderstood(e)} disabled={btnDisabled}>OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default HomePage;