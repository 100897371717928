import { useEffect, useState } from 'react'
import { useEffectOnce } from '../useEffectOnce';
import useToken from '../Auth/useToken';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import datalabelPlugin from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import '../PlayerData.css';
 
const PlayerData = (props) => {
  const navigate = useNavigate();
  const {token} = useToken();
  
  const { id, stat } = useParams();
  const [player, setPlayer] = useState([]);
  const [assists, setAssists] = useState([]);
  const [goals, setGoals] = useState([]);
  const [points, setPoints] = useState([]);
  const [ppPoints, setPPPoints] = useState([]);
  const [shots, setShots] = useState([]);
  const [saves, setSaves] = useState([]);
  const [games, setGames] = useState([]);
  const [oppGames, setOppGames] = useState([]);
  const [oppsGames, setOppsGames] = useState([]);
  const [oppRanks, setOppRanks] = useState([]);
  const [statOptions, setStatOptions] = useState([]);
  const [statRadio, setStatRadio] = useState('');
  const [seasonAvg, setSeasonAvg] = useState('');
  const [tenAvg, setTenAvg] = useState('');
  const [fiveAvg, setFiveAvg] = useState('');
  const [threeAvg, setThreeAvg] = useState('');
  const [oneAvg, setOneAvg] = useState('');
  const [vsAvg, setVsAvg] = useState(-1);
  const [oppRank, setOppRank] = useState('');
  const [chartData, setChartData] = useState({});
  const [line, setLine] = useState('');
  const [seasonHitCount, setSeasonHitCount] = useState('');
  const [tenHitCount, setTenHitCount] = useState(0);
  const [fiveHitCount, setFiveHitCount] = useState(0);
  const [threeHitCount, setThreeHitCount] = useState(0);
  const [oneHitCount, setOneHitCount] = useState(0);
  const [vsHitCount, setVSHitCount] = useState(0);
  const [homeGameCount, setHomeGameCount] = useState(0);
  const [homeHitCount, setHomeHitCount] = useState(0);
  const [awayGameCount, setAwayGameCount] = useState(0);
  const [awayHitCount, setAwayHitCount] = useState(0);
  const [winGameCount, setWinGameCount] = useState(0);
  const [winHitCount, setWinHitCount] = useState(0);
  const [lossGameCount, setLossGameCount] = useState(0);
  const [lossHitCount, setLossHitCount] = useState(0);
  const [playoffsGameCount, setPlayoffsGameCount] = useState(0);
  const [playoffsHitCount, setPlayoffsHitCount] = useState(0);
  const [selectedGame, setSelectedGame] = useState(0);
  const [selectedHitCount, setSelectedHitCount] = useState('season');
  const [collapse, setCollapse] = useState('d-none');
  const [hideLabels, setHideLabels] = useState(0);
  
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    datalabelPlugin
  );
  
  /*
   * Run once on load
  */
  useEffectOnce(() => {
    props.setActive('nhl');

    // Scroll to top when page is first loaded
    window.scrollTo(0, 0);

    // Hide labels on chart on smaller screens
    if (window.innerWidth < 600) setHideLabels(1);

    getData();
  });

  /*
   * Run when of variables in array below are changed
  */
  useEffect(() => {
    if (games !== undefined && games.length > 0) {
      // Calculate season average for stat
      if (statRadio === 'assists') { setSeasonAvg(Math.round(Number(player.APG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Assists Rank'] : ''); }
      else if (statRadio === 'goals') { setSeasonAvg(Math.round(Number(player.GPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Goals Rank'] : ''); }
      else if (statRadio === 'points') { setSeasonAvg(Math.round(Number(player.PPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Points Rank'] : ''); }
      else if (statRadio === 'pp_points') { setSeasonAvg(Math.round(Number(player.PPPPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['PP Points Rank'] : ''); }
      else if (statRadio === 'shots_on_goal') { setSeasonAvg(Math.round(Number(player.SOGPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Shots Rank'] : ''); }
      else if (statRadio === 'saves') { setSeasonAvg(Math.round(Number(player.SaPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Saves Rank'] : ''); }

      //* Calculate other averages for stat
      let allHit = 0;
      let ten = 0, tenHit = 0;
      let five = 0, fiveHit = 0;
      let three = 0, threeHit = 0;
      let homeGames = 0, homeHit = 0;
      let awayGames = 0, awayHit = 0;
      let winGames = 0, winHit = 0;
      let lossGames = 0, lossHit = 0;
      let opp = 0, oppHit = 0;
      let playoffsGames = 0, playoffsHit = 0;

      if (games.length > 0 && statRadio !== 'toi') {
        games.map((g, index) => {
          // Format date to just date
          g.date = g.date.substring(0, 10);

          // Season
          if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) allHit++;
          else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) allHit++;
          else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) allHit++;
          else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) allHit++;
          else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) allHit++;
          else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) allHit++;
          // Last 10
          if (games.length >= 10 && index < 10) {
            ten += g[statRadio];
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) tenHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) tenHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) tenHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) tenHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) tenHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) tenHit++;
          }
          // Last 5
          if (games.length >= 5 && index < 5) {
            five += g[statRadio];
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) fiveHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) fiveHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) fiveHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) fiveHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) fiveHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) fiveHit++;
          }
          // Last 3
          if (games.length >= 3 && index < 3) {
            three += g[statRadio];
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) threeHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) threeHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) threeHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) threeHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) threeHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) threeHit++;
          }
          // Last 1
          if (games.length >= 1 && index < 1) {
            setOneHitCount(0);
            setOneAvg(g[statRadio]);
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) setOneHitCount(1);
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) setOneHitCount(1);
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) setOneHitCount(1);
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) setOneHitCount(1);
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) setOneHitCount(1);
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) setOneHitCount(1);
          }
          // Home
          if (g['location'] === '') {
            homeGames++;
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) homeHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) homeHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) homeHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) homeHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) homeHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) homeHit++;
          }
          // Away
          if (g['location'] === '@') {
            awayGames++;
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) awayHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) awayHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) awayHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) awayHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) awayHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) awayHit++;
          }
          // Win
          if (g['win_loss'] === 'W') {
            winGames++;
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) winHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) winHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) winHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) winHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) winHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) winHit++;
          }
          // Loss
          else {
            lossGames++;
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) lossHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) lossHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) lossHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) lossHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) lossHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) lossHit++;
          }
          // Playoffs
          if (g['playoffs'] === 1) {
            playoffsGames++;
            if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) playoffsHit++;
            else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) playoffsHit++;
            else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) playoffsHit++;
            else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) playoffsHit++;
            else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) playoffsHit++;
            else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) playoffsHit++;
          }
          
          return '';
        });
      }
      if (oppGames.length > 0 && statRadio !== 'toi') {
        oppGames.map(g => {
          opp += g[statRadio];
          if (statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) oppHit++;
          else if (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) oppHit++;
          else if (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) oppHit++;
          else if (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) oppHit++;
          else if (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) oppHit++;
          else if (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value) oppHit++;
          
          return '';
        });
      }
      // setSeasonAvg(Math.round(all / games.length * 10) / 10);
      setSeasonHitCount(allHit);
      setTenAvg(Math.round(ten / 10 * 10) / 10);
      setTenHitCount(tenHit);
      setFiveAvg(Math.round(five / 5 * 10) / 10);
      setFiveHitCount(fiveHit);
      setThreeAvg(Math.round(three / 3 * 10) / 10);
      setThreeHitCount(threeHit);
      setHomeGameCount(homeGames);
      setHomeHitCount(homeHit);
      setAwayGameCount(awayGames);
      setAwayHitCount(awayHit);
      setVsAvg(Math.round(opp / oppGames.length * 10) / 10);
      setVSHitCount(oppHit);
      setWinGameCount(winGames);
      setWinHitCount(winHit);
      setLossGameCount(lossGames);
      setLossHitCount(lossHit);
      setPlayoffsGameCount(playoffsGames);
      setPlayoffsHitCount(playoffsHit);

      // Empty all averages if 'toi Played' is selected
      if (statRadio === 'toi') {
        setSeasonAvg('');
        setTenAvg('');
        setFiveAvg('');
        setThreeAvg('');
        setOneAvg('');
        setVsAvg(-1);
      }

      // Create object for chart of all player's games this season
      setChartData({
        // labels: games.slice(0).reverse().map(g => [g.location + (g.real_team ?? g.opponent), g.date.substring(5).replace('-', '/')]),
        labels: (hideLabels === 0 ? games.slice(0).reverse().map(g => [g.location + (g.real_team ?? g.opponent), g.date.substring(5).replace('-', '/')]) : games.slice(0).reverse().map(g => [''])),
        datasets: [{
          data: games.slice(0).reverse().map(g => {
            if (typeof g[statRadio] === "string") return g[statRadio].replace(':', '.');
            else return g[statRadio];
          }),
          backgroundColor: games.slice(0).reverse().map((g, index) => {
            // If filtering with hit-rate bubbles, ignore games that don't match criteria
            if (selectedHitCount === 'l10' && index < games.length - 10) return '#494949';
            if (selectedHitCount === 'l5' && index < games.length - 5) return '#494949';
            if (selectedHitCount === 'l3' && index < games.length - 3) return '#494949';
            if (selectedHitCount === 'l1' && index < games.length - 1) return '#494949';
            if (selectedHitCount === 'vs' && g.real_team !== player.opp && g.opponent !== player.opp) return '#494949';
            if (selectedHitCount === 'home' && g.location !== '') return '#494949';
            if (selectedHitCount === 'away' && g.location === '') return '#494949';
            if (selectedHitCount === 'win' && g.win_loss !== 'W') return '#494949';
            if (selectedHitCount === 'loss' && g.win_loss === 'W') return '#494949';
            if (selectedHitCount === 'playoffs' && g.playoffs !== 1) return '#494949';
            if (selectedHitCount === 'game' && g.game_player_id !== selectedGame) return '#494949';

            // If amount from game is greater than current line, set as green
            if ((statRadio === 'assists' && assists && Object.keys(assists).length > 0 && g[statRadio] > assists.over_value) ||
                (statRadio === 'goals' && goals && Object.keys(goals).length > 0 && g[statRadio] > goals.over_value) ||
                (statRadio === 'points' && points && Object.keys(points).length > 0 && g[statRadio] > points.over_value) ||
                (statRadio === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0 && g[statRadio] > ppPoints.over_value) ||
                (statRadio === 'shots_on_goal' && shots && Object.keys(shots).length > 0 && g[statRadio] > shots.over_value) ||
                (statRadio === 'saves' && saves && Object.keys(saves).length > 0 && g[statRadio] > saves.over_value)
              ) return '#198754';
            // If there is no line, shouldn't be red, so set as grey
            else if ((statRadio === 'assists' && (assists === undefined || Object.keys(assists).length === 0)) ||
                      (statRadio === 'goals' && (goals === undefined || Object.keys(goals).length === 0)) ||
                      (statRadio === 'points' && (points === undefined || Object.keys(points).length === 0)) ||
                      (statRadio === 'pp_points' && (ppPoints === undefined || Object.keys(ppPoints).length === 0)) ||
                      (statRadio === 'shots_on_goal' && (shots === undefined || Object.keys(shots).length === 0)) ||
                      (statRadio === 'saves' && (saves === undefined || Object.keys(saves).length === 0)) ||
                      (statRadio === 'toi')
                    ) return '#494949';
            // Otherwise, amount from game is less than line, set as red
            else return '#dc3545';
          })
        }]
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games, statRadio, selectedHitCount, selectedGame])

  /*
   * Get data for player
  */
  const getData = async () => {
    const response = await axios.get(API_URL + `pj/nhl/player/${id}`, { headers: { 'x-access-token': token } });
    if (process.env.NODE_ENV === 'development') console.log(response);

    // If token expired or doesn't exist, redirect to login page
    if (response.data.errorMessage) {
      // If user's account is inactive, redirect them to /account
      if (response.data.errorMessage === 'Inactive User') {
        navigate("/account");
      }
      else {
        sessionStorage.setItem('lastError', response.data.errorMessage);
        sessionStorage.removeItem('token');
        window.location.reload();
        navigate("/login");
      }
    }
    // If player is set
    else if (response.data.player && response.data.player.length > 0) {
      setPlayer(response.data.player[0]);

      // Set dropdown options
      setStatOptions([
        { value: 'assists', label: 'Assists' },
        { value: 'goals', label: 'Goals' },
        { value: 'points', label: 'Points' },
        { value: 'pp_points', label: 'Power Play Points' },
        { value: 'shots_on_goal', label: 'Shots On Goal' },
        { value: 'x', label: <><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</></>, isDisabled: true },
        { value: 'toi', label: 'Time On Ice' }
      ]);

      // If Assists is set
      if (response.data.assists) setAssists(response.data.assists[0]);
      // If Goals is set
      if (response.data.goals) setGoals(response.data.goals[0]);
      // If Points is set
      if (response.data.points) setPoints(response.data.points[0]);
      // If PP Points is set
      if (response.data.pp_points) setPPPoints(response.data.pp_points[0]);
      // If Shots is set
      if (response.data.shots) setShots(response.data.shots[0]);
      // If Saves is set
      if (response.data.saves) setSaves(response.data.saves[0]);

      // Set stat filter starting value
      if (stat === 'Assists') { setStatRadio('assists'); setLine((response.data.assists.length > 0) ? response.data.assists[0].over_value : ''); }
      else if (stat === 'Goals') { setStatRadio('goals'); setLine((response.data.goals.length > 0) ? response.data.goals[0].over_value : ''); }
      else if (stat === 'PP Points') { setStatRadio('pp_points'); setLine((response.data.pp_points.length > 0) ? response.data.pp_points[0].over_value : ''); }
      else if (stat === 'Shots') { setStatRadio('shots_on_goal'); setLine((response.data.shots.length > 0) ? response.data.shots[0].over_value : ''); }
      else if (stat === 'Saves') { setStatRadio('saves'); setLine((response.data.saves.length > 0) ? response.data.saves[0].over_value : ''); }
      else { setStatRadio('points'); setLine((response.data.points.length > 0) ? response.data.points[0].over_value : ''); }

      // If games is set
      if (response.data.games) setGames(response.data.games);

      // If games vs current opponent is set
      if (response.data.opp_games) setOppGames(response.data.opp_games);

      // If opponent's rankings is set
      if (response.data.oppRanks) setOppRanks(response.data.oppRanks[0]);

      sessionStorage.removeItem('lastError');
    }
    // Else, navigate back to NHL home
    else navigate("/nhl");
  }

  /*
   * Functions to set filter state variables
  */
  const saveStatRadio = (e) => {
    e = e.value || e;
    if (e === 'assists' && assists && Object.keys(assists).length > 0) setLine(assists.over_value)
    else if (e === 'goals' && goals && Object.keys(goals).length > 0) setLine(goals.over_value)
    else if (e === 'points' && points && Object.keys(points).length > 0) setLine(points.over_value)
    else if (e === 'pp_points' && ppPoints && Object.keys(ppPoints).length > 0) setLine(ppPoints.over_value)
    else if (e === 'shots_on_goal' && shots && Object.keys(shots).length > 0) setLine(shots.over_value)
    else if (e === 'saves' && saves && Object.keys(saves).length > 0) setLine(saves.over_value)
    else setLine('');
    
    setStatRadio(e);
  }

  const saveSelectedHit = (e) => {
    setSelectedGame(0);
    if (selectedHitCount !== e) setSelectedHitCount(e);
    else setSelectedHitCount('season');
  }

  const saveSelectedGame = (e) => {
    if (selectedGame !== e) {
      setSelectedHitCount('game');
      setSelectedGame(e);
    }
    else {
      setSelectedHitCount('season');
      setSelectedGame(0);
    }
  }

  const toggleCollapse = (e) => {
    if (collapse === 'd-none') setCollapse('');
    else setCollapse('d-none');
  }

  // Helper function to format and color rank
  const formatRank = (rank) => {
    if (rank !== undefined) {
      // If rank is empty, return nothing
      if (rank === '') return;

      rank = rank.toString();
      let rankStr = '';
      if (rank.substring(rank.length - 1) === '1') rankStr = rank + 'st';
      else if (rank.substring(rank.length - 1) === '2') rankStr = rank + 'nd';
      else if (rank.substring(rank.length - 1) === '3') rankStr = rank + 'rd';
      else rankStr = rank + 'th';

      // if (rank <= 10) rank = <span className="text-danger2">{rankStr}</span>;
      // else if (rank >= 23 ) rank = <span className="text-success2">{rankStr}</span>;
      // else rank = <span>{rankStr}</span>;
      rank = <span>({rankStr})</span>;
    }
    
    return rank;
  }

  return (
    <div className="px-12">
      <div className="row mt-2">
        <div className="col">
          <h2 className="text-light ff-g">{player.player_name} <span className="position-font"> {player.position !== undefined ? '(' + player.position + ')' : ''}</span></h2>
        </div>
        <div className="col-auto text-end">
          <h2 className="text-light ff-g"><span className="opponent-font">{player.opp !== undefined && player.opp !== '' ? player.opp + (player.location === '' ? ' @ ' : ' v. ') : ''}</span>{player.team}</h2>
        </div>
      </div>

      <hr></hr>

      {player.position !== undefined ? (
        <div>
          <div className="row text-white mt-2 mx-0">
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('points')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'points' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Points</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ppgl3 !== 'N/A' && points && Object.keys(points).length > 0 && player.ppgl3 < points.over_value ? "bg-danger border-danger" : (player.ppgl3 !== 'N/A' && points && Object.keys(points).length > 0 && player.ppgl3 >= points.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'points' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{points && points.over_value ? points.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{points && points.over_value ? (points.over_odds >= 100 ? '+' : '') + points.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{points && points.under_value ? points.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{points && points.under_value ? (points.under_odds >= 100 ? '+' : '') + points.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 pe-0 pe-sm-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('assists')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'assists' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Assists</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.apgl3 !== 'N/A' && assists && Object.keys(assists).length > 0 && player.apgl3 < assists.over_value ? "bg-danger border-danger" : (player.apgl3 !== 'N/A' && assists && Object.keys(assists).length > 0 && player.apgl3 >= assists.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'assists' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{assists && assists.over_value ? assists.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{assists && assists.over_value ? (assists.over_odds >= 100 ? '+' : '') + assists.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{assists && assists.under_value ? assists.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{assists && assists.under_value ? (assists.under_odds >= 100 ? '+' : '') + assists.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1" onClick={e => saveStatRadio('pp_points')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'pp_points' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>PP Points</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ppppgl3 !== 'N/A' && ppPoints && Object.keys(ppPoints).length > 0 && player.ppppgl3 < ppPoints.over_value ? "bg-danger border-danger" : (player.ppppgl3 !== 'N/A' && ppPoints && Object.keys(ppPoints).length > 0 && player.ppppgl3 >= ppPoints.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'pp_points' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{ppPoints && ppPoints.over_value ? ppPoints.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{ppPoints && ppPoints.over_value ? (ppPoints.over_odds >= 100 ? '+' : '') + ppPoints.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{ppPoints && ppPoints.under_value ? ppPoints.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{ppPoints && ppPoints.under_value ? (ppPoints.under_odds >= 100 ? '+' : '') + ppPoints.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 pe-0" onClick={e => saveStatRadio('shots_on_goal')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'shots_on_goal' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Shots</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.spgl3 !== 'N/A' && shots && Object.keys(shots).length > 0 && player.spgl3 < shots.over_value ? "bg-danger border-danger" : (player.spgl3 !== 'N/A' && shots && Object.keys(shots).length > 0 && player.spgl3 >= shots.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'shots_on_goal' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{shots && shots.over_value ? shots.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{shots && shots.over_value ? (shots.over_odds >= 100 ? '+' : '') + shots.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{shots && shots.under_value ? shots.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{shots && shots.under_value ? (shots.under_odds >= 100 ? '+' : '') + shots.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mx-3 ps-01 text-white">
            <Select 
              defaultValue={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              value={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              options={statOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral0: '#2c3237',
                  neutral80: 'white',
                  primary: 'grey',
                  primary25: '#198754'
                },
              })}
              styles={{
                menuList: base => ({
                  ...base,
                  lineHeight: '17px'
                })
              }}
              onChange={e => saveStatRadio(e)}
            />
          </div>

          <div className="text-center text-white rounded tight-shadow ms-1 me-0 my-3">
            <div className="row bg-bubble border-bottom border-bubble rounded-top fs-12 w-100 py-01 mx-0">
              <div className="col px-0"><strong>Season</strong></div>
              <div className="col px-0"><strong>L10</strong></div>
              <div className="col px-0"><strong>L5</strong></div>
              <div className="col px-0"><strong>L3</strong></div>
              <div className="col px-0"><strong>L1</strong></div>
              <div className="col px-0"><strong>vs {player.opp}</strong></div>
            </div>
            <div className="row fs-cust w-100 mx-0">
              <div className={"col orbitron px-0 mx-001 " + (seasonAvg !== '' ? (seasonAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-left py-0"}><strong>{seasonAvg !== '' ? seasonAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (tenAvg !== '' ? (tenAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{tenAvg !== '' ? tenAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (fiveAvg !== '' ? (fiveAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{fiveAvg !== '' ? fiveAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (threeAvg !== '' ? (threeAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{threeAvg !== '' ? threeAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (oneAvg !== '' ? (oneAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{oneAvg !== '' ? oneAvg : <>&mdash;</>}</strong></div>
              <div className={"col px-0 " + (vsAvg > -1 ? (vsAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-right py-0"}><strong className="orbitron">{(vsAvg > -1 ? vsAvg : <>&mdash;</>)}</strong> <div className="fs-cust-sm"> {statRadio !== 'toi' ? formatRank(oppRank) : ''}</div></div>
            </div>
          </div>
          
          <div className="bg-darkest rounded tight-shadow pt-1 pb-2 px-2 ms-1">
            <div className="row text-white">
              <div className="col orbitron text-orange pe-0"><strong>{line ? 'O/U: ' + line : ''}</strong></div>
              <div className="col orbitron ps-0 pe-5 me-5">{selectedHitCount === 'def' ? <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div> : ''}</div>
            </div>

            <div className="row chartRow mt-2">
              <div className="col chartCol">
                {(Object.keys(chartData).length > 0 ?
                  <Bar
                    data={chartData}
                    options={{
                      plugins: {
                        title: {
                          display: false
                        },
                        legend: {
                          display: false
                        },
                        tooltip: {
                          interaction: {
                            mode: 'index'
                          },
                          intersect: false,
                          callbacks: {
                            title: function(items) {
                              return items[0]['label'].substring(0, items[0]['label'].indexOf(','));
                            },
                            footer: function(items) {
                              return items[0]['label'].substring(items[0]['label'].indexOf(',')+1);
                            }
                          }
                        },
                        datalabels: {
                        labels: {
                          value: {
                              font: {
                                // size: (hideLabels === 0 && statRadio !== 'toi' ? '16' : (hideLabels === 0 ? '12' : '8'))
                                size: (hideLabels === 0 && statRadio !== 'toi' ? '12' : '0'),
                                family: 'Orbitron'
                              },
                              color: "white"
                            }
                          },
                          anchor: 'end',
                          align: 'start',
                          padding: '0',
                          formatter: function(value) {
                            if (value > 0 && typeof value !== "string") return value;
                            else if (hideLabels === 0 && value > 0 && typeof value === "string") return value.replace('.', ':');
                            else return '';
                          }
                        },
                        annotation: {
                          annotations: {
                            line1: {
                              type: 'line',
                              yMin: line,
                              yMax: line,
                              borderColor: '#fd7e14',
                              borderWidth: line > 0 ? 2 : 0
                            },
                            // label1: {
                            //   type: 'label',
                            //   xValue: (games.length > 1 && hideLabels === 0 ? 0.5 : (games.length > 1 && hideLabels === 1 ? 1.5 : 0)),
                            //   yValue: line,
                            //   content: [(line > 0 ? 'O/U: ' + line : '')],
                            //   font: { size: 14 },
                            //   color: 'white'
                            // }
                          }
                        }
                      },
                      scales: {
                        x: {
                          ticks: {
                            font: {
                              size: (hideLabels === 0 ? (selectedHitCount !== 'def' ? (games.length <= 25 ? 12 : (games.length <= 50 ? 6 : (games.length <= 75 ? 4 : 2))) : 
                                  (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 25 ? 12 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 50 ? 6 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 75 ? 4 : 2))))
                                  : 1)
                            }
                          },
                          grid: {
                            display: false
                          }
                        },
                        y: {
                          grid: {
                            display: false
                          }
                        },
                      }
                    }}
                  />
                : '')}
              </div>
            </div>

            <div className="row text-light fs-13 hit-count-row p-1 ms-2 mt-2">
              <div className="col centerer"></div>
              {games.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (seasonHitCount / games.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'season' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('season')}>
                  <div><strong>Season</strong></div>
                  <div>{seasonHitCount + ' of ' + games.length}</div>
                  <div>{Math.round(seasonHitCount / games.length * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 10 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (tenHitCount / 10 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l10' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l10')}>
                  <div><strong>L10</strong></div>
                  <div>{tenHitCount + ' of 10'}</div>
                  <div>{Math.round(tenHitCount / 10 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 5 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (fiveHitCount / 5 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l5' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l5')}>
                  <div><strong>L5</strong></div>
                  <div>{fiveHitCount + ' of 5'}</div>
                  <div>{Math.round(fiveHitCount / 5 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 3 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (threeHitCount / 3 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l3' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l3')}>
                  <div><strong>L3</strong></div>
                  <div>{threeHitCount + ' of 3'}</div>
                  <div>{Math.round(threeHitCount / 3 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (oneHitCount / 1 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l1' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l1')}>
                  <div><strong>L1</strong></div>
                  <div>{oneHitCount + ' of 1'}</div>
                  <div>{Math.round(oneHitCount / 1 * 100) + '%'}</div>
                </div>
              : ''}
              {oppGames.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (vsHitCount / oppGames.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'vs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('vs')}>
                  <div><strong>vs {player.opp}</strong></div>
                  <div>{vsHitCount + ' of ' + oppGames.length}</div>
                  <div>{Math.round(vsHitCount / oppGames.length * 100) + '%'}</div>
                </div>
              : ''}
              {homeGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (homeHitCount / homeGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'home' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('home')}>
                  <div><strong>Home</strong></div>
                  <div>{homeHitCount + ' of ' + homeGameCount}</div>
                  <div>{Math.round(homeHitCount / homeGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {awayGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (awayHitCount / awayGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'away' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('away')}>
                  <div><strong>Away</strong></div>
                  <div>{awayHitCount + ' of ' + awayGameCount}</div>
                  <div>{Math.round(awayHitCount / awayGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {winGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (winHitCount / winGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'win' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('win')}>
                  <div><strong>Win</strong></div>
                  <div>{winHitCount + ' of ' + winGameCount}</div>
                  <div>{Math.round(winHitCount / winGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {lossGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (lossHitCount / lossGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'loss' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('loss')}>
                  <div><strong>Loss</strong></div>
                  <div>{lossHitCount + ' of ' + lossGameCount}</div>
                  <div>{Math.round(lossHitCount / lossGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {/* {oppsGames.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col-wide text-center rounded tight-shadow border border-" + (defHitCount / defGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'def' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('def')}>
                  <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div>
                  <div>{defHitCount + ' of ' + defGameCount}</div>
                  <div>{Math.round(defHitCount / defGameCount * 100) + '%'}</div>
                </div>
              : ''} */}
              {playoffsGameCount > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (playoffsHitCount / playoffsGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'playoffs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('playoffs')}>
                  <div><strong>Playoffs</strong></div>
                  <div>{playoffsHitCount + ' of ' + playoffsGameCount}</div>
                  <div>{Math.round(playoffsHitCount / playoffsGameCount * 100) + '%'}</div>
                </div>
              : ''}
              <div className="col centerer"></div>
            </div>
          </div>

          <h3 className="mt-2 text-light ff-g"><strong><u>Game Log</u></strong></h3>
          <div className="row">
            <div className="game-log-table">
              <table className="table text-light mb-1">
                <thead>
                  <tr>
                    <th>G</th>
                    <th>Date</th>
                    <th>Opp</th>
                    <th>W/L</th>
                    <th>TOI</th>
                    <th>Goals</th>
                    <th>Assists</th>
                    <th>Points</th>
                    <th>PP Goals</th>
                    <th>PP Assists</th>
                    <th>PP Points</th>
                    <th>SOG</th>
                  </tr>
                </thead>
                <tbody>
                  {games.map((g, index) => 
                    <tr key={index} className={"cursor-pointer " + (g.game === 1 && g.playoffs === 1 ? "border-bottom border-purple " : "") + (selectedGame === g.game_player_id ? "game-selected" : "")} onClick={e => saveSelectedGame(g.game_player_id)}>
                      <td>{(g.playoffs === 1 ? 'P' : '') + g.game}</td>
                      <td className="whitespace-nowrap">{g.date.substring(0, 10)}</td>
                      <td>{g.location + (g.real_team ?? g.opponent)}</td>
                      <td>{g.win_loss}</td>
                      <td>{g.toi}</td>
                      <td>{g.goals}</td>
                      <td>{g.assists}</td>
                      <td>{g.points}</td>
                      <td>{g.pp_goals}</td>
                      <td>{g.pp_assists}</td>
                      <td>{g.pp_points}</td>
                      <td>{g.shots_on_goal}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-center">
            <button type="button" className="btn header-btn text-primary p-2 mb-2" onClick={e => toggleCollapse(e)}>Toggle Header Legend</button>
            <div className={collapse + " header-div border border-white rounded text-start text-white py-1 px-3 mb-3"} id="headerLegend">
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>G</strong> - Game</div>
                <div className="col-md-12 col-lg-3"><strong>Opp</strong> - Opponent</div>
                <div className="col-md-12 col-lg-3"><strong>W/L</strong> - Win / Loss</div>
                <div className="col-md-12 col-lg-3"><strong>TOI</strong> - Time On Ice</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>PP Goals</strong> - Power Play Goals</div>
                <div className="col-md-12 col-lg-3"><strong>PP Assists</strong> - Power Play Assists</div>
                <div className="col-md-12 col-lg-3"><strong>PP Points</strong> - Power Play Points</div>
                <div className="col-md-12 col-lg-3"><strong>SOG</strong> - Shots On Goal</div>
              </div>
            </div>
          </div>
        </div>) : ''}
    </div>
  );
}
 
export default PlayerData;