import React from "react";
import AppleLogin from "react-apple-login";
import axios from "axios";

class AppleButtonS extends React.Component {
  state = {
    authResponse: {}
  };
  appleResponse = response => {
    var API_URL;
    if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
    else API_URL = 'https://prop-jockey.herokuapp.com/';
  
    if (!response.error) {
      axios
        .post(API_URL + "pj/authS", response)
        .then(res => {
          this.setState({ authResponse: res.data });
          // console.log(res);
        })
        .catch(err => console.log(err));
    }
  };

  render() {
    return (
      <div className="mt-3 text-center">
        <div>
          {Object.keys(this.state.authResponse).length === 0 ? (
            <AppleLogin
              clientId="com.ejcox.propjockeys"
              redirectURI="https://prop-jockey.herokuapp.com/pj/authS"
              usePopup={false}
              callback={this.appleResponse}
              responseType="code id_token"
              scope="name email"
              responseMode="form_post"
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  className="w-100"
                  style={{
                    backgroundColor: "black",
                    padding: 10,
                    border: "1px solid black",
                    borderRadius: '5px',
                    lineHeight: "20px",
                    fontSize: "20px",
                    color: "white"
                  }}
                >
                  <i className="fa-brands fa-apple px-2 "></i>
                  Sign up with Apple
                </button>
              )}
            />
          ) : (
            <p className="text-white">
              {JSON.stringify(this.state.authResponse)}
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default AppleButtonS;