// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// // eslint-disable-next-line
// import { Collapse } from 'bootstrap';
import { useEffect, useState } from 'react'
import { useEffectOnce } from '../useEffectOnce';
import useToken from '../Auth/useToken';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import bcrypt from 'bcryptjs-react';
import './Account.css';
 
const Account = (props) => {
  const navigate = useNavigate();
  const {token} = useToken();

  const [user, setUser] = useState([]);
  const [selectedSection, setSelectedSection] = useState('account');
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(0);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(0);
  const [passwordLength, setPasswordLength] = useState(0);
  const [passwordUpper, setPasswordUpper] = useState(0);
  const [passwordNumber, setPasswordNumber] = useState(0);
  const [passwordSpecial, setPasswordSpecial] = useState(0);
  const [newPassword2, setNewPassword2] = useState('');
  const [newPassword2Error, setNewPassword2Error] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackPage, setFeedbackPage] = useState('');
  const [feedbackOtherPage, setFeedbackOtherPage] = useState('');
  const [feedbackNotes, setFeedbackNotes] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const salt = bcrypt.genSaltSync(10);

  const [waitlist, setWaitlist] = useState([]);
  const [users, setUsers] = useState([]);
  const [logins, setLogins] = useState([]);
  const [league, setLeague] = useState('');
  const [players, setPlayers] = useState([]);
  
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';
  
  /*
   * Run once on load
  */
  useEffectOnce(() => {
    props.setActive('account');

    // // Hide labels on chart on smaller screens
    // if (window.innerWidth < 500) setHideLabels(1);

    getData();
  });

  /*
   * Run when of variables in array below are changed
  */
  useEffect(() => {
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitlist, users, logins, players])

  /*
   * Get data for player
  */
  const getData = async () => {
    const response = await axios.get(API_URL + `pj/getUserInfo`, { headers: { 'x-access-token': token } });
    if (process.env.NODE_ENV === 'development') console.log(response);

    // If token expired or doesn't exist, redirect to login page
    if (response.data.errorMessage) {
      sessionStorage.setItem('lastError', response.data.errorMessage);
      sessionStorage.removeItem('token');
      window.location.reload();
      navigate("/login");
    }
    // If user found
    else if (response.data.user && response.data.user.length > 0) { 
      setUser(response.data.user[0]);
    }
    // Else, navigate to login
    else navigate("/login");
  }

  /*
   * Functions to change password
  */
  const getUser = async (email) => {
    return fetch(API_URL + 'pj/getUserAccount', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(email)
    })
    .then(data => data.json())
  }
  const changeUserPassword = async (credentials) => {
    return fetch(API_URL + 'pj/changeUserPassword', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(credentials)
    })
    .then(data => data.json())
  }
  const handleChangePassword = async e => {
    e.preventDefault();

    // Declare variables and clear errors
    var changePassword = true;
    setSuccessMsg('');
    setCurrentPasswordError(0);
    setNewPasswordError(0);
    setPasswordLength(0);
    setPasswordUpper(0);
    setPasswordNumber(0);
    setPasswordSpecial(0);
    setNewPassword2Error(0);
    setErrorMsg('');
    setSuccessMsg('');

    // If new passwords don't match
    if (newPassword !== newPassword2) { setErrorMsg("New passwords do not match"); setNewPassword2Error(1); changePassword = false; }
    // If password doesn't meet complexity requirements
      // Length
      if (newPassword.length < 8) { setErrorMsg('password'); setNewPasswordError(1); setNewPassword2Error(1); setPasswordLength(1); changePassword = false; }
      // Uppercase
      if (!testUppercase(newPassword)) { setErrorMsg('password'); setNewPasswordError(1); setNewPassword2Error(1); setPasswordUpper(1); changePassword = false; }
      // Number
      if (!testNumber(newPassword)) { setErrorMsg('password'); setNewPasswordError(1); setNewPassword2Error(1); setPasswordNumber(1); changePassword = false; }
      // Special Character
      if (!testSpecial(newPassword)) { setErrorMsg('password'); setNewPasswordError(1); setNewPassword2Error(1); setPasswordSpecial(1); changePassword = false; }
      // Illegal Character
      if (testIllegal(newPassword)) { setErrorMsg('illegal'); setNewPasswordError(1); setNewPassword2Error(1); changePassword = false; }
    // If 'Current Password' is empty
    if (currentPassword.length === 0) { setErrorMsg('Current password must be filled'); setCurrentPasswordError(1); changePassword = false; }

    // If all criteria is met to change password
    if (changePassword) {
      // Get password for user
      const userInfo = await getUser({
        email: user.email
      });

      // Compare stored password and inputted 'Current Password'
      if (userInfo.user.length > 0) {
        bcrypt.compare(currentPassword, userInfo.user[0].password, function(err, result) {
          // If 'Current Password' is correct, change password
          if (result) doChangePassword();
          // Otherwise, show error message
          else {
            setErrorMsg("Incorrect password");
            setCurrentPasswordError(1);
          }
        });
      }
    }
  }
  const doChangePassword = async e => {
    const changed = await changeUserPassword({
      password: bcrypt.hashSync(newPassword, salt)
    });

    // If token expired or doesn't exist, redirect to login page
    if (changed.errorMessage) {
      sessionStorage.setItem('lastError', changed.errorMessage);
      sessionStorage.removeItem('token');
      window.location.reload();
      navigate("/login");
    }
    // If password changed successfully
    else if (changed.message && changed.message === 'success') {
      setCurrentPassword('');
      document.getElementById('current_password').value = '';
      setNewPassword('');
      document.getElementById('new_password').value = '';
      setNewPassword2('');
      document.getElementById('new_password2').value = '';
      setSuccessMsg('Password Changed');
    }
  }

  /*
   * Helper function to show complexity requirements as user enters password
  */
  const checkPassword = (e) => {
    setSuccessMsg('');
    setErrorMsg('');
    setNewPasswordError(0);
    setPasswordLength(0);
    setPasswordUpper(0);
    setPasswordNumber(0);
    setPasswordSpecial(0);

    // If password doesn't meet complexity requirements
      // Length
      if (e.target.value.length < 8) { setErrorMsg('password'); setNewPasswordError(1); setPasswordLength(1); }
      // Uppercase
      if (!testUppercase(e.target.value)) { setErrorMsg('password'); setNewPasswordError(1); setPasswordUpper(1); }
      // Number
      if (!testNumber(e.target.value)) { setErrorMsg('password'); setNewPasswordError(1); setPasswordNumber(1); }
      // Special Character
      if (!testSpecial(e.target.value)) { setErrorMsg('password'); setNewPasswordError(1); setPasswordSpecial(1); }
      // Illegal Character
      if (testIllegal(e.target.value)) { setErrorMsg('illegal'); setNewPasswordError(1); }
  }
  
  /*
   * Helper functions to check password complexity requirements
  */
  const testUppercase = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (character.match(/[a-z]/i) && character === character.toUpperCase()) return true;
    }
    return false;
  }
  const testNumber = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (!isNaN(character * 1)) return true;
    }
    return false;
  }
  const testSpecial = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (character.match(/[!@#$%^&*]/i)) return true;
    }
    return false;
  }
  const testIllegal = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (!character.match(/[0-9a-z!@#$%^&*]/i)) return true;
    }
    return false;
  }

  /*
   * Functions to submit feedback
  */
  const submitFeedback = async (info) => {
    return fetch(API_URL + 'pj/feedback', {
      method: 'POST',
      // credentials: 'include',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(info)
    })
    .then(data => data.json());
  }
  const handleSubmit = async e => {
    e.preventDefault();

    const submitted = await submitFeedback({
      type: feedbackType,
      page: feedbackPage,
      otherPage: (feedbackPage === 'Other' ? feedbackOtherPage : null),
      notes: feedbackNotes
    });

    // If token expired or doesn't exist, redirect to login page
    if (submitted.errorMessage) {
      sessionStorage.setItem('lastError', submitted.errorMessage);
      sessionStorage.removeItem('token');
      window.location.reload();
      navigate("/login");
    }
    // If feedback submitted successfully
    else if (submitted.message && submitted.message === 'submitted') {
      setFeedbackSubmitted(true);
      let typeOptions = document.getElementById('type').options;
      for (var i = 0; i < typeOptions.length; i++) typeOptions[i].selected = false;
      let pageOptions = document.getElementById('page').options;
      for (i = 0; i < pageOptions.length; i++) pageOptions[i].selected = false;
      if (document.getElementById('otherPage')) document.getElementById('otherPage').value = '';
      document.getElementById('notes').value = '';
    }
  }

  /*
   * ADMIN
   * Get admin data
  */
  // Get waitlist and users
  const getAdminData = async (e) => {
    //! ADMIN
    if (user.admin === 1) {
      // Waitlist
      const response = await axios.get(API_URL + `pj/getWaitlist`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response.data);
      setWaitlist(response.data);

      // Users
      const response2 = await axios.get(API_URL + `pj/getUsers`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response2.data);
      setUsers(response2.data);

      // Users
      const response3 = await axios.get(API_URL + `pj/getLogins`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response3.data);
      setLogins(response3.data);
    }
  }
  // Create a beta key for waitlisted user
  const admitWaitlistee = async (waitlistee) => {
    const data = { email: waitlistee.email };
    await axios.post(API_URL + `pj/createKey`, data, { headers: { 'x-access-token': token } }).then(getAdminData());
  }
  // Toggle user account lock
  const lockUser = async (u) => {
    const data = { uuid: u.uuid, locked: u.locked };
    await axios.post(API_URL + `pj/lockUser`, data, { headers: { 'x-access-token': token } }).then(getAdminData());
  }
  // Toggle user account active
  const activateUser = async (u) => {
    const data = { uuid: u.uuid, active: u.active };
    await axios.post(API_URL + `pj/activateUser`, data, { headers: { 'x-access-token': token } }).then(getAdminData());
  }
  // Refresh waitlist and users
  const refresh = (e) => {
    getAdminData();
  }

  // Change league to list players for
  const change_league_players = async (e) => {
    // Get players for league
    if (e !== '') {
      const response = await axios.get(API_URL + `pj/admin/players/${e}`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response);
      setPlayers(response.data);
      setLeague(e);
    }
    else { setPlayers([]); setLeague(''); }
  }

  // Change player' status
  const change_player_status = async (player_id, status) => {
    const data = { 'league': league, 'player_id': player_id, 'status': status };
    await axios.post(API_URL + `pj/admin/player_status`, data, { headers: { 'x-access-token': token } }).then(change_league_players(league));
  }

  /*
   * Functions to set filter state variables
  */
  const saveSectionSelected = (e) => {
    setCurrentPasswordError(0);
    setNewPasswordError(0);
    setNewPassword2Error(0);
    setErrorMsg('');
    setSuccessMsg('');
    setFeedbackSubmitted(false);

    if (e !== selectedSection) setSelectedSection(e);
  }

  /*
   * Logout
  */
  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("firstLogin");
    sessionStorage.removeItem('acceptTC');
    sessionStorage.removeItem("lastError");
    navigate("/");
    window.location.reload();
  }

  // Helper function to capitalize first letter of word
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="px-12 text-white">
      {Object.keys(user).length > 0 && user.active === 0 ? <div className="text-center text-white bg-danger rounded p-2 mt-2"><strong>Your account has not yet been activated, please wait</strong></div> : ''}
      <div className="row mt-4">
        <div className="col-auto account-sidebar">
          <div className="bg-bubble text-white rounded pt-1 pb-2 px-3">
            <div className={"row section-selector " + (selectedSection === 'account' ? 'section-selected' : '') + " border-bottom border-secondary py-2"} onClick={e => saveSectionSelected('account')}>
              <div className="col">
                Account Settings
              </div>
            </div>
            {user.authService === 'basic' ? 
              <div className={"row section-selector " + (selectedSection === 'password' ? 'section-selected' : '') + " border-bottom border-secondary py-2"} onClick={e => saveSectionSelected('password')}>
                <div className="col">
                  Change Password
                </div>
              </div>
            : ''}
            <div className={"row section-selector " + (selectedSection === 'sub' ? 'section-selected' : '') + " border-bottom border-secondary py-2"} onClick={e => saveSectionSelected('sub')}>
              <div className="col">
                Subscription
              </div>
            </div>
            <div className={"row section-selector " + (selectedSection === 'feedback' ? 'section-selected' : '') + " py-2"} onClick={e => saveSectionSelected('feedback')}>
              <div className="col">
                Submit Feedback
              </div>
            </div>

            {/* //! ADMIN */}
            {user.admin === 1 ? 
              <div className={"row section-selector " + (selectedSection === 'admin' ? 'section-selected' : '') + " border-top border-secondary  py-2"} onClick={e => { getAdminData(); saveSectionSelected('admin'); }}>
                <div className="col">
                  Admin
                </div>
              </div>
            : ''}
          </div>
        </div>
        <div className="col">
          <div className="text-white bg-bubble rounded py-3 px-4">
            {Object.keys(user).length > 0 && selectedSection === 'account' ?
              <div className="row border-bottom border-secondary pb-2">
                <div className="col">
                  <h3>{user.name}</h3>
                  <h6>{user.email}</h6>
                  Subscription: <strong>{capitalizeFirstLetter(user.subscription)}</strong>
                </div>
                <div className="col-auto">
                  <button className="btn btn-danger" onClick={logout}><strong>Logout</strong></button>
                </div>
              </div>

            : Object.keys(user).length > 0 && selectedSection === 'password' ?
              <div className="row pb-2">
                <div className="col" data-bs-theme="dark">
                  <div className="form-floating mb-3">
                    <input type="password" className={"form-control text-white " + (currentPasswordError === 1 ? "border-error" : "")} id="current_password" placeholder="Current Password" onChange={e => setCurrentPassword(e.target.value)} />
                    <label className={"form-control-label-white " + (currentPasswordError === 1 ? "text-error" : "")} htmlFor="current_password"><strong>Current Password</strong></label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="password" className={"form-control text-white " + (newPasswordError === 1 ? "border-error" : "")} id="new_password" placeholder="New Password" onChange={e => { setNewPassword(e.target.value); checkPassword(e); }} />
                    <label className={"form-control-label-white " + (newPasswordError === 1 ? "text-error" : "")} htmlFor="new_password"><strong>New Password</strong></label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="password" className={"form-control text-white " + (newPassword2Error === 1 ? "border-error" : "")} id="new_password2" placeholder="Confirm New Password" onChange={e => setNewPassword2(e.target.value)} />
                    <label className={"form-control-label-white " + (newPassword2Error === 1 ? "text-error" : "")} htmlFor="new_password2"><strong>Confirm New Password</strong></label>
                  </div>
                  <button className="btn btn-success" onClick={handleChangePassword}><strong>Change Password</strong></button>

                  {errorMsg !== '' && errorMsg !== 'password' && errorMsg !== 'illegal' ? <div className="text-danger fs-6 mt-3">{errorMsg}</div> : ''}
                  {errorMsg === 'password' ? <div className="text-danger fs-14 mt-3">Passwords must include: 
                      <ul>
                        <li className={passwordLength === 1 ? 'text-danger' : 'text-success'}>at least 8 characters</li>
                        <li className={passwordUpper === 1 ? 'text-danger' : 'text-success'}>an uppercase letter</li>
                        <li className={passwordNumber === 1 ? 'text-danger' : 'text-success'}>a number</li>
                        <li className={passwordSpecial === 1 ? 'text-danger' : 'text-success'}>at least one of the following:
                          <ul>
                            <li>! @ # $ % ^ & *</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  : ''}
                  {errorMsg === 'illegal' ? <div className="text-danger fs-14 mt-3">Only the following special characters are allowed: 
                      <ul>
                        <li>! @ # $ % ^ & *</li>
                      </ul>
                    </div>
                  : ''}

                  {successMsg !== '' ? <div className="text-success fs-6 mt-3">{successMsg}</div> : ''}
                </div>
              </div>

            : Object.keys(user).length > 0 && selectedSection === 'sub' ?
              <div className="row">
                <div className="col">
                  <div className="row border-bottom border-secondary">
                    <div className="col">
                      <h3>{capitalizeFirstLetter(user.subscription)}</h3>
                    </div>
                  </div>

                  <div className="row pt-2 g-4">
                    <div className="col-md-12 col-lg-4">
                      <div className="card text-white card-design-reverse h-100 shadow">
                        <div className="card-body d-flex flex-column text-center">
                          <h3 className="card-title border border-white rounded p-1 orbitron"><strong>Monthly Pass</strong></h3>
                          <div className="card-text">
                            <div className="rounded shadow p-2 mb-3"><strong>Month by month</strong> full access to <strong>all</strong> sports</div>
                            <div className="rounded shadow p-2 mb-3">Prop ranking & analysis by our advanced algorithms</div>
                            <div className="rounded shadow p-2 mb-3">Updated lines and odds from major books</div>
                            <div className="rounded shadow p-2 mb-3">User friendly visualizations & game log breakdowns</div>
                            {/* <div className="rounded shadow p-2 mb-3">Access to website & Discord (Mobile App Coming Soon!)</div> */}
                          </div>
                          <h1 className="mt-auto"><strong>$9.99</strong></h1>
                          <button className="btn bg-orange shadow fs-5 py-1 w-100 mt-1"><strong>Start Free Trial</strong></button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                      <div className="card text-white card-design-reverse h-100 shadow">
                        <div className="card-body d-flex flex-column text-center">
                          <h3 className="card-title border border-white rounded p-1 orbitron"><strong>Season Pass</strong></h3>
                          <div className="card-text">
                            <div className="rounded shadow p-2 mb-3"><strong>Season-long</strong> full access to <strong>one</strong> sport</div>
                            <div className="rounded shadow p-2 mb-3">Prop ranking & analysis by our advanced algorithms</div>
                            <div className="rounded shadow p-2 mb-3">Updated lines and odds from major books</div>
                            <div className="rounded shadow p-2 mb-3">User friendly visualizations & game log breakdowns</div>
                            {/* <div className="rounded shadow p-2 mb-3">Access to website & Discord (Mobile App Coming Soon!)</div> */}
                          </div>
                          <h1 className="mt-auto"><strong>$49.99</strong></h1>
                          <button className="btn bg-orange shadow fs-5 py-1 w-100 mt-1"><strong>Start Free Trial</strong></button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                      <div className="card text-white card-design-reverse h-100 shadow">
                        <div className="card-body d-flex flex-column text-center">
                          <h3 className="card-title border border-white rounded p-1 orbitron"><strong>Yearly Pass</strong></h3>
                          <div className="card-text">
                            <div className="rounded shadow p-2 mb-3"><strong>Year-round</strong> full access to <strong>all</strong> sports</div>
                            <div className="rounded shadow p-2 mb-3">Prop ranking & analysis by our advanced algorithms</div>
                            <div className="rounded shadow p-2 mb-3">Updated lines and odds from major books</div>
                            <div className="rounded shadow p-2 mb-3">User friendly visualizations & game log breakdowns</div>
                            {/* <div className="rounded shadow p-2 mb-3">Access to website & Discord (Mobile App Coming Soon!)</div> */}
                          </div>
                          <h1 className="mt-auto"><strong>$99.99</strong></h1>
                          <button className="btn bg-orange shadow fs-5 py-1 w-100 mt-1"><strong>Start Free Trial</strong></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            : Object.keys(user).length > 0 && selectedSection === 'feedback' ?
              <div className="row">
                <div className="col">
                  <div className="row border-bottom border-secondary">
                    <div className="col">
                      <h3>Submit Feedback / Report a Bug</h3>
                    </div>
                  </div>

                  <form className="text-white mt-4" onSubmit={handleSubmit} data-bs-theme="dark">
                    <div className="form-floating mb-3">
                      <select className="form-select text-white pt-3 pb-0 px-3" id="type" placeholder="Type" onChange={e => setFeedbackType(e.target.selectedOptions[0].text)} required>
                        <option></option>
                        <option>Bug</option>
                        <option>Feature Suggestion</option>
                        <option>General Feedback</option>
                      </select>
                      <label className="py-2 px-2" htmlFor="serviceCenter"><strong>Type</strong></label>
                    </div>

                    <div className="form-floating mb-3">
                      <select className="form-select text-white pt-3 pb-0 px-3" id="page" placeholder="Page" onChange={e => setFeedbackPage(e.target.selectedOptions[0].text)}>
                        <option></option>
                        <option>NBA Props List (/nba)</option>
                        <option>NBA Player Page (/nba/player/...)</option>
                        <option>NFL Props List (/nfl)</option>
                        <option>NFL Player Page (/nfl/player/...)</option>
                        <option>NHL Props List (/nhl)</option>
                        <option>NHL Player Page (/nhl/player/...)</option>
                        <option>Other</option>
                      </select>
                      <label className="py-2 px-2" htmlFor="serviceCenter"><strong>Page</strong></label>
                    </div>

                    {feedbackPage === 'Other' ? 
                      <div className="form-floating mb-3">
                        <input type="text" className="form-control text-white" id="otherPage" placeholder="Other Page" maxLength="250" onChange={e => setFeedbackOtherPage(e.target.value)} />
                        <label className="form-control-label-white" htmlFor="otherPage"><strong>Other Page</strong></label>
                      </div>
                    : ''}

                    <div className="form-floating mb-3">
                      <textarea className="form-control text-white" id="notes" placeholder="Notes" maxLength="8000" onChange={e => setFeedbackNotes(e.target.value)} required></textarea>
                      <label className="form-control-label-white" htmlFor="notes"><strong>Notes</strong></label>
                    </div>

                    <button type="submit" className="btn btn-success fs-6 px-3 py-1"><strong>Submit</strong></button>

                    {feedbackSubmitted ? <div className="text-success mt-3">Feedback submitted. Thank You</div> : ''}
                  </form>
                </div>
              </div>
            
            //! ADMIN
            : Object.keys(user).length > 0 && user.admin === 1 && selectedSection === 'admin' ?
              <div className="row">
                <div className="col">
                  <div className="row mb-1">
                    <div className="col"></div>
                    <div className="col-auto">
                      <button className="btn btn-outline-primary py-0 px-1" onClick={e => refresh(e)}><i className="fas fa-sync-alt"></i></button>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item bg-darkest">
                      <h2 className="accordion-header">
                        <button className="bg-darkest text-white accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Waitlist
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-white">
                          {waitlist.map((w, index) => (
                            <div className="row border-bottom border-white fs-12 py-2" key={index}>
                              <div className="col">{w.email}</div>
                              <div className="col">{w.city}, {w.state}, {w.zip}, {w.country}</div>
                              <div className="col">{DateTime.fromISO(w.timestamp.replace(' ', 'T')).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                              <div className="col">
                                {w.beta_key === null ?
                                  <button type="button" className="btn btn-warning fs-12 py-0" onClick={e => admitWaitlistee(w)}>key</button>
                                  : <div className={"border border-" + (w.used === 1 ? 'success' : 'danger') + " rounded py-1 px-2"} style={{cursor: 'pointer'}} onClick={e => navigator.clipboard.writeText(e.target.innerText)}>
                                      {'https://propjockey.ai/signup?key=' + w.beta_key}
                                    </div>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-darkest">
                      <h2 className="accordion-header">
                        <button className="bg-darkest text-white accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Users
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-white">
                          {users.map((u, index) => (
                            <div className="row border-bottom border-white fs-12 py-2" key={index}>
                              <div className="col">{u.email}</div>
                              <div className="col">{u.authService}</div>
                              <div className="col">{DateTime.fromISO(u.createdDate.replace(' ', 'T')).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                              <div className="col">{u.osName} - {u.browserName}</div>
                              <div className="col">{u.city}, {u.state}, {u.zip}, {u.country}</div>
                              <div className="col">{DateTime.fromISO(u.timestamp.replace(' ', 'T')).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                              <div className="col-auto">
                                {u.locked === 1 ?
                                  <button type="button" className="btn btn-danger" onClick={e => lockUser(u)}>L</button>
                                  : <button type="button" className="btn btn-success" onClick={e => lockUser(u)}>U</button>
                                }
                              </div>
                              <div className="col-auto">
                                {u.active === 0 ?
                                  <button type="button" className="btn btn-danger" onClick={e => activateUser(u)}>I</button>
                                  : <button type="button" className="btn btn-success" onClick={e => activateUser(u)}>A</button>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-darkest">
                      <h2 className="accordion-header">
                        <button className="bg-darkest text-white accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Logins
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-white">
                          {logins.map((l, index) => (
                            <div className="row border-bottom border-white fs-12 py-2" key={index}>
                              <div className="col">{(l.name !== null && l.name !== '' ? l.name : l.email)}</div>
                              <div className="col">{l.city}, {l.state}, {l.country}</div>
                              <div className="col">{l.osName} - {l.browserName}</div>
                              <div className="col">{DateTime.fromISO(l.timestamp.replace(' ', 'T')).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-darkest">
                      <h2 className="accordion-header">
                        <button className="bg-darkest text-white accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          Players
                        </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-white">
                          <div className="row mb-2">
                            <div className="col" data-bs-theme="dark">
                              <select className="form-select text-white" id="league_players" onChange={e => change_league_players(e.target.selectedOptions[0].value)}>
                                <option value=""></option>
                                <option value="MLB">MLB</option>
                                <option value="NBA">NBA</option>
                                <option value="NFL">NFL</option>
                                <option value="NHL">NHL</option>
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            {players.length > 0 ?
                              <table className="table text-white">
                                <thead>
                                  <tr>
                                    <th>Team</th>
                                    <th>Player</th>
                                    <th>Games</th>
                                    <th>Active</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {players.map((p, index) => 
                                    <tr key={index} className="fs-14">
                                      <td>{p.team}</td>
                                      <td>{p.player_name}</td>
                                      <td>{p.game_count ?? '0'}</td>
                                      <td>{p.active}</td>
                                      <td>
                                        {(p.active === 1 ? <button className="btn btn-danger py-0 px-1" onClick={e => change_player_status(p.player_id, 0)}><i className="fas fa-ban"></i></button> 
                                          : <button className="btn btn-success py-0 px-1" onClick={e => change_player_status(p.player_id, 1)}><i className="fas fa-check-circle"></i></button>)
                                        }
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            : ''}
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Account;