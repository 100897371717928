import React, { useState } from 'react';
import { useEffectOnce } from '../useEffectOnce';
import { useLocation, useNavigate } from "react-router-dom";
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";
import axios from "axios";
import bcrypt from 'bcryptjs-react';

export default function ResetPassword () {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(0);
  const [passwordLength, setPasswordLength] = useState(0);
  const [passwordUpper, setPasswordUpper] = useState(0);
  const [passwordNumber, setPasswordNumber] = useState(0);
  const [passwordSpecial, setPasswordSpecial] = useState(0);
  const [password2, setPassword2] = useState('');
  const [password2Error, setPassword2Error] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordReset, setPasswordReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(0);
  const [secToRedirect, setSecToRedirect] = useState(3);
  const salt = bcrypt.genSaltSync(10);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  // Clear all filters and data in local storage
  //TODO just for beta testing?
  localStorage.clear();

  useEffectOnce(() => {
    // If key parameter exists in URL, call function to check that key is valid
    if (params.get('key') !== undefined && params.get('key') !== null && params.get('key') !== '' && params.get('key').length === 24) {
      checkKey();
    }
    else navigate('/login');
  });

  const checkKey = e => {
    const keyData = { key: params.get('key') };
    axios.post(API_URL + `pj/checkResetKey`, keyData, { headers: { 'token': 'XkjQzRQEsm2Ztvcj6kd6LqWL' } })
         .then((keyData) => { if (Object.keys(keyData.data).length > 0 && keyData.data.length > 0 && keyData.data[0].email !== null && keyData.data[0].email !== '') setResetPassword(1); else navigate('/enroll'); });
  }

  const handleSubmit = async e => {
    e.preventDefault();

    // Declare variables and clear errors
    var resetPassword = true;
    setPasswordError(0);
    setPasswordLength(0);
    setPasswordUpper(0);
    setPasswordNumber(0);
    setPasswordSpecial(0);
    setPassword2Error(0);
    setErrorMsg('');
    setPasswordReset(false);

    // If passwords don't match
    if (password !== password2) { setErrorMsg("Passwords do not match"); setPassword2Error(1); resetPassword = false; }
    // If password doesn't meet complexity requirements
      // Length
      if (password.length < 8) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordLength(1); resetPassword = false; }
      // Uppercase
      if (!testUppercase(password)) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordUpper(1); resetPassword = false; }
      // Number
      if (!testNumber(password)) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordNumber(1); resetPassword = false; }
      // Special Character
      if (!testSpecial(password)) { setErrorMsg('password'); setPasswordError(1); setPassword2Error(1); setPasswordSpecial(1); resetPassword = false; }
      // Illegal Character
      if (testIllegal(password)) { setErrorMsg('illegal'); setPasswordError(1); setPassword2Error(1); resetPassword = false; }

    // If password is empty
    if (password.length < 1) { setErrorMsg("All fields must be filled"); setPasswordError(1); resetPassword = false; }
    // If password2 is empty
    if (password2.length < 1) { setErrorMsg("All fields must be filled"); setPassword2Error(1); resetPassword = false; }
    
    // If all criteria met, update password
    if (resetPassword) {
      const data = {
        password: bcrypt.hashSync(password, salt),
        hash: params.get('key')
      };
      axios.post(API_URL + `pj/updatePassword`, data, { headers: { 'token': 'XkjQkRQEhdtZtvcj6xS2LqWL' } })
        .then((res) => {
          // Password updated successfully
          if (res.data.length > 0 && res.data[0].status === 1) {
            document.getElementById('password').value = '';
            document.getElementById('password2').value = '';

            setPasswordReset(true);

            // Start countdown timer to redirect user to /login after 3 seconds
            var start = 3;
            setInterval(() => {
              setSecToRedirect(--start);
              if (start === 0) navigate('/login');
            }, 1000);
          }
          // Otherwise, an issue occurred
          else setErrorMsg("Password reset link has already been used");
        });
    }
  }

  /*
   * Helper function to show complexity requirements as user enters password
  */
  const checkPassword = (e) => {
    setErrorMsg('');
    setPasswordError(0);
    setPasswordLength(0);
    setPasswordUpper(0);
    setPasswordNumber(0);
    setPasswordSpecial(0);

    // If password doesn't meet complexity requirements
      // Length
      if (e.target.value.length < 8) { setErrorMsg('password'); setPasswordError(1); setPasswordLength(1); }
      // Uppercase
      if (!testUppercase(e.target.value)) { setErrorMsg('password'); setPasswordError(1); setPasswordUpper(1); }
      // Number
      if (!testNumber(e.target.value)) { setErrorMsg('password'); setPasswordError(1); setPasswordNumber(1); }
      // Special Character
      if (!testSpecial(e.target.value)) { setErrorMsg('password'); setPasswordError(1); setPasswordSpecial(1); }
      // Illegal Character
      if (testIllegal(e.target.value)) { setErrorMsg('illegal'); setPasswordError(1); }
  }
  
  /*
   * Helper functions to check password complexity requirements
  */
  const testUppercase = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (character.match(/[a-z]/i) && character === character.toUpperCase()) return true;
    }
    return false;
  }
  const testNumber = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (!isNaN(character * 1)) return true;
    }
    return false;
  }
  const testSpecial = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (character.match(/[!@#$%^&*]/i)) return true;
    }
    return false;
  }
  const testIllegal = (inputString) => {
    var character = '';
    for (var i = 0; i < inputString.length; i++) {
      character = inputString.charAt(i);
      if (!character.match(/[0-9a-z!@#$%^&*]/i)) return true;
    }
    return false;
  }

  return (
    <div className="container d-flex justify-content-center mt-5">
      <div className="custom-shadow p-3 w-350 rounded">
        <form onSubmit={handleSubmit} data-bs-theme="dark">
          <div className="form-floating mb-3">
            <input type="password" className={"form-control text-white " + (passwordError === 1 ? "border-error" : "")} id="password" placeholder="Password" onChange={e => { setPassword(e.target.value); checkPassword(e); }} />
            <label className={"form-control-label-light " + (passwordError === 1 ? "text-error" : "")} htmlFor="password"><strong>Password</strong></label>
          </div>

          <div className="form-floating mb-4">
            <input type="password" className={"form-control text-white " + (password2Error === 1 ? "border-error" : "")} id="password2" placeholder="Password" onChange={e => setPassword2(e.target.value)} />
            <label className={"form-control-label-light " + (password2Error === 1 ? "text-error" : "")} htmlFor="password2"><strong>Confirm Password</strong></label>
          </div>
          
          <button type="submit" className="btn btn-success w-100 px-4"><strong>Update Password</strong></button>
        </form>
        
        {errorMsg !== '' && errorMsg !== 'password' && errorMsg !== 'illegal' ? <div className="text-danger fs-14 mt-3">{errorMsg}</div> : ''}
        {errorMsg === 'password' ? <div className="text-danger fs-14 mt-3">Passwords must include: 
            <ul>
              <li className={passwordLength === 1 ? 'text-danger' : 'text-success'}>at least 8 characters</li>
              <li className={passwordUpper === 1 ? 'text-danger' : 'text-success'}>an uppercase letter</li>
              <li className={passwordNumber === 1 ? 'text-danger' : 'text-success'}>a number</li>
              <li className={passwordSpecial === 1 ? 'text-danger' : 'text-success'}>at least one of the following:
                <ul>
                  <li>! @ # $ % ^ & *</li>
                </ul>
              </li>
            </ul>
          </div>
        : ''}
        {errorMsg === 'illegal' ? <div className="text-danger fs-14 mt-3">Only the following special characters are allowed: 
            <ul>
              <li>! @ # $ % ^ & *</li>
            </ul>
          </div>
        : ''}

        {passwordReset ? 
          <div className="row mt-3">
            <div className="col-auto pe-0">
              <div className="spinner-grow text-success" role="status">
                <span className="visually-hidden">Redirecting</span>
              </div>
            </div>
            <div className="col">
              <div className="text-success fs-14">Password updated, redirecting to login in ... {secToRedirect}</div>
            </div>
          </div>
        : ''}
      </div>
    </div>
  )
}