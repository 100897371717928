import React, { useEffect, useState } from 'react';
import { useEffectOnce } from '../useEffectOnce';
import { useLocation, useNavigate } from "react-router-dom";
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";
import axios from "axios";
import PropTypes from 'prop-types';
// import AppleButton from './AppleButton';
import AppleButton from './AppleButtonL';
import GoogleButton from './GoogleButtonL';

async function checkUser(info) {
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  return fetch(API_URL + 'pj/checkUser', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'token': 'XkjQzRQEsm2Ptvcj6xS2LqWL' },
    body: JSON.stringify(info)
  })
  .then(data => data.json())
}

async function loginUser(data) {
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  return fetch(API_URL + 'pj/login', {
    method: 'POST',
    // credentials: 'include',
    headers: { 'Content-Type': 'application/json', 'token': 'XkjQzRQEhdtPtvcj6xS2LqWL' },
    body: JSON.stringify(data)
  })
  .then(data => data.json())
}

async function lockAccount(data) {
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  return fetch(API_URL + 'pj/lockAccount', {
    method: 'POST',
    // credentials: 'include',
    headers: { 'Content-Type': 'application/json', 'token': 'XkjQzRQEhdtPtvcj6xS2LqWL' },
    body: JSON.stringify(data)
  })
  .then(data => data.json())
}

async function logAccess(id, data) {
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';
  
  return fetch(API_URL + 'pj/logAccess', {
    method: 'POST',
    // credentials: 'include',
    headers: { 'Content-Type': 'application/json', 'x-access-token': id },
    body: JSON.stringify(data)
  })
  .then(data => data.json())
}

export default function Login ({ setToken }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(0);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(0);
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Clear all filters and data in local storage
  //TODO just for beta testing?
  localStorage.clear();

  useEffectOnce(() => {
    sessionStorage.removeItem("firstLogin");

    // If id parameter exists in URL (redirect from API after SSO login)
    if (params.get('id') !== undefined && params.get('id') !== null && params.get('id') !== '' && params.get('id').length > 15) {
      handleRedirectTo();
    }
  });

  const handleSubmit = async e => {
    // Prevent form submission posting and reloading page
    e.preventDefault();

    // Declare variables and clear errors
    var login = true;
    setEmailError(0);
    setPasswordError(0);
    setErrorMsg('');

    // If email is empty
    if (email.trim().length < 1) { setErrorMsg("All fields must be filled"); setEmailError(1); login = false; }
    // If password is empty
    if (password.length < 1) { setErrorMsg("All fields must be filled"); setPasswordError(1); login = false; }

    // Fields filled, attempt login
    if (login) {
      // Let user know they are being logged in
      setLoggingIn(true);

      // Get user location & IP
      var ip = '', city = '', state = '', postal = '', country = '';
      try {
        var res = await axios.get('https://geolocation-db.com/json/');
        ip = res.data.IPv4;
        city = res.data.city;
        state = res.data.state;
        postal = res.data.postal;
        country = res.data.country_code;
      } catch (e) { console.log('private'); }

      // Check if password is correct for inputted email
      const user = await checkUser({
        email: email.trim().toLowerCase(),
        password: password,
        browserName: browserName,
        browserVersion: browserVersion,
        osName: osName,
        osVersion: osVersion,
        ip: ip,
        city: city,
        state: state,
        zip: postal,
        country: country
      });

      setLoggingIn(false);
      // If account is locked
      if (user.user === 'locked') {
        setErrorMsg('Too many bad login attempts. Account is locked.');
      }
      // If passwords didn't match
      else if (user.user === 'mismatch') {
        setErrorMsg("Incorrect email / password");
        sessionStorage.removeItem('token');
        if (user.user[0].bad_count === 3) lockingAccount(user.user[0].uuid);
      }
      // If passwords matched
      else if (user.user.length > 0) {
        // If account is active
        if (user.user[0].active === 1) {
          loggingInUser(user.user[0].uuid, user.user[0].laid, ip, city, state, postal, country);
        }
        else setErrorMsg("Account not yet activated, please wait");
      }
      // If account not found, show error message
      else {
        setErrorMsg("Incorrect email / password");
        sessionStorage.removeItem('token');
      }
    }
  }

  const loggingInUser = async (uuid, laid, ip, city, state, zip, country) => {
    // // Get user location & IP
    // const res = await axios.get('https://geolocation-db.com/json/');
    
    // Login user and get JWT
    const token = await loginUser({
      uuid: uuid,
      laid: laid,
      browserName: browserName,
      browserVersion: browserVersion,
      osName: osName,
      osVersion: osVersion,
      ip: ip,
      city: city,
      state: state,
      zip: zip,
      country: country
    });

    // Credentials match and JWT created
    if (token.token !== undefined && token.token !== null && token.token !== '') {
      setToken(token);
      if (token.logins === 1) sessionStorage.setItem('firstLogin', '1');
      if (token.accepted !== 1) sessionStorage.setItem('acceptTC', '0');
      if (window.location.href.includes('/login')) navigate("/");
      else window.location.reload();
    }
  }

  const lockingAccount = async (uuid) => {
    // Lock account
    await lockAccount({
      uuid: uuid
    });

    setErrorMsg('Too many bad login attempts. Account is locked.');
  }

  const handleRedirectTo = async e => {
    // Get user location & IP
    var ip = '', city = '', state = '', postal = '', country = '';
    try {
      var res = await axios.get('https://geolocation-db.com/json/');
      ip = res.data.IPv4;
      city = res.data.city;
      state = res.data.state;
      postal = res.data.postal;
      country = res.data.country_code;
    } catch (e) { console.log('private'); }

    // Record this login
    const uuid = await logAccess(params.get('id'), {
      browserName: browserName,
      browserVersion: browserVersion,
      osName: osName,
      osVersion: osVersion,
      ip: ip,
      city: city,
      state: state,
      zip: postal,
      country: country
    });

    // User successfully logged in
    if (uuid.uuid !== undefined && uuid.uuid !== null && uuid.uuid !== '') {
      setToken({ "token": params.get('id') });
      if (uuid.logins === 1) sessionStorage.setItem('firstLogin', '1');
      if (uuid.accepted !== 1) sessionStorage.setItem('acceptTC', '0');
      navigate("/");
    }
    // Otherwise, error logging user in
    else {
      sessionStorage.setItem('lastError', uuid.errorMessage ?? '');
      navigate("/login");
    }
  }

  const routeSignUp = () => { 
    navigate("/signup");
  }

  const routeForgotPassword = () => { 
    navigate("/forgot-password");
  }

  return (
    <div className="container d-flex justify-content-center mt-5">
      {(params.get('id') !== undefined && params.get('id') !== null && params.get('id') !== '') || loggingIn ? 
        <div className="text-center mt-3">
          <div className="text-white fs-2 mb-3">Logging in...</div>
          <div className="spinner-grow spinner-grow-2x text-header" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      :
        <div className="custom-shadow p-3 w-350 rounded">
          <h6 className="text-header mb-3">We are currently in beta, please join our waitlist for beta access if you do not already have an account</h6>

          <form className="mb-3" onSubmit={handleSubmit} data-bs-theme="dark">
            <div className="form-floating">
              <input type="text" className={"form-control text-white no-border-bottom " + (emailError === 1 ? "border-error" : "")} id="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
              <label className={"form-control-label-light " + (emailError === 1 ? "text-error" : "")} htmlFor="email"><strong>Email</strong></label>
            </div>
            <div className="form-floating">
              <input type="password" className={"form-control text-white no-border-top " + (passwordError === 1 ? "border-error" : "")} id="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
              <label className={"form-control-label-light " + (passwordError === 1 ? "text-error" : "")} htmlFor="password"><strong>Password</strong></label>
            </div>

            <button type="submit" className="btn btn-success w-100 mt-4"><strong>Login</strong></button>

            <div className="row mt-3">
              <div className="col">
                <button type="button" className="btn bg-darkest text-white fs-14 px-1" onClick={routeForgotPassword}>Forgot Password?</button>
              </div>
              <div className="col-auto text-end">
                <button type="button" className="btn bg-darkest text-white fs-14 px-1" onClick={routeSignUp}><strong>Sign Up</strong></button>
              </div>
            </div>
          </form>

          <hr className="login-hr" />

          <AppleButton />

          <GoogleButton />

          {errorMsg !== '' ? <div className="text-danger fs-14 mt-3">{errorMsg}</div> : ''}
        </div>
      }
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};