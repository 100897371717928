import { useEffect, useState } from 'react';
import { useStatePersist as useStateP } from 'use-state-persist';
import { useEffectOnce } from '../useEffectOnce';
import useToken from '../Auth/useToken';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import PropBubble from './PropBubble';
import '../PropsList.css';
 
const PropsList = (props) => {
  const navigate = useNavigate();
  const {token} = useToken();

  const [cached, setCached] = useStateP('@NFLCached', 0);
  const [loading, setLoading] = useState(true);

  const [lastDBLoadTime, setLastDBLoadTime] = useStateP('@NFLLastDBLoadTime', '');
  const [lastDBLoadTimeLocal, setLastDBLoadTimeLocal] = useStateP('@NFLLastDBLoadTimeLocal', '');
  const [lastDataLoadTime, setLastDataLoadTime] = useStateP('@NFLLastDataLoadTime', '');
  const [week, setWeek] = useStateP('@NFLweek', 'Week #1');
  const [games, setGames] = useStateP('@NFLgames', []);
  const [data, setData] = useStateP('@NFLdata', []);
  const [filteredData, setFilteredData] = useStateP('@NFLfilteredData', []);
  const [searchText, setSearchText] = useStateP('@NFLsearchText', '');
  // const [last_season, setLastSeason] = useState(new Date().getFullYear() - 1);
  // const [this_season, setThisSeason] = useState(new Date().getFullYear());
  const [statFilter, setStatFilter] = useStateP('@NFLstatF', []);
  const [positionFilter, setPositionFilter] = useStateP('@NFLposF', []);
  const [sortFilter, setSortFilter] = useStateP('@NFLsortF', '');
  const [gameFilter, setGameFilter] = useStateP('@NFLgameF', []);
  const [bookFilter, setBookFilter] = useStateP('@NFLbookF', []);
  const [hasHover, setHasHover] = useState(0);
  const [filterView, setFilterView] = useStateP('@NFLfilterView', 'show');
  const [initFilterView, setInitFilterView] = useState(filterView);
  const [buttonDisabled ,setButtonDisabled] = useState(false);

  var booksName = {'d': 'DraftKings', 'f': 'FanDuel', 'm': 'MGM'};
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';
  
  /*
   * Run once on load
  */
  useEffectOnce(() => {
    props.setActive('nfl');

    // Eliminate scroll animation
    document.documentElement.style.scrollBehavior = 'auto';

    // // Get last season's & this season's starting years
    // let today = new Date();
    // if (today.getMonth() < 5) {
    //   setLastSeason(today.getFullYear() - 2);
    //   setThisSeason(today.getFullYear() - 1);
    // }

    getLastLoadTime();
    // getWeek();             // Moved to getLastLoadTime so it can pass lastDBLoadTime directly, instead of missing new state change
    // getData();             // Moved to getLastLoadTime so it can pass lastDBLoadTime directly, instead of missing new state change
    watchForHover();
  });

  /*
   * Run when of variables in array below are changed
  */
  useEffect(() => {
    // Loop through data to get what lines to display depending on book filters selected
    data.forEach(f => {
      // Define arrays to collect values for each book selected
      f.over_values_arr = [];
      f.over_odds_arr = [];
      f.over_books_arr = [];
      f.under_values_arr = [];
      f.under_odds_arr = [];
      f.under_books_arr = [];
      f.over_index = -1;
      f.under_index = -1;

      // Reset values to stop mistakes
      f.book_over_value = null;
      f.book_over_odds = null;
      f.book_over_book = f.book;
      f.book_under_value = null;
      f.book_under_odds = null;
      f.book_under_book = f.under_book;

      // Loop through each book selected, push values & odds into arrays
      if (bookFilter.length > 0) {
        bookFilter.forEach(g => {
          if (f[g + '_over_value'] != null) {
            f.over_values_arr.push(f[g + '_over_value']);
            f.over_odds_arr.push(f[g + '_over_odds']);
            f.over_books_arr.push(booksName[g]);
          }
          if (f[g + '_under_value'] != null) {
            f.under_values_arr.push(f[g + '_under_value']);
            f.under_odds_arr.push(f[g + '_under_odds']);
            f.under_books_arr.push(booksName[g]);
          }
        });

        // Get the lowest over and the corresponding odds
        if (f.over_values_arr.length > 0) {
          f.book_over_value = Math.min.apply(Math, f.over_values_arr);
          f.over_index = f.over_values_arr.indexOf(f.book_over_value.toString());
          f.book_over_odds = f.over_odds_arr[f.over_index];
          f.book_over_book = f.over_books_arr[f.over_index];
        }
        // Get the highest under and the corresponding odds
        if (f.under_values_arr.length > 0) {
          f.book_under_value = Math.max.apply(Math, f.under_values_arr);
          f.under_index = f.under_values_arr.indexOf(f.book_under_value.toString());
          f.book_under_odds = f.under_odds_arr[f.under_index];
          f.book_under_book = f.under_books_arr[f.under_index];
        }
      }
      else {
        f.book_over_value = null;
        f.book_over_odds = null;
        f.book_over_book = f.book;
        f.book_under_value = null;
        f.book_under_odds = null;
        f.book_under_book = f.under_book;
      }
    });
    
    // var cDateTime = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
    var cDateTime = DateTime.now().setZone("America/New_York");
    // Subtract 10 minutes so that props stay showing until game locks on books
    // cDateTime = subtractMinutes(cDateTime, 10);
    cDateTime = cDateTime.minus({minutes:10}).toISO();

    //* ISSUE: games that are filtered out below also need filtered out of gameFilter
    // // Filter out games that have already started
    // setGames(games.filter(g => 
    //   (DateTime.fromFormat(g.date + " " + g.time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toISO() >= cDateTime)
    // ));

    setFilteredData(data.filter(d => 
      (DateTime.fromFormat(d.date + " " + d.time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toISO() >= cDateTime && (statFilter.length === 0 || statFilter.indexOf(d.stat) >= 0) && (positionFilter.length === 0 || positionFilter.indexOf(d.position) >= 0) && (gameFilter.length === 0 || gameFilter.indexOf(d.home_team) >= 0))
    ).sort(compare));

    // If cache has already been loaded, hide loading spinner here
    if (cached === 1) setLoading(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statFilter, positionFilter, sortFilter, gameFilter, bookFilter, data]);

  /*
   * Get most recent time that props were loaded in DB
  */
  const getLastLoadTime = async () => {
    const response = await axios.get(API_URL + `pj/nfl/loadtime`, { headers: { 'x-access-token': token } });
    
    // If token expired or doesn't exist, redirect to login page
    if (response.data.errorMessage) {
      // If user's account is inactive, redirect them to /account
      if (response.data.errorMessage === 'Inactive User') {
        navigate("/account");
      }
      else {
        sessionStorage.setItem('lastError', response.data.errorMessage);
        sessionStorage.removeItem('token');
        window.location.reload();
        navigate("/login");
      }
    }
    // Otherwise, process data
    else {
      if (response.data.length > 0) {
        // const dateTime = new Date(new Date(response.data[0].time).toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
        // setLastDBLoadTime(formatDateTimeSec(dateTime));
        // setLastDBLoadTimeLocal(formatDateTime(new Date(response.data[0].time)));
        // getWeek(dateTime);
        // getData(dateTime);

        // Create date object from last time the DB was loaded
        const dateTime = DateTime.fromISO(response.data[0].time.replace(' ', 'T')).setZone("America/New_York");
        // Set last DB load time in format (2023-01-01T00:00:00.000-05:00)
        setLastDBLoadTime(dateTime.toISO());
        //! BETA
        console.log('DB Last Loaded: ' + dateTime.toISO());
        // Set last DB load time in user's local time in format (2023-01-01 00:00)
        setLastDBLoadTimeLocal(dateTime.toLocal().toFormat('yyyy-MM-dd HH:mm'));
        //! BETA
        console.log('DB Last Loaded (Local): ' + dateTime.toLocal().toFormat('yyyy-MM-dd HH:mm'));
        // Get games and props
        getWeek();
        getData(dateTime.toISO());
      }

      sessionStorage.removeItem('lastError');
    }
  }

  /*
   * Get week number & games that have not started yet
  */
  const getWeek = async (lastLoad) => {
    // // Only query DB if the database has been loaded more recent than the current data was or week or games is not yet set
    // if (lastDataLoadTime === '' || new Date(lastDataLoadTime) < new Date(lastDBLoadTime) || new Date(lastDataLoadTime) < lastLoad || week === 0 || games.length === 0) {
      // Get current date & time in EST
      // let today = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
      let today = DateTime.now().setZone("America/New_York");
      // Get date from DateTime object
      // let date = formatDate(today);
      let date = today.toFormat('yyyy-MM-dd');
      // Subtract 10 minutes so that props stay showing until game locks on books
      // today = subtractMinutes(today, 10);
      today = today.minus({minutes:10});
      // let time = today.toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}).substring(12);
      // let time = today.toString().substring(16, 24);
      let time = today.toFormat('HH:mm:ss');
      
      // Get data
      // const response = await axios.get(API_URL + `pj/nfl/week/${date}/${time}`, { withCredentials: true });
      const response = await axios.get(API_URL + `pj/nfl/week/${date}/${time}`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response);

      // If token expired or doesn't exist, redirect to login page
      if (response.data.errorMessage) {
        // If user's account is inactive, redirect them to /account
        if (response.data.errorMessage === 'Inactive User') {
          navigate("/account");
        }
        else {
          sessionStorage.setItem('lastError', response.data.errorMessage);
          sessionStorage.removeItem('token');
          navigate("/login");
        }
      }
      // Otherwise, process data
      else {
        if (Object.keys(response.data).length > 0) {
          // if (response.data.week.length > 0) setWeek(response.data.week[0].weekNum);
          if (response.data.week.length > 0) setWeek(response.data.week[0].text);
          else setWeek(0);
  
          // If games have already started, reset games filter so it doesn't cause issues
          if (games.length !== response.data.games.length) setGameFilter([]);
  
          // Remove time from date string
          response.data.games.map(g => g.date = g.date.substring(0, 10));

          setGames(response.data.games);
        }

        sessionStorage.removeItem('lastError');
      }
    // }
  }

  /*
   * Get props for games that have not started yet
  */
  const getData = async (lastLoad) => {
    // Only query DB if the database has been loaded more recent than the current data was or filteredData is not yet set
    if (lastDataLoadTime === '' || lastDataLoadTime < lastDBLoadTime || lastDataLoadTime < lastLoad || filteredData.length === 0) {
      // Get current date & time in EST
      // let today = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
      let today = DateTime.now().setZone("America/New_York");
      // Get date from DateTime object
      // let date = formatDate(today);
      let date = today.toFormat('yyyy-MM-dd');
      // Subtract 10 minutes so that props stay showing until game locks on books
      // today = subtractMinutes(today, 10);
      today = today.minus({minutes:10});
      // let time = today.toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}).substring(12);
      // let time = today.toString().substring(16, 24);
      let time = today.toFormat('HH:mm:ss');

      // Get data
      // const response = await axios.get(API_URL + `pj/nfl/props/${date}/${time}`, { withCredentials: true });
      const response = await axios.get(API_URL + `pj/nfl/props/${date}/${time}`, { headers: { 'x-access-token': token } });
      if (process.env.NODE_ENV === 'development') console.log(response);
      
      // If token expired or doesn't exist, redirect to login page
      if (response.data.errorMessage) {
        // If user's account is inactive, redirect them to /account
        if (response.data.errorMessage === 'Inactive User') {
          navigate("/account");
        }
        else {
          sessionStorage.setItem('lastError', response.data.errorMessage);
          sessionStorage.removeItem('token');
          navigate("/login");
        }
      }
      // Otherwise, process data
      else {
        if (Object.keys(response.data).length > 0) {
          // Remove time from date string
          response.data.map(d => d.date = d.date.substring(0, 10));
          
          setData(response.data);
          setFilteredData(response.data);
          setLastDataLoadTime(DateTime.now().setZone("America/New_York").toISO());

          // Clear game filter when reloading props
          setGameFilter([]);
        }

        // Remove loading spinner and store value that the cache has been loaded
        setLoading(false);
        setCached(1);

        sessionStorage.removeItem('lastError');
      }
    }
    // Otherwise, filter out any games that have already started
    else {
      // var cDateTime = new Date(new Date().toLocaleString('en-US', {hour12: false, timeZone: 'America/New_York'}));
      var cDateTime = DateTime.now().setZone("America/New_York");
      // Subtract 10 minutes so that props stay showing until game locks on books
      // cDateTime = subtractMinutes(cDateTime, 10);
      cDateTime = cDateTime.minus({minutes:10}).toISO();
      setData(data.filter(d => (DateTime.fromFormat(d.date + " " + d.time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toISO() >= cDateTime)));
    }
  }

  /*
   * Functions to set filter state variables
  */
  const saveText = (e) => {
    setSearchText(e.target.value.toLowerCase());
  }

  const saveStatFilter = (e) => {
    if (statFilter.indexOf(e.target.innerText) < 0) setStatFilter([...statFilter, e.target.innerText]);
    else setStatFilter(statFilter.filter(stat => stat !== e.target.innerText));
  }

  const savePositionFilter = (e) => {
    if (positionFilter.indexOf(e.target.innerText) < 0) setPositionFilter([...positionFilter, e.target.innerText]);
    else setPositionFilter(positionFilter.filter(position => position !== e.target.innerText));
  }

  const saveSortFilter = (e) => {
    if (sortFilter !== e) setSortFilter(e);
    else setSortFilter('');
  }

  const saveGameFilter = (e) => {
    if (gameFilter.indexOf(e) < 0) setGameFilter([...gameFilter, e]);
    else setGameFilter(gameFilter.filter(game => game !== e));
  }

  // const saveBookFilter = (e) => {
  //   if (bookFilter.indexOf(e) < 0) setBookFilter([...bookFilter, e]);
  //   else setBookFilter(bookFilter.filter(book => book !== e));
  // }

  const saveFilterView = (e) => {
    if (filterView === 'show') setFilterView('');
    else setFilterView('show');

    setButtonDisabled(true);

    setTimeout(() => setButtonDisabled(false), 350);
  }

  // Helper function to sort by hit percentage bubbles
  const compare = (a, b) => {
    if ( a[sortFilter] < b[sortFilter] ) return 1;
    if ( a[sortFilter] > b[sortFilter] ) return -1;
    return 0;
  }

  // Helper function to remove :hover css on touch devices, and add on mouse devices
  function watchForHover () {
    let lastTouchTime = 0;
  
    function enableHover() {
      if (new Date() - lastTouchTime < 500) return;
      setHasHover(1);
    }
  
    function disableHover() {
      setHasHover(0);
    }
  
    function updateLastTouchTime() {
      lastTouchTime = new Date();
    }
  
    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
  
    enableHover();
  }

  // Helper function to format date & time into day time string
  const formatDayTime = (date, time) => {
    // const d = new Date(date + " " + time);
    // return getDayName(d).substring(0, 3) + ' ' + (d.getHours() > 12 ? d.getHours() - 12 : d.getHours()) + ':' + (d.getMinutes() > 0 ? (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) : '00') + (d.getHours() > 12 ? ' PM' : ' AM');
    var newFormat = {weekday: 'short', ...DateTime.TIME_SIMPLE};
    return DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toLocal().toLocaleString(newFormat);
  }

  return (
    <div className="px-12">
      <div className="row">
        <div className="col-auto header-col">
          <h3 className="text-light orbitron mt-2 mb-0">{week} Props</h3>
        </div>
        <div className="col" data-bs-theme="dark">
          <input type="text" className="col form-control search float-end shadow-none px-2 mt-07" id="search" placeholder="Search" onChange={e => saveText(e)} autoComplete="off" defaultValue={searchText}/>
        </div>
      </div>

      {/* <div className="row m-0">
        <div className="col text-end text-secondary fs-12 mt-004 p-0">{data.length} Props Last Loaded at {lastDBLoadTimeLocal}</div>
      </div> */}

      <div className="w-100 text-end p-0 m-0">
        <button className="btn filters-btn bg-dark2 text-orange p-0 m-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilters" onClick={e => saveFilterView(e)} disabled={buttonDisabled}>Filters <i className={"bi bi-caret-" + (filterView === 'show' ? 'up' : 'down')}></i></button>
      </div>
      <div className={"collapse " + initFilterView} id="collapseFilters">
        <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('ATTS') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>ATTS</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Pass INTs') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Pass INTs</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Pass TDs') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Pass TDs</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Pass Yds') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Pass Yds</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Rec') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Rec</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Rec Yds') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Rec Yds</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Rush Atts') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Rush Atts</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (statFilter.indexOf('Rush Yds') >= 0 ? " bubble-selected" : "")} onClick={e => saveStatFilter(e)}>Rush Yds</div>
            <div className="col"></div>
          </div>
        </div>

        <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('FB') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>FB</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('QB') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>QB</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('RB') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>RB</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('TE') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>TE</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (positionFilter.indexOf('WR') >= 0 ? " bubble-selected" : "")} onClick={e => savePositionFilter(e)}>WR</div>
            {/* <div className="col-auto b-left"><div className={"px-25 bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'last_season' ? " bubble-selected" : "")} onClick={e => saveSortFilter('last_season')}>2022</div></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'all_season' ? " bubble-selected" : "")} onClick={e => saveSortFilter('all_season')}>Season</div> */}
            <div className="col-auto b-left"><div className={"px-25 bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'all_season' ? " bubble-selected" : "")} onClick={e => saveSortFilter('all_season')}>Season</div></div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_seven' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_seven')}>L7</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_five' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_five')}>L5</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_three' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_three')}>L3</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'l_week' ? " bubble-selected" : "")} onClick={e => saveSortFilter('l_week')}>L1</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'vs' ? " bubble-selected" : "")} onClick={e => saveSortFilter('vs')}>vs Opp</div>
            <div className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (sortFilter === 'opp_rank' ? " bubble-selected" : "")} onClick={e => saveSortFilter('opp_rank')}>Def</div>
            <div className="col"></div>
          </div>
        </div>

        {games.length > 1 ?
        <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            {games.map((g, index) => (
              <div key={index} className={"col-auto bubble px-2" + (hasHover === 1 ? " hasHover" : "") + (gameFilter.indexOf(g.home_team) >= 0 ? " bubble-selected" : "")} onClick={e => saveGameFilter(g.home_team)}>
                {g.away_team + ' @ ' + g.home_team}
                <div className="game-date-time">{formatDayTime(g.date, g.time)}</div>
              </div>
            ))}
            <div className="col"></div>
          </div>
        </div>
        : ''}

        {/* <div className="filter-group px-2 text-center">
          <div className="row mb-2">
            <div className="col"></div>
            <div className={"col-auto bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('d') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('d')}>DraftKings</div>
            <div className={"col-auto bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('f') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('f')}>Fanduel</div>
            <div className={"col-auto bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('m') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('m')}>MGM</div>
            <div className={"col-auto bg-black bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('d') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('d')} data-bs-toggle="tooltip" title="DraftKings">
              <img className="align-text-bottom" src="./DraftKings.png" alt="DraftKings" width="16"/>
            </div>
            <div className={"col-auto bg-blue bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('f') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('f')} data-bs-toggle="tooltip" title="FanDuel">
              <img className="align-text-bottom" src="./FanDuel.png" alt="FanDuel" width="16"/>
            </div>
            <div className={"col-auto bg-black bubble" + (hasHover === 1 ? " hasHover" : "") + (bookFilter.indexOf('m') >= 0 ? " bubble-selected" : "")} onClick={e => saveBookFilter('m')} data-bs-toggle="tooltip" title="BetMGM">
              <img className="align-text-bottom" src="./MGM.png" alt="BetMGM" width="16"/>
            </div>
            <div className="col"></div>
          </div>
        </div> */}
      </div>

      {loading ? 
        <div className="loader" id="loader"></div>
        :
        filteredData.map((p, index) => (
          (p.player_name.toLowerCase().includes(searchText) && (bookFilter.length === 0 || (bookFilter.length > 0 && (p.book_over_value != null || p.book_under_value != null))) ?
            <Link to={`/nfl/player/${p.player_user}/${p.stat}`} key={index}>
              <PropBubble player={p} />
            </Link>
          : '')
        ))
      }
    </div>
  );
}
 
export default PropsList;