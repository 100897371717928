import { useEffect, useState } from 'react'
import { useEffectOnce } from '../useEffectOnce';
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import datalabelPlugin from 'chartjs-plugin-datalabels';
// import { Bar, Line } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import './Track.css';
 
const Track = () => {
  const [bets, setBets] = useState([]);
  const [filteredBets, setFilteredBets] = useState([]);
  const [profitForChart, setProfitForChart] = useState([]);
  const [profitByDay, setProfitByDay] = useState([]);
  const [betDate, setBetDate] = useState('');
  const [betOdds, setBetOdds] = useState('+100');
  const [betRisk, setBetRisk] = useState('10');
  const [betLegCounter, setBetLegCounter] = useState(1);
  const [betTags, setBetTags] = useState([]);
  const [betSport, setBetSport] = useState('');
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalRecord, setTotalRecord] = useState('');
  const [weekProfit, setWeekProfit] = useState(0);
  const [weekRecord, setWeekRecord] = useState('');
  const [monthProfit, setMonthProfit] = useState(0);
  const [monthRecord, setMonthRecord] = useState('');
  const [yearProfit, setYearProfit] = useState(0);
  const [yearRecord, setYearRecord] = useState('');
  const [fanduelProfit, setFanduelProfit] = useState(0);
  const [fanduelRecord, setFanduelRecord] = useState('');
  const [fdBoostsProfit, setFDBoostsProfit] = useState(0);
  const [fdBoostsRecord, setFDBoostsRecord] = useState('');
  const [draftkingsProfit, setDraftkingsProfit] = useState(0);
  const [draftkingsRecord, setDraftkingsRecord] = useState('');
  const [dkBoostsProfit, setDKBoostsProfit] = useState(0);
  const [dkBoostsReecord, setDKBosstsRecord] = useState('');
  const [mgmProfit, setMGMProfit] = useState(0);
  const [mgmrecord, setMGMRecord] = useState('');
  const [cappersProfit, setCappersProfit] = useState([]);
  const [cappersWins, setCappersWins] = useState([]);
  const [cappersLosses, setCappersLosses] = useState([]);
  const [sportsProfit, setSportsProfit] = useState([]);
  const [sportsWins, setSportsWins] = useState([]);
  const [sportsLosses, setSportsLosses] = useState([]); 
  const [timeFilter, setTimeFilter] = useState('');
  const [bookFilter, setBookFilter] = useState([]);
  const [capperFilter, setCapperFilter] = useState([]);
  const [sportFilter, setSportFilter] = useState([]);
  const [showIcons, setShowIcons] = useState('');
  const [chartData, setChartData] = useState({});
  
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    annotationPlugin,
    datalabelPlugin
  );

  const tag_options = ['DK', 'FD', 'MGM', 'Boost', 'Conch', 'JeffRosa', 'Mikey', 'PRO', 'PromoGuy', 'PropBetGuy', 'PropJockey', 'TDs'];
  const cappers = ['Conch', 'JeffRosa', 'Mikey', 'PRO', 'PromoGuy', 'PropBetGuy', 'PropJockey'];
  const sport_options = ['MLB', 'NBA', 'NCAAB', 'NCAAF', 'NFL', 'NHL', 'Soccer'];
  
  /*
   * Run once on load
  */
  useEffectOnce(() => {
    getData();
    setBetDate(formatDate(new Date()));
  });

  /*
   * Run when of variables in array below are changed
  */
  useEffect(() => {
    // Define profit/loss tracking local variables
    var overall = 0, week = 0, month = 0, year = 0;
    var fd = 0, fdBoost = 0;
    var dk = 0, dkBoost = 0;
    var mgm = 0;
    var capProfit = [];
    cappers.map((c, index) => capProfit[index] = 0);
    var sportProfit = [];
    sport_options.map((s, index) => sportProfit[index] = 0);

    // Define record tracking local variables
    var overall_wins = 0, overall_losses = 0;
    var week_wins = 0, week_losses = 0;
    var month_wins = 0, month_losses = 0;
    var year_wins = 0, year_losses = 0;
    var fanduel_wins = 0, fanduel_losses = 0;
    var fdBoost_wins = 0, fdBoost_losses = 0;
    var draftkings_wins = 0, draftkings_losses = 0;
    var dkBoost_wins = 0, dkBoost_losses = 0;
    var mgm_wins = 0, mgm_losses = 0;
    var capWins = [], capLosses = [];
    cappers.map((c, index) => capWins[index] = 0);
    cappers.map((c, index) => capLosses[index] = 0);
    var sportWins = [], sportLosses = [];
    sport_options.map((s, index) => sportWins[index] = 0);
    sport_options.map((s, index) => sportLosses[index] = 0);

    // Define date variables so they aren't created repeatedly in loop below
    var curDate = new Date(), weekAgo = new Date(), monthAgo = new Date(), yearAgo = new Date();
    weekAgo.setDate(curDate.getDate() - 8);
    monthAgo.setDate(curDate.getDate() - 31);
    yearAgo.setDate(curDate.getDate() - 366);

    // Loop through bets
    bets.map((b, index) => {
      // Create date object for bet's date to prevent it being done multiple times below
      var bDate = new Date(b.date);

      // Check filters to show/hide bet
      b.show = 1;

      if (timeFilter === 'week' && bDate < weekAgo) b.show = 0;
      else if (timeFilter === 'month' && bDate < monthAgo) b.show = 0;
      else if (timeFilter === 'year' && bDate < yearAgo) b.show = 0;

      if (bookFilter.includes('FDBoost') && (!b.tags.includes('FD') || !b.tags.includes('Boost'))) b.show = 0;
      else if (bookFilter.includes('FanDuel') && !b.tags.includes('FD')) b.show = 0;
      else if (bookFilter.includes('DKBoost') && (!b.tags.includes('DK') || !b.tags.includes('Boost'))) b.show = 0;
      else if (bookFilter.includes('DraftKings') && !b.tags.includes('DK')) b.show = 0;
      else if (bookFilter.includes('MGM') && !b.tags.includes('MGM')) b.show = 0;

      if (capperFilter.length > 0) b.show = capperFilter.some(r => b.tags.indexOf(r) >= 0) ? 1 : 0;

      if (sportFilter.length > 0 && !sportFilter.includes(b.sport)) b.show = 0;

      // If bet is settled, include in profit/loss calculations
      if (b.won_flag !== null) {
        // Calculate overall profit/loss
        if (b.won_flag === 1) { overall += Number(b.won); overall_wins++; }
        else { overall -= Number(b.risk); overall_losses++; }
        
        // Calculate last weeks profit/loss
        if (bDate > weekAgo) {
          if (b.won_flag === 1) { week += Number(b.won); week_wins++; }
          else { week -= Number(b.risk); week_losses++; }
        }
        
        // Calculate last months profit/loss
        if (bDate > monthAgo) {
          if (b.won_flag === 1) { month += Number(b.won); month_wins++; }
          else { month -= Number(b.risk); month_losses++; }
        }
        
        // Calculate last years profit/loss
        if (bDate > yearAgo) {
          if (b.won_flag === 1) { year += Number(b.won); year_wins++; }
          else { year -= Number(b.risk); year_losses++; }
        }

        // Calculate FanDuel profit/loss
        if (b.tags.indexOf('FD') > 0) {
          if (b.won_flag === 1) { fd += Number(b.won); fanduel_wins++; }
          else { fd -= Number(b.risk); fanduel_losses++; }

          // Calculate FD Boost profit/loss
          if (b.tags.indexOf('Boost') > 0) {
            if (b.won_flag === 1) { fdBoost += Number(b.won); fdBoost_wins++; }
            else { fdBoost -= Number(b.risk); fdBoost_losses++; }
          }
        }

        // Calculate DraftKings profit/loss
        if (b.tags.indexOf('DK') > 0) {
          if (b.won_flag === 1) { dk += Number(b.won); draftkings_wins++; }
          else { dk -= Number(b.risk); draftkings_losses++; }

          // Calculate DK Boost profit/loss
          if (b.tags.indexOf('Boost') > 0) {
            if (b.won_flag === 1) { dkBoost += Number(b.won); dkBoost_wins++; }
            else { dkBoost -= Number(b.risk); dkBoost_losses++; }
          }
        }

        // Calculate BetMGM profit/loss
        if (b.tags.indexOf('MGM') > 0) {
          if (b.won_flag === 1) { mgm += Number(b.won); mgm_wins++; }
          else { mgm -= Number(b.risk); mgm_losses++; }
        }

        // Calculate profit/loss for each capper
        JSON.parse(b.tags).map((t, index) => {
          if (b.won_flag === 1) { capProfit[cappers.indexOf(t)] += Number(b.won); capWins[cappers.indexOf(t)]++; }
          else { capProfit[cappers.indexOf(t)] -= Number(b.risk); capLosses[cappers.indexOf(t)]++; }
          return 1;
        });

        // Calculate profit/loss for each sport
        if (b.won_flag === 1) { sportProfit[sport_options.indexOf(b.sport)] += Number(b.won); sportWins[sport_options.indexOf(b.sport)]++; }
        else { sportProfit[sport_options.indexOf(b.sport)] -= Number(b.risk); sportLosses[sport_options.indexOf(b.sport)]++; }
      }
      return 1;
    });

    // Set profit/loss tracking state variables
    setTotalProfit(overall);
    setWeekProfit(week);
    setMonthProfit(month);
    setYearProfit(year);
    setFanduelProfit(fd);
    setFDBoostsProfit(fdBoost);
    setDraftkingsProfit(dk);
    setDKBoostsProfit(dkBoost);
    setMGMProfit(mgm);
    setCappersProfit(capProfit);
    setSportsProfit(sportProfit);

    // Set record tracking state variables
    setTotalRecord(overall_wins + '-' + overall_losses);
    setWeekRecord(week_wins + '-' + week_losses);
    setMonthRecord(month_wins + '-' + month_losses);
    setYearRecord(year_wins + '-' + year_losses);
    setFanduelRecord(fanduel_wins + '-' + fanduel_losses);
    setFDBoostsRecord(fdBoost_wins + '-' + fdBoost_losses);
    setDraftkingsRecord(draftkings_wins + '-' + draftkings_losses);
    setDKBosstsRecord(dkBoost_wins + '-' + dkBoost_losses);
    setMGMRecord(mgm_wins + '-' + mgm_losses);
    setCappersWins(capWins);
    setCappersLosses(capLosses);
    setSportsWins(sportWins);
    setSportsLosses(sportLosses);

    // Set filtered bets
    setFilteredBets(bets.filter(b => b.show === 1));
    
    // 
    var profits = profitForChart.slice(0).reverse().map(p => p.profit);
    setChartData({
      labels: profitForChart.slice(0).reverse().map(b => b.date),
      datasets: [{
        fill: true,
        data: profitForChart.slice(0).reverse().map((b, index) => profits.slice(0, index + 1).reduce((a, b) => Number(a) + Number(b))),
        backgroundColor: '#198754',
        // backgroundColor: profitForChart.slice(0).reverse().map(b => b.profit > 0 ? '#198754' : (b.profit < 0 ? '#dc3545' : 'grey')),
        // backgroundColor: profitForChart.slice(0).reverse().map((b, index) => profits.slice(0, index + 1).reduce((a, b) => Number(a) + Number(b)) > 0 ? '#198754' : (profits.slice(0, index + 1).reduce((a, b) => Number(a) + Number(b)) < 0 ? '#dc3545' : 'grey')),
        // pointBackgroundColor: profitForChart.slice(0).reverse().map(b => b.profit > 0 ? '#198754' : (b.profit < 0 ? '#dc3545' : 'grey')),
        tension: 0.3
      }]
    });
  }, [bets, profitForChart, timeFilter, bookFilter, capperFilter, sportFilter]); // eslint-disable-line

  /*
   * Get bets for current user
  */
  const getData = async () => {
    // const response = await axios.get(API_URL + `pj/track/${userID}`, { headers: { token: 'S68PUd6HKJDQUUGHBtrpN59M' } });
    const response = await axios.get(API_URL + `pj/track/1`, { headers: { token: 'S68PUd6HKJDQUUGHBtrpN59M' } });
    if (process.env.NODE_ENV === 'development') console.log(response);

    // If bets are set
    if (response.data.bets && response.data.bets.length > 0) {
      setBets(response.data.bets);
      setFilteredBets(response.data.bets);
    }

    // If profit by day is set
    if (response.data.profitByDay && response.data.profitByDay.length > 0) {
      setProfitForChart(response.data.profitByDay);
      var pbd = [];
      response.data.profitByDay.map(p => pbd[p.date] = p.profit);
      setProfitByDay(pbd);
    }
  }

  /*
   * Save bet
  */
  const saveBet = async () => {
    try {
      // Build JSON for entered bet legs
      var betLegs = [];
      var legs = document.getElementsByClassName("bet-leg");
      var l;
      for (l = 0; l < legs.length; l++) {
        if (legs[l].value !== '') betLegs.push(legs[l].value);
        legs[l].value = '';
      }
      setBetLegCounter(1);

      // POST bet
      let res = await fetch(API_URL + "pj/track/save_bet", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: 1,
          date:    betDate,
          tags:    JSON.stringify(betTags),
          bet:     JSON.stringify(betLegs).replace(/[\/\(\)\']/g, "\\$&"), // eslint-disable-line
          sport:   betSport,
          odds:    betOdds,
          risk:    betRisk
        }),
      });
      if (res.status === 200) {
        setBetDate(formatDate(new Date()));
        setBetOdds('+100');
        setBetRisk('10');
        setBetLegCounter(1);
        setBetTags([]);
        setBetSport('');
        var tags = document.getElementsByClassName("btn-check");
        var t;
        for (t = 0; t < tags.length; t++) tags[t].checked = false;

        // Refresh data and close modal
        getData();
        document.getElementById("closeBetModal").click();
      } else {
        console.log("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  }

  /*
   * Mark bet as won
  */
  const markBetWon = async (id, odds, risk) => {
    try {
      // Calculate money won
      var amountWon;
      if (odds > 0) amountWon = (odds / 100) * risk;
      else amountWon = (100 / Math.abs(odds)) * risk;

      // POST bet update
      let res = await fetch(API_URL + "pj/track/update_bet", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          bet_id:   id,
          won:      amountWon,
          won_flag: 1
        }),
      });
      if (res.status === 200) {
        // Refresh data
        getData();
      } else {
        console.log("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  }

  /*
   * Mark bet as lost
  */
  const markBetLost = async (id) => {
    try {
      let res = await fetch(API_URL + "pj/track/update_bet", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          bet_id:   id,
          won:      null,
          won_flag: 0
        }),
      });
      if (res.status === 200) {
        // Refresh data
        getData();
      } else {
        console.log("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  }

  /*
   * Delete bet
  */
  const deleteBet = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete this bet?')) {
        let res = await fetch(API_URL + "pj/track/delete_bet", {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            bet_id:   id
          }),
        });
        if (res.status === 200) {
          // Refresh data
          getData();
        } else {
          console.log("Some error occured");
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  /*
   * Add leg to new bet modal
  */
  const addLeg = () => {
    setBetLegCounter(betLegCounter + 1);
  }

  /*
   * Functions to set filter state variables
  */
  const saveTimeFilter = (item) => {
    if (timeFilter === item) setTimeFilter('');
    else setTimeFilter(item);
  }

  const saveBookFilter = (item) => {
    if (bookFilter.indexOf(item) < 0) setBookFilter([...bookFilter, item]);
    else setBookFilter(bookFilter.filter(b => b !== item));
  }

  const saveCapperFilter = (item) => {
    if (capperFilter.indexOf(item) < 0) setCapperFilter([...capperFilter, item]);
    else setCapperFilter(capperFilter.filter(b => b !== item));
  }

  const saveSportFilter = (item) => {
    if (sportFilter.indexOf(item) < 0) setSportFilter([...sportFilter, item]);
    else setSportFilter(sportFilter.filter(b => b !== item));
  }

  const saveTags = (e) => {
    if (betTags.indexOf(e.target.value) < 0) setBetTags([...betTags, e.target.value]);
    else setBetTags(betTags.filter(tag => tag !== e.target.value));
  }

  const toggleIcons = (index) => {
    if (showIcons !== index) setShowIcons(index);
    else setShowIcons('');
  }

  // Helper function to format date into date string
  const formatDate = (dateStr) => {
    var date = new Date(dateStr);
    var month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return date.getFullYear() + "-" + month + "-" + day;
  }

  // Format numbers to USD
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <div className="px-12 text-white">
      <div className="row">
        <div className="col-xl col-lg-12">
          <div className="row m-2 overflow-x-scroll">
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (timeFilter === 'week' ? ' selected' : '')} onClick={e => saveTimeFilter('week')}>
              <span><strong>Last 7</strong></span>
              <h4 className={"mb-0 " + (weekProfit < 0 ? 'text-danger' : (weekProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(weekProfit)}</strong></h4>
              <h6 className="text-secondary">{weekRecord}</h6>
            </div>
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (timeFilter === 'month' ? ' selected' : '')} onClick={e => saveTimeFilter('month')}>
              <span><strong>Last 30</strong></span>
              <h4 className={"mb-0 " + (monthProfit < 0 ? 'text-danger' : (monthProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(monthProfit)}</strong></h4>
              <h6 className="text-secondary">{monthRecord}</h6>
            </div>
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (timeFilter === 'year' ? ' selected' : '')} onClick={e => saveTimeFilter('year')}>
              <span><strong>Last 365</strong></span>
              <h4 className={"mb-0 " + (yearProfit < 0 ? 'text-danger' : (yearProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(yearProfit)}</strong></h4>
              <h6 className="text-secondary">{yearRecord}</h6>
            </div>
            <div className="col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2">
              <span><strong>Overall</strong></span>
              <h4 className={"mb-0 " + (totalProfit < 0 ? 'text-danger' : (totalProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(totalProfit)}</strong></h4>
              <h6 className="text-secondary">{totalRecord}</h6>
            </div>
          </div>
          <div className="row m-2 overflow-x-scroll">
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (bookFilter.indexOf('FanDuel') > -1 ? ' selected' : '')} onClick={e => saveBookFilter('FanDuel')}>
              <span><strong>FanDuel</strong></span>
              <h4 className={"mb-0 " + (fanduelProfit < 0 ? 'text-danger' : (fanduelProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(fanduelProfit)}</strong></h4>
              <h6 className="text-secondary">{fanduelRecord}</h6>
            </div>
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (bookFilter.indexOf('FDBoost') > -1 ? ' selected' : '')} onClick={e => saveBookFilter('FDBoost')}>
              <span><strong>FD Boosts</strong></span>
              <h4 className={"mb-0 " + (fdBoostsProfit < 0 ? 'text-danger' : (fdBoostsProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(fdBoostsProfit)}</strong></h4>
              <h6 className="text-secondary">{fdBoostsRecord}</h6>
            </div>
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (bookFilter.indexOf('DraftKings') > -1 ? ' selected' : '')} onClick={e => saveBookFilter('DraftKings')}>
              <span><strong>DraftKings</strong></span>
              <h4 className={"mb-0 " + (draftkingsProfit < 0 ? 'text-danger' : (draftkingsProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(draftkingsProfit)}</strong></h4>
              <h6 className="text-secondary">{draftkingsRecord}</h6>
            </div>
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (bookFilter.indexOf('DKBoost') > -1 ? ' selected' : '')} onClick={e => saveBookFilter('DKBoost')}>
              <span><strong>DK Boosts</strong></span>
              <h4 className={"mb-0 " + (dkBoostsProfit < 0 ? 'text-danger' : (dkBoostsProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(dkBoostsProfit)}</strong></h4>
              <h6 className="text-secondary">{dkBoostsReecord}</h6>
            </div>
            <div className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (bookFilter.indexOf('MGM') > -1 ? ' selected' : '')} onClick={e => saveBookFilter('MGM')}>
              <span><strong>BetMGM</strong></span>
              <h4 className={"mb-0 " + (mgmProfit < 0 ? 'text-danger' : (mgmProfit > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(mgmProfit)}</strong></h4>
              <h6 className="text-secondary">{mgmrecord}</h6>
            </div>
          </div>
          <div className="row m-2 overflow-x-scroll">
            {cappers.map((c, index) => 
              <div key={index} className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (capperFilter.indexOf(c) > -1 ? ' selected' : '')} onClick={e => saveCapperFilter(c)}>
                <span><strong>{c}</strong></span>
                <h4 className={"mb-0 " + (cappersProfit[index] < 0 ? 'text-danger' : (cappersProfit[index] > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(cappersProfit[index])}</strong></h4>
                <h6 className="text-secondary">{cappersWins[index]}-{cappersLosses[index]}</h6>
              </div>
            )}
          </div>
          <div className="row m-2 overflow-x-scroll">
            {sport_options.map((s, index) => 
              <div key={index} className={"col pl-card border border-dark3 shadow rounded p-1 px-2 pb-0 me-2" + (sportFilter.indexOf(s) > -1 ? ' selected' : '')} onClick={e => saveSportFilter(s)}>
                <span><strong>{s}</strong></span>
                <h4 className={"mb-0 " + (sportsProfit[index] < 0 ? 'text-danger' : (sportsProfit[index] > 0 ? 'text-success' : 'text-secondary'))}><strong>{formatter.format(sportsProfit[index])}</strong></h4>
                <h6 className="text-secondary">{sportsWins[index]}-{sportsLosses[index]}</h6>
              </div>
            )}
          </div>

          <div className="row border border-dark3 shadow rounded p-1 px-2 m-2 mt-4 me-3">
            {(Object.keys(chartData).length > 0 ?
              <Line
                data={chartData}
                options={{
                  elements: {
                    point: {
                      radius: 0
                    }
                  },
                  plugins: {
                    title: {
                      display: false
                    },
                    legend: {
                      display: false
                    },
                    datalabels: {
                      labels: {
                        value: {
                          font: {
                            size: "16"
                          },
                          color: "white"
                        }
                      },
                      anchor: 'end',
                      align: 'start',
                      padding: '0',
                      formatter: function(value, index, values) {
                        // if (((vsRadio === 'season' && hideLabels === 0) || vsRadio === 'vs') && value > 0) return value;
                        // else 
                        return '';
                      }
                    },
                    tooltip: {
                      intersect: false,
                      // callbacks: {
                      //   footer: function(items) {
                      //     return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0)
                      //   }
                      // }
                    },
                    // annotation: {
                    //   annotations: {
                    //     line1: {
                    //       type: 'line',
                    //       yMin: line,
                    //       yMax: line,
                    //       borderColor: '#fd7e14',
                    //       borderWidth: line > 0 ? 2 : 0
                    //     },
                    //     label1: {
                    //       type: 'label',
                    //       xValue: (vsRadio === 'season' && games.length > 1 && hideLabels === 0 ? 0.5 : (vsRadio === 'season' && games.length > 1 && hideLabels === 1 ? 1.5 : (vsRadio === 'vs' && oppGames.length > 1 ? 0.5 : 0))),
                    //       yValue: line,
                    //       content: [(line > 0 ? 'O/U: ' + line : '')],
                    //       font: { size: 14 },
                    //       color: 'white'
                    //     }
                    //   }
                    // },
                  },
                  scales:{
                    x: {
                      display: false
                    }
                  }
                }}
              />
            : '')}
          </div>
        </div>
        
        <div className="col scroll-col">
          <div className="row mb-2 overflow-x-scroll">
            <div>
              {filteredBets.map((b, index) => (
                <div key={index}>
                  {((index === 0 || (index > 0 && b.date !== filteredBets[index-1].date)) ? 
                    <div className="bb-purple mt-3 mb-2">
                      {b.date}
                      <span className={"float-end text-" + (profitByDay[b.date] > 0 ? 'success' : (profitByDay[b.date] < 0 ? 'danger' : 'secondary'))}>{profitByDay[b.date] > 0 ? '+' + formatter.format(profitByDay[b.date]) : formatter.format(profitByDay[b.date])}</span>
                    </div>
                  : '')}
                  
                  <div className="row px-1 mb-2" onClick={e => toggleIcons(index)}>
                    <div className="col">
                      <div className="row">
                        <div className="col title-col">
                          <span>{JSON.parse(b.bet).length > 1 ? JSON.parse(b.bet).length + '-Way Parlay' : JSON.parse(b.bet)[0]}</span> <span className="text-secondary fs-14 ms-2">{b.odds}</span>
                        </div>
                        <div className="col-auto text-end">{b.won_flag === null ? formatter.format(b.risk) : (b.won_flag === 0 ? <span className="text-danger">-{formatter.format(b.risk)}</span> : <span className="text-success">+{formatter.format(b.won)}</span>)}</div>
                      </div>

                      <div className="row">
                        <div className="col text-secondary fs-12 ms-3">
                          {b.sport} <>&mdash;</> 
                          {JSON.parse(b.tags).length > 0 ? JSON.parse(b.tags).map((k, index) => <span key={index} className="ms-1"> #{k} </span>) : ''}
                        </div>
                      </div>

                      {JSON.parse(b.bet).length > 1 ?
                        <div className="border border-dark3 rounded p-1 px-3 mx-3 mb-1">
                          {JSON.parse(b.bet).map((k, index) => <div key={index} className="row"><div key={index} className="col">{k}</div></div>)}
                        </div>
                      : ''}

                      {b.won_flag === null ?
                        <div className={"text-center " + (showIcons === index ? '' : 'd-none')}>
                          <i className="fas fa-check align-middle text-success fa-lg me-4" onClick={e => markBetWon(b.bet_id, b.odds, b.risk)}></i>
                          <i className="fas fa-times fs-4 align-middle text-danger fa-lg me-4" onClick={e => markBetLost(b.bet_id)}></i>
                          <i className="fas fa-minus-circle align-middle text-danger fa-lg" onClick={e => deleteBet(b.bet_id)}></i>
                        </div>
                      : ''}
                      {b.won_flag !== null ?
                        <div className={"text-center " + (showIcons === index ? '' : 'd-none')}>
                          <i className="fas fa-minus-circle align-middle text-danger fa-lg" onClick={e => deleteBet(b.bet_id)}></i>
                        </div>
                      : ''}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <button type="button" className="btn btn-success btn-rounded btn-stuck shadow py-2" id="modalTrigger" data-bs-toggle="modal" data-bs-target="#betModal"><i className="fas fa-plus fa-2x"></i></button>


      <div className="modal fade" id="betModal" tabIndex="-1">
        <div className="modal-dialog modal-lg rounded mt-5">
          <div className="modal-content bg-dark text-light">
            <div className="modal-header py-2">
              <h5 className="modal-title"><strong>Track New Bet</strong></h5>
              <button type="button" className="btn-close" id="closeBetModal" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body text-white">
              <input type="date" className="form-control w-auto" id="bet_date" value={betDate} onChange={e => setBetDate(e.target.value)} />

              <div className="row mt-3">
                <div className="col">
                  <label>Odds</label>
                  <input type="text" className="form-control" id="bet_odds" value={betOdds} onChange={e => setBetOdds(e.target.value)} />
                </div>
                <div className="col">
                  <label>Risk</label>
                  <input type="number" className="form-control" id="bet_risk" value={betRisk} onChange={e => setBetRisk(e.target.value)} />
                </div>
                <div className="col">
                  <label>Win</label>
                  <input type="text" className="form-control" value={(betOdds > 0 && betRisk > 0 ? ((betOdds / 100) * betRisk) : (betOdds < 0 && betRisk > 0 ? ((100 / Math.abs(betOdds)) * betRisk) : ''))} onChange={e => ''} disabled />
                </div>
              </div>

              <div className="text-center mt-3" id="bet_div">
                {[...Array(betLegCounter)].map((x, i) =>
                  <div key={i+1} className="mb-2">
                    <span>Leg {i+1}: </span>
                    <input type="text" className="form-control bet-leg w-75 d-inline-block" />
                  </div>
                )}
                
              </div>
              <div className="row text-center mt-2">
                <div className="col">
                  <button className="btn btn-outline-primary py-0 px-5" onClick={e => addLeg()}>+ Add Leg</button>
                </div>
              </div>

              <div className="text-center mt-3">
                <div className="btn-group flex-wrap" role="group">
                  {tag_options.map((o, index) => 
                    <div key={index} className="me-2 mt-2">
                      <input type="checkbox" className="btn-check" id={"tag_" + o} autoComplete="off" value={o} onChange={e => saveTags(e)} />
                      <label className="btn btn-outline-secondary py-1 px-2" htmlFor={"tag_" + o}>{o}</label>
                    </div>
                  )}
                </div>
              </div>

              <div className="text-center mt-3">
                <div className="btn-group flex-wrap" role="group">
                  {sport_options.map((o, index) => 
                    <div key={index} className="me-2 mt-2">
                      <input type="radio" className="btn-check" id={"sport_" + o} name="sport" autoComplete="off" value={o} onChange={e => setBetSport(o)} />
                      <label className="btn btn-outline-secondary py-1 px-2" htmlFor={"sport_" + o}>{o}</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer py-2">
              <button type="button" className="btn btn-secondary py-0" data-bs-dismiss="modal">Close</button>
              <button type="button" id="save_bet" className="btn btn-success py-0" onClick={e => saveBet()}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Track;