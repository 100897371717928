import { DateTime } from 'luxon';
import HitCountBubble from '../PropsList/HitCountBubble';

const PropBubble = ({player}) => {
  // Helper function to format and color rank
  const formatRank = (rank) => {
    if (rank !== undefined) {
      rank = rank.toString();
      let rankStr = '';
      if (rank.substring(rank.length - 1) === '1') rankStr = rank + 'st';
      else if (rank.substring(rank.length - 1) === '2') rankStr = rank + 'nd';
      else if (rank.substring(rank.length - 1) === '3') rankStr = rank + 'rd';
      else rankStr = rank + 'th';

      if (rank <= 10) rank = <span className="text-danger2">{rankStr}</span>;
      else if (rank >= 21) rank = <span className="text-success2">{rankStr}</span>;
      else rank = <span>{rankStr}</span>;
    }

    return rank;
  }

  // Helper function to format date & time into day time string
  const formatDayTime = (date, time) => {
    var newFormat = {weekday: 'short', ...DateTime.TIME_SIMPLE};
    return DateTime.fromFormat(date + " " + time, "yyyy-MM-dd HH:mm:ss", { zone: 'America/New_York' }).toLocal().toLocaleString(newFormat);
  }

  return (
    <div className="card bg-darkest text-light shadow mb-2">
      <div className="card-body py-1 px-2">
        <div className="row line-height mb-01">
          <div className="col text-start player-name"><strong>{ player.player_name }</strong></div>
          <div className="col-auto text-light-orange text-end player-stat px-0">{ player.stat }</div>
          <div className="col-auto short orbitron text-light-orange text-end player-over px-0" data-bs-toggle="tooltip" title="Over Line"><strong>{ player.book_over_value !== undefined && player.book_over_value != null ? player.book_over_value : player.over_value }</strong></div>
          <div className="col-auto short orbitron text-light-orange text-end player-under px-0 me-3" data-bs-toggle="tooltip" title="Under Line"><strong>{ player.stat !== 'ATTS' ? (player.book_under_value !== undefined && player.book_under_value != null ? player.book_under_value : player.under_value) : '' }</strong></div>
        </div>

        <div className="row game-opp-odds-row ps-1">
          <div className="col text-start">{ player.position } - { player.team } <span className="text-purple time-span">{formatDayTime(player.date, player.time)}</span></div>
          <div className="col-auto text-end px-0">{ player.team === player.home_team ? 'vs ' + player.away_team : '@' + player.home_team } ({ (player.opp_rank !== null ? formatRank(player.opp_rank) : '') })</div>
          <div className="col-auto short text-end player-odds px-0" data-bs-toggle="tooltip" title="Over Odds">{ player.book_over_odds !== undefined && player.book_over_odds != null ? (player.book_over_odds >= 100 ? '+' : '') + player.book_over_odds : (player.over_odds >= 100 ? '+' : '') + player.over_odds }</div>
          <div className="col-auto short text-end player-odds px-0 me-3" data-bs-toggle="tooltip" title="Under Odds">{ player.stat !== 'ATTS' ? (player.book_under_odds !== undefined && player.book_under_odds != null ? (player.book_under_odds >= 100 ? '+' : '') + player.book_under_odds : (player.under_odds >= 100 ? '+' : '') + player.under_odds) : '' }</div>
        </div>

        <div className="row fs-7 ms-1">
          <div className="col hit-group">
            <div className="row">
              {/* { player.last_season >= 0 ? <HitCountBubble text="2022" perc={player.last_season} /> : '' } */}
              { player.all_season >= 0 ? <HitCountBubble text="Season" perc={player.all_season} /> : '' }
              { player.l_ten >= 0 ? <HitCountBubble text="L10" perc={player.l_ten} /> : '' }
              { player.l_five >= 0 ? <HitCountBubble text="L5" perc={player.l_five} /> : '' }
              { player.l_three >= 0 ? <HitCountBubble text="L3" perc={player.l_three} /> : '' }
              { player.l_week >= 0 ? <HitCountBubble text="L1" perc={player.l_week} /> : '' }
              { player.vs >= 0 ? <HitCountBubble text={"vs " + (player.team !== player.home_team ? player.home_team : player.away_team) } perc={player.vs} /> : '' }
            </div>
          </div>

          <div className="col-auto">
            <div className="row">
              <div className="col-auto short text-end px-0">
                <div className="d-inline-block align-middle text-success2 over-o" data-bs-toggle="tooltip" title="Over"><strong>O</strong></div> { /*player.book_over_book !== undefined ? <img src={"./" + player.book_over_book + ".png"} alt={player.book_over_book} width="16" data-bs-toggle="tooltip" title={player.book_over_book}/> : '' */}
              </div>
              <div className="col-auto short text-end px-0">
                {player.stat !== 'ATTS' ? <div className="d-inline-block align-middle text-danger2 under-u" data-bs-toggle="tooltip" title="Under"><strong>U</strong></div> : ''}
                {/* <div><div className="d-inline-block align-middle text-danger2 under-u" data-bs-toggle="tooltip" title="Under"><strong>U</strong></div> { player.book_under_book !== undefined ? <img src={"./" + player.book_under_book + ".png"} alt={player.book_under_book} width="16" data-bs-toggle="tooltip" title={player.book_under_book}/> : '' } </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropBubble;