import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line
import { Collapse } from 'bootstrap';
import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Account from './components/Account/Account';
import Signup from './components/Signup/Signup';
import Enroll from './components/Signup/Enroll';
import Login from './components/Login/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import HomePage from "./components/HomePage";
import MLBPropsList from "./components/mlb/PropsList";
import MLBPlayerData from "./components/mlb/PlayerData";
import NBAPropsList from "./components/nba/PropsList";
import NBAPlayerData from "./components/nba/PlayerData";
import NHLPropsList from "./components/nhl/PropsList";
import NHLPlayerData from "./components/nhl/PlayerData";
import NFLPropsList from "./components/nfl/PropsList";
import NFLPlayerData from "./components/nfl/PlayerData";
import Track from "./components/track/Track";
import './General.css';
import useToken from './components/Auth/useToken';
 
function App() {
  const [active, setActive] = useState('home');
  const {token, setToken} = useToken();

  // if (!token || token.length !== 66) {
  if (!token || token.length < 15) {
    return (
      <Router>
        <header>
          <div id="slider" className="sliding-background-still"></div>

          <nav className="navbar navbar-expand-lg bg-header navbar-dark py-1">
            <div className="container">
              <Link to="/" className="navbar-brand ms-3" onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}>
                {/* <img className="logo-br me-3" src="/logo192.png" alt="Prop Jockey" width="32" height="32"/> */}
                <img className="logo-br me-3" src="/pj_logo_bg.png" alt="Prop Jockey" width="32" height="32"/>
                <strong>PROP JOCKEY</strong>
              </Link>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav text-center ms-auto">
                  <li className="nav-item me-4">
                    {/* <Link to="/signup" className="nav-link text-light" onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><strong>Sign Up</strong></Link> */}
                    <Link to="/enroll" className="nav-link text-light" onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><strong>Join Waitlist</strong></Link>
                  </li>
                  <li className="nav-item me-4">
                    <Link to="/login" className="nav-link text-light" onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><strong>Login</strong></Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <section className="container-fluid px-0">
          <Routes>
            <Route exact path="/" element={<HomePage active={active} setActive={setActive} />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/enroll" element={<Enroll />} />
            <Route exact path="/login" element={<Login setToken={setToken} />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset" element={<ResetPassword />} />

            {/* <Route exact path="*" element={<Login setToken={setToken} />} /> */}
            <Route exact path="*" element={<HomePage active={active} setActive={setActive} />} />
          </Routes>
        </section>
      </Router>
    );
  }

  return (
    <Router>
      <header>
        <div id="slider" className="sliding-background-still"></div>

        <nav className="navbar navbar-expand-lg bg-header navbar-dark py-1">
          <div className="container">
            <Link to="/" className="navbar-brand ms-3" onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}>
              {/* <img className="logo-br me-3" src="/logo192.png" alt="Prop Jockey" width="32" height="32"/> */}
              <img className="logo-br me-3" src="/pj_logo_bg.png" alt="Prop Jockey" width="32" height="32"/>
              <strong>PROP JOCKEY</strong>
            </Link>

            <button id="hamburger_toggle" className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav text-center ms-auto">
                <li className="nav-item me-4">
                  <Link to="/mlb" className={ "nav-link text-light" + (active === 'mlb' ? " active" : "") } onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><strong>MLB</strong></Link>
                </li>
                <li className="nav-item me-4">
                  <Link to="/nba" className={ "nav-link text-light" + (active === 'nba' ? " active" : "") } onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><strong>NBA</strong></Link>
                </li>
                <li className="nav-item me-4">
                  <Link to="/nfl" className={ "nav-link text-light" + (active === 'nfl' ? " active" : "") } onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><strong>NFL</strong></Link>
                </li>
                <li className="nav-item me-4">
                  <Link to="/nhl" className={ "nav-link text-light" + (active === 'nhl' ? " active" : "") } onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><strong>NHL</strong></Link>
                </li>
                <li className="nav-item">
                  <Link to="/account" className="nav-link text-light pt-1" onClick={() => {document.getElementById("navbarSupportedContent").classList.contains("show") ? document.getElementById("hamburger_toggle").click() : void(0)}}><i className="bi bi-person-circle text-white fs-5"></i></Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section className="container-fluid px-0">
        <Routes>
          <Route exact path="/" element={<HomePage active={active} setActive={setActive} />} />
          <Route exact path="/account" element={<Account active={active} setActive={setActive} />} />
          <Route exact path="/mlb" element={<MLBPropsList active={active} setActive={setActive} />} />
          <Route exact path="/mlb/player/:id" element={<MLBPlayerData active={active} setActive={setActive} />} />
          <Route exact path="/mlb/player/:id/:stat" element={<MLBPlayerData active={active} setActive={setActive} />} />
          <Route exact path="/nba" element={<NBAPropsList active={active} setActive={setActive} />} />
          <Route exact path="/nba/player/:id" element={<NBAPlayerData active={active} setActive={setActive} />} />
          <Route exact path="/nba/player/:id/:stat" element={<NBAPlayerData active={active} setActive={setActive} />} />
          <Route exact path="/nhl" element={<NHLPropsList active={active} setActive={setActive} />} />
          <Route exact path="/nhl/player/:id" element={<NHLPlayerData active={active} setActive={setActive} />} />
          <Route exact path="/nhl/player/:id/:stat" element={<NHLPlayerData active={active} setActive={setActive} />} />
          <Route exact path="/nfl" element={<NFLPropsList active={active} setActive={setActive} />} />
          <Route exact path="/nfl/player/:id" element={<NFLPlayerData active={active} setActive={setActive} />} />
          <Route exact path="/nfl/player/:id/:stat" element={<NFLPlayerData active={active} setActive={setActive} />} />

          <Route exact path="/track" element={<Track />} />

          <Route exact path="*" element={<HomePage active={active} setActive={setActive} />} />
        </Routes>
      </section>
    </Router>
  );
}
 
export default App;