import { useEffect, useState } from 'react'
import { useEffectOnce } from '../useEffectOnce';
import useToken from '../Auth/useToken';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import datalabelPlugin from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import '../PlayerData.css';
 
const PlayerData = (props) => {
  const navigate = useNavigate();
  const {token} = useToken();

  const { id, stat } = useParams();
  const [player, setPlayer] = useState([]);
  const [passYards, setPassYards] = useState([]);
  const [passTDs, setPassTDs] = useState([]);
  const [passINTs, setPassINTs] = useState([]);
  const [rec, setRec] = useState([]);
  const [recYards, setRecYards] = useState([]);
  const [rushAtts, setRushAtts] = useState([]);
  const [rushYards, setRushYards] = useState([]);
  const [games, setGames] = useState([]);
  const [oppGames, setOppGames] = useState([]);
  const [oppRanks, setOppRanks] = useState([]);
  const [statOptions, setStatOptions] = useState([]);
  const [statRadio, setStatRadio] = useState('');
  const [seasonAvg, setSeasonAvg] = useState('');
  const [sevenAvg, setSevenAvg] = useState('');
  const [fiveAvg, setFiveAvg] = useState('');
  const [threeAvg, setThreeAvg] = useState('');
  const [oneAvg, setOneAvg] = useState('');
  const [vsAvg, setVsAvg] = useState(-1);
  const [oppRank, setOppRank] = useState('');
  const [chartData, setChartData] = useState({});
  const [line, setLine] = useState('');
  const [seasonHitCount, setSeasonHitCount] = useState('');
  const [sevenHitCount, setSevenHitCount] = useState(0);
  const [fiveHitCount, setFiveHitCount] = useState(0);
  const [threeHitCount, setThreeHitCount] = useState(0);
  const [oneHitCount, setOneHitCount] = useState(0);
  const [vsHitCount, setVSHitCount] = useState(0);
  const [homeGameCount, setHomeGameCount] = useState(0);
  const [homeHitCount, setHomeHitCount] = useState(0);
  const [awayGameCount, setAwayGameCount] = useState(0);
  const [awayHitCount, setAwayHitCount] = useState(0);
  const [winGameCount, setWinGameCount] = useState(0);
  const [winHitCount, setWinHitCount] = useState(0);
  const [lossGameCount, setLossGameCount] = useState(0);
  const [lossHitCount, setLossHitCount] = useState(0);
  const [defGameCount, setDefGameCount] = useState(0);
  const [defHitCount, setDefHitCount] = useState(0);
  const [playoffsGameCount, setPlayoffsGameCount] = useState(0);
  const [playoffsHitCount, setPlayoffsHitCount] = useState(0);
  const [selectedGame, setSelectedGame] = useState(0);
  const [selectedHitCount, setSelectedHitCount] = useState('season');
  const [collapse, setCollapse] = useState('d-none');
  const [hideLabels, setHideLabels] = useState(0);
  
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    datalabelPlugin
  );

  /*
   * Run once on load
  */
  useEffectOnce(() => {
    props.setActive('nfl');

    // Scroll to top when page is first loaded
    window.scrollTo(0, 0);

    // Hide labels on chart on smaller screens
    if (window.innerWidth < 600) setHideLabels(1);

    getData();
  });
  
  /*
   * Run when of variables in array below are changed
  */
  useEffect(() => {
    if (games !== undefined && games.length > 0) {
      // Calculate season average for stat
      if (statRadio === 'passing_yards') { setSeasonAvg(Math.round(Number(player.PaYPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Pass Yds Rank'] : ''); }
      else if (statRadio === 'passing_tds') {
        let season = 0;
        games.map(g => season += g[statRadio]);
        setSeasonAvg(Math.round(season / games.length * 10) / 10);
        setOppRank(oppRanks ? oppRanks['Pass TDs Rank'] : '');
      }
      else if (statRadio === 'interceptions') {
        let season = 0;
        games.map(g => season += g[statRadio]);
        setSeasonAvg(Math.round(season / games.length * 10) / 10);
        setOppRank(oppRanks ? oppRanks['Pass INTs Rank'] : '');
      }
      else if (statRadio === 'receiving_yards') { setSeasonAvg(Math.round(Number(player.ReYPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Rec Yds Rank'] : ''); }
      else if (statRadio === 'receptions') { setSeasonAvg(Math.round(Number(player.ReRPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Rec Rank'] : ''); }
      else if (statRadio === 'rushing_yards') { setSeasonAvg(Math.round(Number(player.RuYPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Rush Yds Rank'] : ''); }
      else if (statRadio === 'rush_attempts') { setSeasonAvg(Math.round(Number(player.RuAPG) * 10) / 10); setOppRank(oppRanks ? oppRanks['Rush Atts Rank'] : ''); }
      else if (statRadio === 'atts') {
        let season = 0;
        games.map(g => season += (g['rushing_tds'] + g['receiving_tds']));
        setSeasonAvg(Math.round(season / games.length * 10) / 10);
        if (player.position === 'QB' || player.position === 'TE' || player.position === 'WR') setOppRank(oppRanks ? oppRanks['Pass TDs Rank'] : '');
        else setOppRank(oppRanks ? oppRanks['Rush TDs Rank'] : '');
      }

      //* Calculate other averages for stat
      // Define variables
      let allHit = 0;
      let seven = 0, sevenHit = 0;
      let five = 0, fiveHit = 0;
      let three = 0, threeHit = 0;
      let homeGames = 0, homeHit = 0;
      let awayGames = 0, awayHit = 0;
      let winGames = 0, winHit = 0;
      let lossGames = 0, lossHit = 0;
      let opp = 0, oppHit = 0;

      // If games is set and 'Snap Percentage' is not selected in stat drop down
      if (games.length > 0 && statRadio !== 'offensive_snap_pct') {
        // Loop through player's games
        games.map((g, index) => {
          // Season
          if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) allHit++;
          else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) allHit++;
          else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) allHit++;
          else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) allHit++;
          else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) allHit++;
          else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) allHit++;
          else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) allHit++;
          else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) allHit++;
          // Last 7
          if (games.length >= 7 && index < 7) {
            if (statRadio !== 'atts') seven += g[statRadio];
            else seven += (g['rushing_tds'] + g['receiving_tds']);
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) sevenHit++;
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) sevenHit++;
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) sevenHit++;
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) sevenHit++;
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) sevenHit++;
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) sevenHit++;
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) sevenHit++;
            else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) sevenHit++;
          }
          // Last 5
          if (games.length >= 5 && index < 5) {
            if (statRadio !== 'atts') five += g[statRadio];
            else five += (g['rushing_tds'] + g['receiving_tds']);
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) fiveHit++;
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) fiveHit++;
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) fiveHit++;
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) fiveHit++;
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) fiveHit++;
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) fiveHit++;
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) fiveHit++;
            else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) fiveHit++;
          }
          // Last 3
          if (games.length >= 3 && index < 3) {
            if (statRadio !== 'atts') three += g[statRadio];
            else three += (g['rushing_tds'] + g['receiving_tds']);
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) threeHit++;
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) threeHit++;
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) threeHit++;
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) threeHit++;
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) threeHit++;
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) threeHit++;
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) threeHit++;
            else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) threeHit++;
          }
          // Last 1
          if (games.length >= 1 && index < 1) {
            setOneHitCount(0);
            if (statRadio !== 'atts') setOneAvg(g[statRadio]);
            else setOneAvg(g['rushing_tds'] + g['receiving_tds'])
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) setOneHitCount(1);
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) setOneHitCount(1);
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) setOneHitCount(1);
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) setOneHitCount(1);
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) setOneHitCount(1);
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) setOneHitCount(1);
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) setOneHitCount(1);
            else if (statRadio === 'atts' && (g['rushing_tds'] + g['receiving_tds']) > 0.5) setOneHitCount(1);
          }
          // Home
          if (g['location'] === '') {
            homeGames++;
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) homeHit++;
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) homeHit++;
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) homeHit++;
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) homeHit++;
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) homeHit++;
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) homeHit++;
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) homeHit++;
            else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) homeHit++;
          }
          // Away
          if (g['location'] === '@') {
            awayGames++;
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) awayHit++;
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) awayHit++;
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) awayHit++;
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) awayHit++;
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) awayHit++;
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) awayHit++;
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) awayHit++;
            else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) awayHit++;
          }
          // Win
          if (g['win_loss'] === 'W') {
            winGames++;
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) winHit++;
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) winHit++;
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) winHit++;
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) winHit++;
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) winHit++;
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) winHit++;
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) winHit++;
            else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) winHit++;
          }
          // Loss
          else {
            lossGames++;
            if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) lossHit++;
            else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) lossHit++;
            else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) lossHit++;
            else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) lossHit++;
            else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) lossHit++;
            else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) lossHit++;
            else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) lossHit++;
            else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) lossHit++;
          }
          
          return '';
        });
      }
      // If player's games vs opponent is set and 'Snap Percentage' is not selected in stat drop down
      if (oppGames.length > 0 && statRadio !== 'offensive_snap_pct') {
        oppGames.map((g, index) => {
          if (statRadio !== 'atts') opp += g[statRadio];
          else opp += (g['rushing_tds'] + g['receiving_tds']);
          if (statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) oppHit++;
          else if (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) oppHit++;
          else if (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) oppHit++;
          else if (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) oppHit++;
          else if (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) oppHit++;
          else if (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) oppHit++;
          else if (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) oppHit++;
          else if (statRadio === 'atts' && g['rushing_tds'] + g['receiving_tds'] > 0.5) oppHit++;

          return '';
        });
      }
      //TODO: todo after getting defenses games
      // // If opponent's games is set and 'Minutes Played' is not selected in stat drop down
      // if (oppsGames.length > 0 && statRadio !== 'minutes') {
      //   oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).map(g => {
      //     defGames++;
      //     if (statRadio === 'ast' && assists && Object.keys(assists).length > 0 && g[player.position.toLowerCase() + '_' + statRadio] > assists.over_value) defHit++;
      //     else if (statRadio === 'pts' && points && Object.keys(points).length > 0 && g[player.position.toLowerCase() + '_' + statRadio] > points.over_value) defHit++;
      //     else if (statRadio === 'rb' && rebounds && Object.keys(rebounds).length > 0 && g[player.position.toLowerCase() + '_' + statRadio] > rebounds.over_value) defHit++;
      //     else if (statRadio === 'threep' && threes && Object.keys(threes).length > 0 && g[player.position.toLowerCase() + '_' + statRadio] > threes.over_value) defHit++;
          
      //     return '';
      //   });
      // }
      // Set average and hit count state variables
      setSeasonHitCount(allHit);
      setSevenAvg(Math.round(seven / 7 * 10) / 10);
      setSevenHitCount(sevenHit);
      setFiveAvg(Math.round(five / 5 * 10) / 10);
      setFiveHitCount(fiveHit);
      setThreeAvg(Math.round(three / 3 * 10) / 10);
      setThreeHitCount(threeHit);
      setHomeGameCount(homeGames);
      setHomeHitCount(homeHit);
      setAwayGameCount(awayGames);
      setAwayHitCount(awayHit);
      setVsAvg(Math.round(opp / oppGames.length * 10) / 10);
      setVSHitCount(oppHit);
      setWinGameCount(winGames);
      setWinHitCount(winHit);
      setLossGameCount(lossGames);
      setLossHitCount(lossHit);

      // Empty all averages if 'Snap Percentage' is selected
      if (statRadio === 'offensive_snap_pct') {
        setSeasonAvg('');
        setSevenAvg('');
        setFiveAvg('');
        setThreeAvg('');
        setOneAvg('');
        setVsAvg(-1);
      }

      // Create object for chart of all player's games this season
      setChartData({
        labels: games.slice(0).reverse().map(g => [g.location + (g.real_team ?? g.opponent), 'Wk ' + g.week]),
        datasets: [{
          data: games.slice(0).reverse().map(g => {
            if (statRadio === 'offensive_snap_pct') return g[statRadio].replace('%', '');
            else if (statRadio === 'atts') return g['rushing_tds'] + g['receiving_tds'];
            else return g[statRadio];
          }),
          backgroundColor: games.slice(0).reverse().map((g, index) => {
            // If filtering with hit-rate bubbles, ignore games that don't match criteria
            if (selectedHitCount === 'l7' && index < games.length - 7) return '#494949';
            if (selectedHitCount === 'l5' && index < games.length - 5) return '#494949';
            if (selectedHitCount === 'l3' && index < games.length - 3) return '#494949';
            if (selectedHitCount === 'l1' && index < games.length - 1) return '#494949';
            if (selectedHitCount === 'vs' && g.real_team !== player.opp && g.opponent !== player.opp) return '#494949';
            if (selectedHitCount === 'home' && g.location !== '') return '#494949';
            if (selectedHitCount === 'away' && g.location === '') return '#494949';
            if (selectedHitCount === 'win' && g.win_loss === 'L') return '#494949';
            if (selectedHitCount === 'loss' && g.win_loss === 'W') return '#494949';

            // If amount from game is greater than current line, set as green
            if ((statRadio === 'passing_yards' && passYards && Object.keys(passYards).length > 0 && g[statRadio] > passYards.over_value) ||
                (statRadio === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0 && g[statRadio] > passTDs.over_value) ||
                (statRadio === 'interceptions' && passINTs && Object.keys(passINTs).length > 0 && g[statRadio] > passINTs.over_value) ||
                (statRadio === 'receiving_yards' && recYards && Object.keys(recYards).length > 0 && g[statRadio] > recYards.over_value) ||
                (statRadio === 'receptions' && rec && Object.keys(rec).length > 0 && g[statRadio] > rec.over_value) ||
                (statRadio === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0 && g[statRadio] > rushYards.over_value) ||
                (statRadio === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0 && g[statRadio] > rushAtts.over_value) ||
                (statRadio === 'atts' && (g['rushing_tds'] > 0.5 || g['receiving_tds'] > 0.5))
              ) return '#198754';
            // If there is no line, shouldn't be red, so set as grey
            else if ((statRadio === 'passing_yards' && (passYards === undefined || Object.keys(passYards).length === 0)) ||
                      (statRadio === 'passing_tds' && (passTDs === undefined || Object.keys(passTDs).length === 0)) ||
                      (statRadio === 'interceptions' && (passINTs === undefined || Object.keys(passINTs).length === 0)) ||
                      (statRadio === 'receiving_yards' && (recYards === undefined || Object.keys(recYards).length === 0)) ||
                      (statRadio === 'receptions' && (rec === undefined || Object.keys(rec).length === 0)) ||
                      (statRadio === 'rushing_yards' && (rushYards === undefined || Object.keys(rushYards).length === 0)) ||
                      (statRadio === 'rush_attempts' && (rushAtts === undefined || Object.keys(rushAtts).length === 0)) ||
                      (statRadio === 'atts' && g['rushing_tds'] < 0.5 && g['receiving_tds'] < 0.5) ||
                      (statRadio === 'offensive_snap_pct')
                    ) return '#494949';
            // Otherwise, amount from game is less than line, set as red
            else return '#dc3545';
          })
        }]
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games, statRadio, selectedHitCount])

  /*
   * Get data for player
  */
  const getData = async () => {
    const response = await axios.get(API_URL + `pj/nfl/player/${id}`, { headers: { 'x-access-token': token } });
    if (process.env.NODE_ENV === 'development') console.log(response);

    // If token expired or doesn't exist, redirect to login page
    if (response.data.errorMessage) {
      // If user's account is inactive, redirect them to /account
      if (response.data.errorMessage === 'Inactive User') {
        navigate("/account");
      }
      else {
        sessionStorage.setItem('lastError', response.data.errorMessage);
        sessionStorage.removeItem('token');
        window.location.reload();
        navigate("/login");
      }
    }
    // If player is set
    else if (response.data.player && response.data.player.length > 0) {
      setPlayer(response.data.player[0]);

      // Set dropdown options for QB
      if (response.data.player[0]['position'] === 'QB') {
        setStatOptions([
          { value: 'atts', label: 'Anytime TD Scorer' },
          { value: 'interceptions', label: 'Interceptions' },
          { value: 'passing_yards', label: 'Passing Yards' },
          { value: 'passing_tds', label: 'Passing TDs' },
          { value: 'rush_attempts', label: 'Rushing Attempts' },
          { value: 'rushing_yards', label: 'Rushing Yards' },
          { value: 'x', label: <><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</></>, isDisabled: true },
          { value: 'offensive_snap_pct', label: 'Snap Percentage' }
        ]);
      }
      // Set dropdown options for other positions
      else {
        setStatOptions([
          { value: 'atts', label: 'Anytime TD Scorer' },
          { value: 'receiving_yards', label: 'Receiving Yards' },
          { value: 'receptions', label: 'Receptions' },
          { value: 'rush_attempts', label: 'Rushing Attempts' },
          { value: 'rushing_yards', label: 'Rushing Yards' },
          { value: 'x', label: <><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</><>&mdash;&mdash;&mdash;</></>, isDisabled: true },
          { value: 'offensive_snap_pct', label: 'Snap Percentage' }
        ]);
      }

      // If Passing Yards is set
      if (response.data.pass_yds) setPassYards(response.data.pass_yds[0]);
      // If Passing TDs is set
      if (response.data.pass_tds) setPassTDs(response.data.pass_tds[0]);
      // If Passing INTs is set
      if (response.data.pass_ints) setPassINTs(response.data.pass_ints[0]);

      // If Receptions is set
      if (response.data.rec) setRec(response.data.rec[0]);
      // If Receiving Yards is set
      if (response.data.rec_yds) setRecYards(response.data.rec_yds[0]);

      // If Rushing Attempts is set
      if (response.data.rush_atts) setRushAtts(response.data.rush_atts[0]);
      // If Rushing Yards is set
      if (response.data.rush_yds) setRushYards(response.data.rush_yds[0]);

      // Set stat filter starting value depending on player's position
      if (stat === 'Pass Yds') { setStatRadio('passing_yards'); setLine((response.data.pass_yds.length > 0) ? response.data.pass_yds[0].over_value : ''); }
      else if (stat === 'Pass TDs') { setStatRadio('passing_tds'); setLine((response.data.pass_tds.length > 0) ? response.data.pass_tds[0].over_value : ''); }
      else if (stat === 'Pass INTs') { setStatRadio('interceptions'); setLine((response.data.pass_ints.length > 0) ? response.data.pass_ints[0].over_value : ''); }
      else if (stat === 'Rush Yds') { setStatRadio('rushing_yards'); setLine((response.data.rush_yds.length > 0) ? response.data.rush_yds[0].over_value : ''); }
      else if (stat === 'Rec Yds') { setStatRadio('receiving_yards'); setLine((response.data.rec_yds.length > 0) ? response.data.rec_yds[0].over_value : ''); }
      else if (stat === 'Rec') { setStatRadio('receptions'); setLine((response.data.rec.length > 0) ? response.data.rec[0].over_value : ''); }
      else if (stat === 'Rush Atts') { setStatRadio('rush_attempts'); setLine((response.data.rush_atts.length > 0) ? response.data.rush_atts[0].over_value : ''); }
      else if (stat === 'ATTS') { setStatRadio('atts'); setLine(0.5); }
      else if (response.data.player[0].position === 'QB') { setStatRadio('passing_yards'); setLine((response.data.pass_yds.length > 0) ? response.data.pass_yds[0].over_value : ''); }
      else if (response.data.player[0].position === 'WR' || response.data.player[0].position === 'TE') { setStatRadio('receiving_yards'); setLine((response.data.rec_yds.length > 0) ? response.data.rec_yds[0].over_value : ''); }
      else if (response.data.player[0].position === 'RB' || response.data.player[0].position === 'FB') { setStatRadio('rushing_yards'); setLine((response.data.rush_yds.length > 0) ? response.data.rush_yds[0].over_value : ''); }

      // If games is set
      if (response.data.games) setGames(response.data.games);

      // If games vs current opponent is set
      if (response.data.opp_games) setOppGames(response.data.opp_games);

      // If opponent's rankings is set
      if (response.data.oppRanks) setOppRanks(response.data.oppRanks[0]);

      sessionStorage.removeItem('lastError');
    }
    // Else, navigate back to NFL home
    else navigate("/nfl");
  }

  /*
   * Function to set filter state variables
  */
  const saveStatRadio = (e) => {
    e = e.value || e;
    if (e === 'passing_yards' && passYards && Object.keys(passYards).length > 0) setLine(passYards.over_value)
    else if (e === 'passing_tds' && passTDs && Object.keys(passTDs).length > 0) setLine(passTDs.over_value)
    else if (e === 'interceptions' && passINTs && Object.keys(passINTs).length > 0) setLine(passINTs.over_value)
    else if (e === 'receiving_yards' && recYards && Object.keys(recYards).length > 0) setLine(recYards.over_value)
    else if (e === 'receptions' && rec && Object.keys(rec).length > 0) setLine(rec.over_value)
    else if (e === 'rushing_yards' && rushYards && Object.keys(rushYards).length > 0) setLine(rushYards.over_value)
    else if (e === 'rush_attempts' && rushAtts && Object.keys(rushAtts).length > 0) setLine(rushAtts.over_value)
    else if (e === 'atts') setLine(0.5);
    else setLine('');
    
    setStatRadio(e);
  }

  const saveSelectedHit = (e) => {
    setSelectedGame(0);
    if (selectedHitCount !== e) setSelectedHitCount(e);
    else setSelectedHitCount('season');
  }

  const saveSelectedGame = (e) => {
    if (selectedGame !== e) {
      setSelectedHitCount('game');
      setSelectedGame(e);
    }
    else {
      setSelectedHitCount('season');
      setSelectedGame(0);
    }
  }

  const toggleCollapse = (e) => {
    if (collapse === 'd-none') setCollapse('');
    else setCollapse('d-none');
  }

  // Helper function to format and color rank
  const formatRank = (rank) => {
    if (rank !== undefined) {
      // If rank is empty, return nothing
      if (rank === '') return;

      rank = rank.toString();
      let rankStr = '';
      if (rank.substring(rank.length - 1) === '1') rankStr = rank + 'st';
      else if (rank.substring(rank.length - 1) === '2') rankStr = rank + 'nd';
      else if (rank.substring(rank.length - 1) === '3') rankStr = rank + 'rd';
      else rankStr = rank + 'th';

      // if (rank <= 10) rank = <span className="text-danger2">{rankStr}</span>;
      // else if (rank >= 23 ) rank = <span className="text-success2">{rankStr}</span>;
      // else rank = <span>{rankStr}</span>;
      rank = <span>({rankStr})</span>;
    }
    
    return rank;
  }

  return (
    <div className="px-12">
      <div className="row mt-2">
        <div className="col">
          <h2 className="text-light ff-g">{player.player_name} <span className="position-font"> {player.position !== undefined ? '(' + player.position + ')' : ''}</span></h2>
        </div>
        <div className="col-auto text-end">
          <h2 className="text-light ff-g"><span className="opponent-font">{player.opp !== undefined && player.opp !== '' ? player.opp + (player.location === '' ? ' @ ' : ' v. ') : ''}</span>{player.team}</h2>
        </div>
      </div>

      <hr></hr>

      {player.position === 'QB' ? (
        <div>
          <div className="row text-white mt-2 mx-0">
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('passing_yards')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'passing_yards' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Pass Yds</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.pypgl3 !== 'N/A' && passYards && Object.keys(passYards).length > 0 && player.pypgl3 < passYards.over_value ? "bg-danger border-danger" : (player.pypgl3 !== 'N/A' && passYards && Object.keys(passYards).length > 0 && player.pypgl3 >= passYards.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'passing_yards' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{passYards && passYards.over_value ? passYards.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{passYards && passYards.over_value ? (passYards.over_odds >= 100 ? '+' : '') + passYards.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{passYards && passYards.under_value ? passYards.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{passYards && passYards.under_value ? (passYards.under_odds >= 100 ? '+' : '') + passYards.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('passing_tds')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'passing_tds' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Pass TDs</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ptpgl3 !== 'N/A' && passTDs && Object.keys(passTDs).length > 0 && player.ptpgl3 < passTDs.over_value ? "bg-danger border-danger" : (player.ptpgl3 !== 'N/A' && passTDs && Object.keys(passTDs).length > 0 && player.ptpgl3 >= passTDs.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'passing_tds' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{passTDs && passTDs.over_value ? passTDs.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{passTDs && passTDs.over_value ? (passTDs.over_odds >= 100 ? '+' : '') + passTDs.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{passTDs && passTDs.under_value ? passTDs.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{passTDs && passTDs.under_value ? (passTDs.under_odds >= 100 ? '+' : '') + passTDs.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('interceptions')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'interceptions' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Pass INTs</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.pipgl3 !== 'N/A' && passINTs && Object.keys(passINTs).length > 0 && player.pipgl3 < passINTs.over_value ? "bg-danger border-danger" : (player.pipgl3 !== 'N/A' && passINTs && Object.keys(passINTs).length > 0 && player.pipgl3 >= passINTs.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'interceptions' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{passINTs && passINTs.over_value ? passINTs.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{passINTs && passINTs.over_value ? (passINTs.over_odds >= 100 ? '+' : '') + passINTs.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{passINTs && passINTs.under_value ? passINTs.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{passINTs && passINTs.under_value ? (passINTs.under_odds >= 100 ? '+' : '') + passINTs.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('rushing_yards')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'rushing_yards' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Rush Yds</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ruypgl3 !== 'N/A' && rushYards && Object.keys(rushYards).length > 0 && player.ruypgl3 < rushYards.over_value ? "bg-danger border-danger" : (player.ruypgl3 !== 'N/A' && rushYards && Object.keys(rushYards).length > 0 && player.ruypgl3 >= rushYards.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'rushing_yards' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushYards && rushYards.over_value ? rushYards.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushYards && rushYards.over_value ? (rushYards.over_odds >= 100 ? '+' : '') + rushYards.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushYards && rushYards.under_value ? rushYards.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushYards && rushYards.under_value ? (rushYards.under_odds >= 100 ? '+' : '') + rushYards.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mx-3 ps-01 text-white">
            <Select 
              defaultValue={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              value={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              options={statOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral0: '#2c3237',
                  neutral80: 'white',
                  primary: 'grey',
                  primary25: '#198754'
                },
              })}
              styles={{
                menuList: base => ({
                  ...base,
                  lineHeight: '17px'
                })
              }}
              onChange={e => saveStatRadio(e)}
            />
          </div>

          <div className="text-center text-white rounded tight-shadow ms-1 me-0 my-3">
            <div className="row bg-bubble border-bottom border-bubble rounded-top fs-12 w-100 py-01 mx-0">
              <div className="col px-0"><strong>Season</strong></div>
              <div className="col px-0"><strong>L7</strong></div>
              <div className="col px-0"><strong>L5</strong></div>
              <div className="col px-0"><strong>L3</strong></div>
              <div className="col px-0"><strong>L1</strong></div>
              <div className="col px-0"><strong>vs {player.opp}</strong></div>
            </div>
            <div className="row fs-cust w-100 mx-0">
              <div className={"col orbitron px-0 mx-001 " + (seasonAvg !== '' ? (seasonAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-left py-0"}><strong>{seasonAvg !== '' ? seasonAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (sevenAvg !== '' ? (sevenAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{sevenAvg !== '' ? sevenAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (fiveAvg !== '' ? (fiveAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{fiveAvg !== '' ? fiveAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (threeAvg !== '' ? (threeAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{threeAvg !== '' ? threeAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (oneAvg !== '' ? (oneAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{oneAvg !== '' ? oneAvg : <>&mdash;</>}</strong></div>
              <div className={"col px-0 " + (vsAvg > -1 ? (vsAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-right py-0"}><strong className="orbitron">{(vsAvg > -1 ? vsAvg : <>&mdash;</>)}</strong> <div className="fs-cust-sm"> {statRadio !== 'minutes' ? formatRank(oppRank) : ''}</div></div>
            </div>
          </div>

          <div className="bg-darkest rounded tight-shadow pt-1 pb-2 px-2 ms-1">
            <div className="row text-white">
              <div className="col orbitron text-orange pe-0"><strong>{line ? 'O/U: ' + line : ''}</strong></div>
              <div className="col orbitron ps-0 pe-5 me-5">{selectedHitCount === 'def' ? <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div> : ''}</div>
            </div>

            <div className="row chartRow mt-2">
              <div className="col chartCol">
                {(Object.keys(chartData).length > 0 ?
                  <Bar
                    data={chartData}
                    options={{
                      plugins: {
                        title: {
                          display: false
                        },
                        legend: {
                          display: false
                        },
                        tooltip: {
                          interaction: {
                            mode: 'index'
                          },
                          intersect: false,
                          callbacks: {
                            title: function(items) {
                              return items[0]['label'].substring(0, items[0]['label'].indexOf(','));
                            },
                            footer: function(items) {
                              return items[0]['label'].substring(items[0]['label'].indexOf(',')+1);
                            }
                          }
                        },
                        datalabels: {
                          labels: {
                            value: {
                              font: {
                                size: (hideLabels === 0 ? '12' : '0'),
                                family: 'Orbitron'
                              },
                              color: "white"
                            }
                          },
                          anchor: 'end',
                          align: 'start',
                          padding: '0',
                          formatter: function(value, index, values) {
                            if (hideLabels === 0 && value > 0) return value;
                            else return '';
                          }
                        },
                        annotation: {
                          annotations: {
                            line1: {
                              type: 'line',
                              yMin: line,
                              yMax: line,
                              borderColor: '#fd7e14',
                              borderWidth: line > 0 ? 2 : 0
                            },
                            // label1: {
                            //   type: 'label',
                            //   xValue: (games.length > 1 && hideLabels === 0 ? 0.5 : (games.length > 1 && hideLabels === 1 ? 1.5 : 0)),
                            //   yValue: line,
                            //   content: [(line > 0 ? 'O/U: ' + line : '')],
                            //   font: { size: 14 },
                            //   color: 'white'
                            // }
                          }
                        }
                      },
                      scales: {
                        x: {
                          ticks: {
                            font: {
                              // size: (hideLabels === 0 ? (selectedHitCount !== 'def' ? (games.length <= 25 ? 12 : (games.length <= 50 ? 6 : (games.length <= 75 ? 4 : 2))) : 
                              //     (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 25 ? 12 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 50 ? 6 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 75 ? 4 : 2))))
                              //     : 1)
                              size: (hideLabels === 0 ? 12 : 2)
                            }
                          },
                          grid: {
                            display: false
                          }
                        },
                        y: {
                          grid: {
                            display: false
                          }
                        },
                      }
                    }}
                  />
                : '')}
              </div>
            </div>

            <div className="row text-light fs-13 hit-count-row p-1 ms-2 mt-2">
              <div className="col centerer"></div>
              {games.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (seasonHitCount / games.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'season' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('season')}>
                  <div><strong>Season</strong></div>
                  <div>{seasonHitCount + ' of ' + games.length}</div>
                  <div>{Math.round(seasonHitCount / games.length * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 7 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (sevenHitCount / 7 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l7' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l7')}>
                  <div><strong>L7</strong></div>
                  <div>{sevenHitCount + ' of 7'}</div>
                  <div>{Math.round(sevenHitCount / 7 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 5 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (fiveHitCount / 5 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l5' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l5')}>
                  <div><strong>L5</strong></div>
                  <div>{fiveHitCount + ' of 5'}</div>
                  <div>{Math.round(fiveHitCount / 5 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 3 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (threeHitCount / 3 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l3' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l3')}>
                  <div><strong>L3</strong></div>
                  <div>{threeHitCount + ' of 3'}</div>
                  <div>{Math.round(threeHitCount / 3 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (oneHitCount / 1 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l1' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l1')}>
                  <div><strong>L1</strong></div>
                  <div>{oneHitCount + ' of 1'}</div>
                  <div>{Math.round(oneHitCount / 1 * 100) + '%'}</div>
                </div>
              : ''}
              {oppGames.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (vsHitCount / oppGames.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'vs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('vs')}>
                  <div><strong>vs {player.opp}</strong></div>
                  <div>{vsHitCount + ' of ' + oppGames.length}</div>
                  <div>{Math.round(vsHitCount / oppGames.length * 100) + '%'}</div>
                </div>
              : ''}
              {homeGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (homeHitCount / homeGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'home' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('home')}>
                  <div><strong>Home</strong></div>
                  <div>{homeHitCount + ' of ' + homeGameCount}</div>
                  <div>{Math.round(homeHitCount / homeGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {awayGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (awayHitCount / awayGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'away' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('away')}>
                  <div><strong>Away</strong></div>
                  <div>{awayHitCount + ' of ' + awayGameCount}</div>
                  <div>{Math.round(awayHitCount / awayGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {winGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (winHitCount / winGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'win' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('win')}>
                  <div><strong>Win</strong></div>
                  <div>{winHitCount + ' of ' + winGameCount}</div>
                  <div>{Math.round(winHitCount / winGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {lossGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (lossHitCount / lossGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'loss' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('loss')}>
                  <div><strong>Loss</strong></div>
                  <div>{lossHitCount + ' of ' + lossGameCount}</div>
                  <div>{Math.round(lossHitCount / lossGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {/* {oppsGames.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col-wide text-center rounded tight-shadow border border-" + (defHitCount / defGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'def' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('def')}>
                  <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div>
                  <div>{defHitCount + ' of ' + defGameCount}</div>
                  <div>{Math.round(defHitCount / defGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {playoffsGameCount > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (playoffsHitCount / playoffsGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'playoffs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('playoffs')}>
                  <div><strong>Playoffs</strong></div>
                  <div>{playoffsHitCount + ' of ' + playoffsGameCount}</div>
                  <div>{Math.round(playoffsHitCount / playoffsGameCount * 100) + '%'}</div>
                </div>
              : ''} */}
              <div className="col centerer"></div>
            </div>
          </div>

          <h3 className="mt-2 text-light ff-g"><strong><u>Game Log</u></strong></h3>
          <div className="row">
            <div className="game-log-table">
              <table className="table text-light mb-1">
                <thead>
                  <tr>
                    <th>G</th>
                    <th>Wk</th>
                    <th>Date</th>
                    <th>Opp</th>
                    <th>W/L</th>
                    <th>GS</th>
                    <th>Snaps</th>
                    <th>Snap Pct</th>
                    <th>Pass Atts</th>
                    <th>Pass Comps</th>
                    <th>INTs</th>
                    <th>Pass Yds</th>
                    <th>Pass TDs</th>
                    <th>Rush Atts</th>
                    <th>Rush Yds</th>
                    <th>Rush TDs</th>
                  </tr>
                </thead>
                <tbody>
                  {games.map((g, index) => 
                    <tr key={index} className={"cursor-pointer " + (g.week === 19 && g.playoffs === 1 ? "border-bottom border-purple " : "") + (selectedGame === g.game_player_id ? "game-selected" : "")} onClick={e => saveSelectedGame(g.game_player_id)}>
                      <td>{g.game}</td>
                      <td>{g.week}</td>
                      <td className="whitespace-nowrap">{g.date.substring(0, 10)}</td>
                      <td>{g.location + (g.real_team ?? g.opponent)}</td>
                      <td>{g.win_loss}</td>
                      <td>{g.started === 1 ? 'Y' : 'N'}</td>
                      <td>{(g.offensive_snaps !== -1 ? g.offensive_snaps : '')}</td>
                      <td>{(g.offensive_snap_pct !== '-1' ? g.offensive_snap_pct : '')}</td>
                      <td>{g.attempts}</td>
                      <td>{g.completions}</td>
                      <td>{g.interceptions}</td>
                      <td>{g.passing_yards}</td>
                      <td>{g.passing_tds}</td>
                      <td>{g.rush_attempts}</td>
                      <td>{g.rushing_yards}</td>
                      <td>{g.rushing_tds}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-center">
            <button type="button" className="btn header-btn text-primary p-2 mb-2" onClick={e => toggleCollapse(e)}>Toggle Header Legend</button>
            <div className={collapse + " header-div border border-white rounded text-start text-white py-1 px-3 mb-3"} id="headerLegend">
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>G</strong> - Game</div>
                <div className="col-md-12 col-lg-3"><strong>Wk</strong> - Week</div>
                <div className="col-md-12 col-lg-3"><strong>Opp</strong> - Opponent</div>
                <div className="col-md-12 col-lg-3"><strong>W/L</strong> - Win / Loss</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>GS</strong> - Game Started</div>
                <div className="col-md-12 col-lg-3"><strong>Snaps</strong> - Snaps Played</div>
                <div className="col-md-12 col-lg-3"><strong>Snap Pct</strong> - Percentage Snaps Played</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>Pass Atts</strong> - Pass Attempts</div>
                <div className="col-md-12 col-lg-3"><strong>Pass Comps</strong> - Pass Completions</div>
                <div className="col-md-12 col-lg-3"><strong>INTs</strong> - Interceptions Thrown</div>
                <div className="col-md-12 col-lg-3"><strong>Pass Yds</strong> - Passing Yards</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>Pass TDs</strong> - Passing Touchdowns</div>
                <div className="col-md-12 col-lg-3"><strong>Rush Atts</strong> - Rush Attempts</div>
                <div className="col-md-12 col-lg-3"><strong>Rush Yds</strong> - Rush Yards</div>
                <div className="col-md-12 col-lg-3"><strong>Rush TDs</strong> - Rushing Touchdowns</div>
              </div>
            </div>
          </div>
        </div>) : 

      (player.position === 'WR' || player.position === 'TE' ? (
        <div>
          <div className="row text-white mt-2 mx-0">
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('receiving_yards')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'receiving_yards' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Rec Yds</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.rypgl3 !== 'N/A' && recYards && Object.keys(recYards).length > 0 && player.rypgl3 < recYards.over_value ? "bg-danger border-danger" : (player.rypgl3 !== 'N/A' && recYards && Object.keys(recYards).length > 0 && player.rypgl3 >= recYards.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'receiving_yards' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{recYards && recYards.over_value ? recYards.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{recYards && recYards.over_value ? (recYards.over_odds >= 100 ? '+' : '') + recYards.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{recYards && recYards.under_value ? recYards.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{recYards && recYards.under_value ? (recYards.under_odds >= 100 ? '+' : '') + recYards.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('receptions')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'receptions' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Recs</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.repgl3 !== 'N/A' && rec && Object.keys(rec).length > 0 && player.repgl3 < rec.over_value ? "bg-danger border-danger" : (player.repgl3 !== 'N/A' && rec && Object.keys(rec).length > 0 && player.repgl3 >= rec.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'receptions' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rec && rec.over_value ? rec.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rec && rec.over_value ? (rec.over_odds >= 100 ? '+' : '') + rec.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rec && rec.under_value ? rec.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rec && rec.under_value ? (rec.under_odds >= 100 ? '+' : '') + rec.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('rushing_yards')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'rushing_yards' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Rush Yds</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ruypgl3 !== 'N/A' && rushYards && Object.keys(rushYards).length > 0 && player.ruypgl3 < rushYards.over_value ? "bg-danger border-danger" : (player.ruypgl3 !== 'N/A' && rushYards && Object.keys(rushYards).length > 0 && player.ruypgl3 >= rushYards.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'rushing_yards' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushYards && rushYards.over_value ? rushYards.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushYards && rushYards.over_value ? (rushYards.over_odds >= 100 ? '+' : '') + rushYards.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushYards && rushYards.under_value ? rushYards.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushYards && rushYards.under_value ? (rushYards.under_odds >= 100 ? '+' : '') + rushYards.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('rush_attempts')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'rush_attempts' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Rush Atts</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ruapgl3 !== 'N/A' && rushAtts && Object.keys(rushAtts).length > 0 && player.ruapgl3 < rushAtts.over_value ? "bg-danger border-danger" : (player.ruapgl3 !== 'N/A' && rushAtts && Object.keys(rushAtts).length > 0 && player.ruapgl3 >= rushAtts.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'rush_attempts' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushAtts && rushAtts.over_value ? rushAtts.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushAtts && rushAtts.over_value ? (rushAtts.over_odds >= 100 ? '+' : '') + rushAtts.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushAtts && rushAtts.under_value ? rushAtts.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushAtts && rushAtts.under_value ? (rushAtts.under_odds >= 100 ? '+' : '') + rushAtts.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mx-3 ps-01 text-white">
            <Select 
              defaultValue={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              value={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              options={statOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral0: '#2c3237',
                  neutral80: 'white',
                  primary: 'grey',
                  primary25: '#198754'
                },
              })}
              styles={{
                menuList: base => ({
                  ...base,
                  lineHeight: '17px'
                })
              }}
              onChange={e => saveStatRadio(e)}
            />
          </div>

          <div className="text-center text-white rounded tight-shadow ms-1 me-0 my-3">
            <div className="row bg-bubble border-bottom border-bubble rounded-top fs-12 w-100 py-01 mx-0">
              <div className="col px-0"><strong>Season</strong></div>
              <div className="col px-0"><strong>L7</strong></div>
              <div className="col px-0"><strong>L5</strong></div>
              <div className="col px-0"><strong>L3</strong></div>
              <div className="col px-0"><strong>L1</strong></div>
              <div className="col px-0"><strong>vs {player.opp}</strong></div>
            </div>
            <div className="row fs-cust w-100 mx-0">
              <div className={"col orbitron px-0 mx-001 " + (seasonAvg !== '' ? (seasonAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-left py-0"}><strong>{seasonAvg !== '' ? seasonAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (sevenAvg !== '' ? (sevenAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{sevenAvg !== '' ? sevenAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (fiveAvg !== '' ? (fiveAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{fiveAvg !== '' ? fiveAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (threeAvg !== '' ? (threeAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{threeAvg !== '' ? threeAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (oneAvg !== '' ? (oneAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{oneAvg !== '' ? oneAvg : <>&mdash;</>}</strong></div>
              <div className={"col px-0 " + (vsAvg > -1 ? (vsAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-right py-0"}><strong className="orbitron">{(vsAvg > -1 ? vsAvg : <>&mdash;</>)}</strong> <div className="fs-cust-sm"> {statRadio !== 'minutes' ? formatRank(oppRank) : ''}</div></div>
            </div>
          </div>

          <div className="bg-darkest rounded tight-shadow pt-1 pb-2 px-2 ms-1">
            <div className="row text-white">
              <div className="col orbitron text-orange pe-0"><strong>{line ? 'O/U: ' + line : ''}</strong></div>
              <div className="col orbitron ps-0 pe-5 me-5">{selectedHitCount === 'def' ? <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div> : ''}</div>
            </div>

            <div className="row chartRow mt-2">
              <div className="col chartCol">
                {(Object.keys(chartData).length > 0 ?
                  <Bar
                    data={chartData}
                    options={{
                      plugins: {
                        title: {
                          display: false
                        },
                        legend: {
                          display: false
                        },
                        tooltip: {
                          interaction: {
                            mode: 'index'
                          },
                          intersect: false,
                          callbacks: {
                            title: function(items) {
                              return items[0]['label'].substring(0, items[0]['label'].indexOf(','));
                            },
                            footer: function(items) {
                              return items[0]['label'].substring(items[0]['label'].indexOf(',')+1);
                            }
                          }
                        },
                        datalabels: {
                          labels: {
                            value: {
                              font: {
                                size: (hideLabels === 0 ? '12' : '0'),
                                family: 'Orbitron'
                              },
                              color: "white"
                            }
                          },
                          anchor: 'end',
                          align: 'start',
                          padding: '0',
                          formatter: function(value, index, values) {
                            if (hideLabels === 0 && value > 0) return value;
                            else return '';
                          }
                        },
                        annotation: {
                          annotations: {
                            line1: {
                              type: 'line',
                              yMin: line,
                              yMax: line,
                              borderColor: '#fd7e14',
                              borderWidth: line > 0 ? 2 : 0
                            },
                            // label1: {
                            //   type: 'label',
                            //   xValue: (games.length > 1 && hideLabels === 0 ? 0.5 : (games.length > 1 && hideLabels === 1 ? 1.5 : 0)),
                            //   yValue: line,
                            //   content: [(line > 0 ? 'O/U: ' + line : '')],
                            //   font: { size: 14 },
                            //   color: 'white'
                            // }
                          }
                        }
                      },
                      scales: {
                        x: {
                          ticks: {
                            font: {
                              // size: (hideLabels === 0 ? (selectedHitCount !== 'def' ? (games.length <= 25 ? 12 : (games.length <= 50 ? 6 : (games.length <= 75 ? 4 : 2))) : 
                              //     (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 25 ? 12 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 50 ? 6 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 75 ? 4 : 2))))
                              //     : 1)
                              size: (hideLabels === 0 ? 12 : 2)
                            }
                          },
                          grid: {
                            display: false
                          }
                        },
                        y: {
                          grid: {
                            display: false
                          }
                        },
                      }
                    }}
                  />
                : '')}
              </div>
            </div>
            
            <div className="row text-light fs-13 hit-count-row p-1 ms-2 mt-2">
              <div className="col centerer"></div>
              {games.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (seasonHitCount / games.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'season' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('season')}>
                  <div><strong>Season</strong></div>
                  <div>{seasonHitCount + ' of ' + games.length}</div>
                  <div>{Math.round(seasonHitCount / games.length * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 7 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (sevenHitCount / 7 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l7' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l7')}>
                  <div><strong>L7</strong></div>
                  <div>{sevenHitCount + ' of 7'}</div>
                  <div>{Math.round(sevenHitCount / 7 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 5 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (fiveHitCount / 5 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l5' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l5')}>
                  <div><strong>L5</strong></div>
                  <div>{fiveHitCount + ' of 5'}</div>
                  <div>{Math.round(fiveHitCount / 5 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 3 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (threeHitCount / 3 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l3' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l3')}>
                  <div><strong>L3</strong></div>
                  <div>{threeHitCount + ' of 3'}</div>
                  <div>{Math.round(threeHitCount / 3 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (oneHitCount / 1 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l1' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l1')}>
                  <div><strong>L1</strong></div>
                  <div>{oneHitCount + ' of 1'}</div>
                  <div>{Math.round(oneHitCount / 1 * 100) + '%'}</div>
                </div>
              : ''}
              {oppGames.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (vsHitCount / oppGames.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'vs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('vs')}>
                  <div><strong>vs {player.opp}</strong></div>
                  <div>{vsHitCount + ' of ' + oppGames.length}</div>
                  <div>{Math.round(vsHitCount / oppGames.length * 100) + '%'}</div>
                </div>
              : ''}
              {homeGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (homeHitCount / homeGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'home' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('home')}>
                  <div><strong>Home</strong></div>
                  <div>{homeHitCount + ' of ' + homeGameCount}</div>
                  <div>{Math.round(homeHitCount / homeGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {awayGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (awayHitCount / awayGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'away' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('away')}>
                  <div><strong>Away</strong></div>
                  <div>{awayHitCount + ' of ' + awayGameCount}</div>
                  <div>{Math.round(awayHitCount / awayGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {winGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (winHitCount / winGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'win' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('win')}>
                  <div><strong>Win</strong></div>
                  <div>{winHitCount + ' of ' + winGameCount}</div>
                  <div>{Math.round(winHitCount / winGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {lossGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (lossHitCount / lossGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'loss' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('loss')}>
                  <div><strong>Loss</strong></div>
                  <div>{lossHitCount + ' of ' + lossGameCount}</div>
                  <div>{Math.round(lossHitCount / lossGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {/* {oppsGames.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col-wide text-center rounded tight-shadow border border-" + (defHitCount / defGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'def' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('def')}>
                  <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div>
                  <div>{defHitCount + ' of ' + defGameCount}</div>
                  <div>{Math.round(defHitCount / defGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {playoffsGameCount > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (playoffsHitCount / playoffsGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'playoffs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('playoffs')}>
                  <div><strong>Playoffs</strong></div>
                  <div>{playoffsHitCount + ' of ' + playoffsGameCount}</div>
                  <div>{Math.round(playoffsHitCount / playoffsGameCount * 100) + '%'}</div>
                </div>
              : ''} */}
              <div className="col centerer"></div>
            </div>
          </div>

          <h3 className="mt-2 text-light ff-g"><strong><u>Game Log</u></strong></h3>
          <div className="row">
            <div className="game-log-table">
              <table className="table text-light mb-1">
                <thead>
                  <tr>
                    <th>G</th>
                    <th>Wk</th>
                    <th>Date</th>
                    <th>Opp</th>
                    <th>W/L</th>
                    <th>GS</th>
                    <th>Snaps</th>
                    <th>Snap Pct</th>
                    <th>Targets</th>
                    <th>Recs</th>
                    <th>Rec Yds</th>
                    <th>Rec TDs</th>
                    <th>Rush Atts</th>
                    <th>Rush Yds</th>
                    <th>Rush TDs</th>
                  </tr>
                </thead>
                <tbody>
                  {games.map((g, index) => 
                    <tr key={index} className={"cursor-pointer " + (g.week === 19 && g.playoffs === 1 ? "border-bottom border-purple " : "") + (selectedGame === g.game_player_id ? "game-selected" : "")} onClick={e => saveSelectedGame(g.game_player_id)}>
                      <td>{g.game}</td>
                      <td>{g.week}</td>
                      <td className="whitespace-nowrap">{g.date.substring(0, 10)}</td>
                      <td>{g.location + (g.real_team ?? g.opponent)}</td>
                      <td>{g.win_loss}</td>
                      <td>{g.started === 1 ? 'Y' : 'N'}</td>
                      <td>{(g.offensive_snaps !== -1 ? g.offensive_snaps : '')}</td>
                      <td>{(g.offensive_snap_pct !== '-1' ? g.offensive_snap_pct : '')}</td>
                      <td>{g.receiving_targets}</td>
                      <td>{g.receptions}</td>
                      <td>{g.receiving_yards}</td>
                      <td>{g.receiving_tds}</td>
                      <td>{g.rush_attempts}</td>
                      <td>{g.rushing_yards}</td>
                      <td>{g.rushing_tds}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-center">
            <button type="button" className="btn header-btn text-primary p-2 mb-2" onClick={e => toggleCollapse(e)}>Toggle Header Legend</button>
            <div className={collapse + " header-div border border-white rounded text-start text-white py-1 px-3 mb-3"} id="headerLegend">
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>G</strong> - Game</div>
                <div className="col-md-12 col-lg-3"><strong>Wk</strong> - Week</div>
                <div className="col-md-12 col-lg-3"><strong>Opp</strong> - Opponent</div>
                <div className="col-md-12 col-lg-3"><strong>W/L</strong> - Win / Loss</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>GS</strong> - Game Started</div>
                <div className="col-md-12 col-lg-3"><strong>Snaps</strong> - Snaps Played</div>
                <div className="col-md-12 col-lg-3"><strong>Snap Pct</strong> - Percentage Snaps Played</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>Pass Atts</strong> - Pass Attempts</div>
                <div className="col-md-12 col-lg-3"><strong>Pass Comps</strong> - Pass Completions</div>
                <div className="col-md-12 col-lg-3"><strong>INTs</strong> - Interceptions Thrown</div>
                <div className="col-md-12 col-lg-3"><strong>Pass Yds</strong> - Passing Yards</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>Rush Atts</strong> - Rush Attempts</div>
                <div className="col-md-12 col-lg-3"><strong>Rush Yds</strong> - Rush Yards</div>
                <div className="col-md-12 col-lg-3"><strong>Rush TDs</strong> - Rushing Touchdowns</div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
            </div>
          </div>
        </div>) : 

      (player.position === 'RB' || player.position === 'FB' ? (
        <div>
          <div className="row text-white mt-2 mx-0">
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('rushing_yards')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'rushing_yards' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Rush Yds</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ruypgl3 !== 'N/A' && rushYards && Object.keys(rushYards).length > 0 && player.ruypgl3 < rushYards.over_value ? "bg-danger border-danger" : (player.ruypgl3 !== 'N/A' && rushYards && Object.keys(rushYards).length > 0 && player.ruypgl3 >= rushYards.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'rushing_yards' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushYards && rushYards.over_value ? rushYards.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushYards && rushYards.over_value ? (rushYards.over_odds >= 100 ? '+' : '') + rushYards.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushYards && rushYards.under_value ? rushYards.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushYards && rushYards.under_value ? (rushYards.under_odds >= 100 ? '+' : '') + rushYards.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('rush_attempts')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'rush_attempts' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Rush Atts</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.ruapgl3 !== 'N/A' && rushAtts && Object.keys(rushAtts).length > 0 && player.ruapgl3 < rushAtts.over_value ? "bg-danger border-danger" : (player.ruapgl3 !== 'N/A' && rushAtts && Object.keys(rushAtts).length > 0 && player.ruapgl3 >= rushAtts.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'rush_attempts' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushAtts && rushAtts.over_value ? rushAtts.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushAtts && rushAtts.over_value ? (rushAtts.over_odds >= 100 ? '+' : '') + rushAtts.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rushAtts && rushAtts.under_value ? rushAtts.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rushAtts && rushAtts.under_value ? (rushAtts.under_odds >= 100 ? '+' : '') + rushAtts.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('receiving_yards')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'receiving_yards' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Rec Yds</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.rypgl3 !== 'N/A' && recYards && Object.keys(recYards).length > 0 && player.rypgl3 < recYards.over_value ? "bg-danger border-danger" : (player.rypgl3 !== 'N/A' && recYards && Object.keys(recYards).length > 0 && player.rypgl3 >= recYards.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'receiving_yards' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{recYards && recYards.over_value ? recYards.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{recYards && recYards.over_value ? (recYards.over_odds >= 100 ? '+' : '') + recYards.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{recYards && recYards.under_value ? recYards.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{recYards && recYards.under_value ? (recYards.under_odds >= 100 ? '+' : '') + recYards.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 rounded line-bubble px-1 mb-2 mb-sm-0" onClick={e => saveStatRadio('receptions')}>
              <div className="row rounded tight-shadow mx-0">
                <div className="col">
                  <div className={"row bg-bubble border-top border-start border-end border-bottom border-bubble rounded-top " + (statRadio === 'receptions' ? 'upper-border-purple' : '')}>
                    <div className="col text-center fs-header"><strong>Recs</strong></div>
                  </div>
                  <div className={"row text-center rounded-bottom border "
                    + (player.repgl3 !== 'N/A' && rec && Object.keys(rec).length > 0 && player.repgl3 < rec.over_value ? "bg-danger border-danger" : (player.repgl3 !== 'N/A' && rec && Object.keys(rec).length > 0 && player.repgl3 >= rec.over_value ? "bg-success border-success" : "bg-bubble border-bubble"))
                    + (statRadio === 'receptions' ? ' lower-border-purple' : '')}
                  >
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">over</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rec && rec.over_value ? rec.over_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rec && rec.over_value ? (rec.over_odds >= 100 ? '+' : '') + rec.over_odds : <>&mdash;</>}</div>
                    </div>
                    <div className="col p-0">
                      <div className="p-0 m-0 fs-12">under</div>
                      <div className="p-0 m-0 fs-18 orbitron"><strong>{rec && rec.under_value ? rec.under_value : <>&mdash;</>}</strong></div>
                      <div className="p-0 m-0 fs-odds">{rec && rec.under_value ? (rec.under_odds >= 100 ? '+' : '') + rec.under_odds : <>&mdash;</>}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mx-3 ps-01 text-white">
            <Select 
              defaultValue={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              value={statOptions[statOptions.findIndex(obj => { return obj.value === statRadio })]}
              options={statOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral0: '#2c3237',
                  neutral80: 'white',
                  primary: 'grey',
                  primary25: '#198754'
                },
              })}
              styles={{
                menuList: base => ({
                  ...base,
                  lineHeight: '17px'
                })
              }}
              onChange={e => saveStatRadio(e)}
            />
          </div>

          <div className="text-center text-white rounded tight-shadow ms-1 me-0 my-3">
            <div className="row bg-bubble border-bottom border-bubble rounded-top fs-12 w-100 py-01 mx-0">
              <div className="col px-0"><strong>Season</strong></div>
              <div className="col px-0"><strong>L7</strong></div>
              <div className="col px-0"><strong>L5</strong></div>
              <div className="col px-0"><strong>L3</strong></div>
              <div className="col px-0"><strong>L1</strong></div>
              <div className="col px-0"><strong>vs {player.opp}</strong></div>
            </div>
            <div className="row fs-cust w-100 mx-0">
              <div className={"col orbitron px-0 mx-001 " + (seasonAvg !== '' ? (seasonAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-left py-0"}><strong>{seasonAvg !== '' ? seasonAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (sevenAvg !== '' ? (sevenAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{sevenAvg !== '' ? sevenAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (fiveAvg !== '' ? (fiveAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{fiveAvg !== '' ? fiveAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (threeAvg !== '' ? (threeAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{threeAvg !== '' ? threeAvg : <>&mdash;</>}</strong></div>
              <div className={"col orbitron px-0 mx-001 " + (oneAvg !== '' ? (oneAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " py-0"}><strong>{oneAvg !== '' ? oneAvg : <>&mdash;</>}</strong></div>
              <div className={"col px-0 " + (vsAvg > -1 ? (vsAvg > line ? 'bg-success' : 'bg-danger') : 'bg-bubble') + " rounded-bottom-right py-0"}><strong className="orbitron">{(vsAvg > -1 ? vsAvg : <>&mdash;</>)}</strong> <div className="fs-cust-sm"> {statRadio !== 'minutes' ? formatRank(oppRank) : ''}</div></div>
            </div>
          </div>

          <div className="bg-darkest rounded tight-shadow pt-1 pb-2 px-2 ms-1">
            <div className="row text-white">
              <div className="col orbitron text-orange pe-0"><strong>{line ? 'O/U: ' + line : ''}</strong></div>
              <div className="col orbitron ps-0 pe-5 me-5">{selectedHitCount === 'def' ? <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div> : ''}</div>
            </div>

            <div className="row chartRow mt-2">
              <div className="col chartCol">
                {(Object.keys(chartData).length > 0 ?
                  <Bar
                  data={chartData}
                    options={{
                      plugins: {
                        title: {
                          display: false
                        },
                        legend: {
                          display: false
                        },
                        tooltip: {
                          interaction: {
                            mode: 'index'
                          },
                          intersect: false,
                          callbacks: {
                            title: function(items) {
                              return items[0]['label'].substring(0, items[0]['label'].indexOf(','));
                            },
                            footer: function(items) {
                              return items[0]['label'].substring(items[0]['label'].indexOf(',')+1);
                            }
                          }
                        },
                        datalabels: {
                          labels: {
                            value: {
                              font: {
                                size: (hideLabels === 0 ? '12' : '0'),
                                family: 'Orbitron'
                              },
                              color: "white"
                            }
                          },
                          anchor: 'end',
                          align: 'start',
                          padding: '0',
                          formatter: function(value, index, values) {
                            if (hideLabels === 0 && value > 0) return value;
                            else return '';
                          }
                        },
                        annotation: {
                          annotations: {
                            line1: {
                              type: 'line',
                              yMin: line,
                              yMax: line,
                              borderColor: '#fd7e14',
                              borderWidth: line > 0 ? 2 : 0
                            },
                            // label1: {
                            //   type: 'label',
                            //   xValue: (games.length > 1 && hideLabels === 0 ? 0.5 : (games.length > 1 && hideLabels === 1 ? 1.5 : 0)),
                            //   yValue: line,
                            //   content: [(line > 0 ? 'O/U: ' + line : '')],
                            //   font: { size: 14 },
                            //   color: 'white'
                            // }
                          }
                        }
                      },
                      scales: {
                        x: {
                          ticks: {
                            font: {
                              // size: (hideLabels === 0 ? (selectedHitCount !== 'def' ? (games.length <= 25 ? 12 : (games.length <= 50 ? 6 : (games.length <= 75 ? 4 : 2))) : 
                              //     (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 25 ? 12 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 50 ? 6 : (oppsGames.filter(g => g[player.position.toLowerCase() + '_' + statRadio] !== null).length <= 75 ? 4 : 2))))
                              //     : 1)
                              size: (hideLabels === 0 ? 12 : 2)
                            }
                          },
                          grid: {
                            display: false
                          }
                        },
                        y: {
                          grid: {
                            display: false
                          }
                        },
                      }
                    }}
                  />
                : '')}
              </div>
            </div>
            
            <div className="row text-light fs-13 hit-count-row p-1 ms-2 mt-2">
              <div className="col centerer"></div>
              {games.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (seasonHitCount / games.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'season' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('season')}>
                  <div><strong>Season</strong></div>
                  <div>{seasonHitCount + ' of ' + games.length}</div>
                  <div>{Math.round(seasonHitCount / games.length * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 7 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (sevenHitCount / 7 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l7' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l7')}>
                  <div><strong>L7</strong></div>
                  <div>{sevenHitCount + ' of 7'}</div>
                  <div>{Math.round(sevenHitCount / 7 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 5 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (fiveHitCount / 5 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l5' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l5')}>
                  <div><strong>L5</strong></div>
                  <div>{fiveHitCount + ' of 5'}</div>
                  <div>{Math.round(fiveHitCount / 5 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 3 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (threeHitCount / 3 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l3' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l3')}>
                  <div><strong>L3</strong></div>
                  <div>{threeHitCount + ' of 3'}</div>
                  <div>{Math.round(threeHitCount / 3 * 100) + '%'}</div>
                </div>
              : ''}
              {games.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (oneHitCount / 1 >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'l1' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('l1')}>
                  <div><strong>L1</strong></div>
                  <div>{oneHitCount + ' of 1'}</div>
                  <div>{Math.round(oneHitCount / 1 * 100) + '%'}</div>
                </div>
              : ''}
              {oppGames.length >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (vsHitCount / oppGames.length >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'vs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('vs')}>
                  <div><strong>vs {player.opp}</strong></div>
                  <div>{vsHitCount + ' of ' + oppGames.length}</div>
                  <div>{Math.round(vsHitCount / oppGames.length * 100) + '%'}</div>
                </div>
              : ''}
              {homeGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (homeHitCount / homeGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'home' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('home')}>
                  <div><strong>Home</strong></div>
                  <div>{homeHitCount + ' of ' + homeGameCount}</div>
                  <div>{Math.round(homeHitCount / homeGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {awayGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (awayHitCount / awayGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'away' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('away')}>
                  <div><strong>Away</strong></div>
                  <div>{awayHitCount + ' of ' + awayGameCount}</div>
                  <div>{Math.round(awayHitCount / awayGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {winGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (winHitCount / winGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'win' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('win')}>
                  <div><strong>Win</strong></div>
                  <div>{winHitCount + ' of ' + winGameCount}</div>
                  <div>{Math.round(winHitCount / winGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {lossGameCount >= 1 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (lossHitCount / lossGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'loss' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('loss')}>
                  <div><strong>Loss</strong></div>
                  <div>{lossHitCount + ' of ' + lossGameCount}</div>
                  <div>{Math.round(lossHitCount / lossGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {/* {oppsGames.length > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col-wide text-center rounded tight-shadow border border-" + (defHitCount / defGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'def' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('def')}>
                  <div><strong>{player.opp}</strong> vs <strong>{player.position}</strong></div>
                  <div>{defHitCount + ' of ' + defGameCount}</div>
                  <div>{Math.round(defHitCount / defGameCount * 100) + '%'}</div>
                </div>
              : ''}
              {playoffsGameCount > 0 && line > 0 ? 
                <div className={"col-auto hit-rate-col text-center rounded tight-shadow border border-" + (playoffsHitCount / playoffsGameCount >= 0.5 ? "success" : "danger") + " " + (selectedHitCount === 'playoffs' ? 'bg-bubble' : '') + " py-1 mx-1"} onClick={e => saveSelectedHit('playoffs')}>
                  <div><strong>Playoffs</strong></div>
                  <div>{playoffsHitCount + ' of ' + playoffsGameCount}</div>
                  <div>{Math.round(playoffsHitCount / playoffsGameCount * 100) + '%'}</div>
                </div>
              : ''} */}
              <div className="col centerer"></div>
            </div>
          </div>

          <h3 className="mt-2 text-light ff-g"><strong><u>Game Log</u></strong></h3>
          <div className="row">
            <div className="game-log-table">
              <table className="table text-light mb-1">
                <thead>
                  <tr>
                    <th>G</th>
                    <th>Wk</th>
                    <th>Date</th>
                    <th>Opp</th>
                    <th>W/L</th>
                    <th>GS</th>
                    <th>Snaps</th>
                    <th>Snap Pct</th>
                    <th>Rush Atts</th>
                    <th>Rush Yds</th>
                    <th>Rush TDs</th>
                    <th>Targets</th>
                    <th>Recs</th>
                    <th>Rec Yds</th>
                    <th>Rec TDs</th>
                  </tr>
                </thead>
                <tbody>
                  {games.map((g, index) => 
                    <tr key={index} className={"cursor-pointer " + (g.week === 19 && g.playoffs === 1 ? "border-bottom border-purple " : "") + (selectedGame === g.game_player_id ? "game-selected" : "")} onClick={e => saveSelectedGame(g.game_player_id)}>
                      <td>{g.game}</td>
                      <td>{g.week}</td>
                      <td className="whitespace-nowrap">{g.date.substring(0, 10)}</td>
                      <td>{g.location + (g.real_team ?? g.opponent)}</td>
                      <td>{g.win_loss}</td>
                      <td>{g.started === 1 ? 'Y' : 'N'}</td>
                      <td>{(g.offensive_snaps !== -1 ? g.offensive_snaps : '')}</td>
                      <td>{(g.offensive_snap_pct !== '-1' ? g.offensive_snap_pct : '')}</td>
                      <td>{g.rush_attempts}</td>
                      <td>{g.rushing_yards}</td>
                      <td>{g.rushing_tds}</td>
                      <td>{g.receiving_targets}</td>
                      <td>{g.receptions}</td>
                      <td>{g.receiving_yards}</td>
                      <td>{g.receiving_tds}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-center">
            <button type="button" className="btn header-btn text-primary p-2 mb-2" onClick={e => toggleCollapse(e)}>Toggle Header Legend</button>
            <div className={collapse + " header-div border border-white rounded text-start text-white py-1 px-3 mb-3"} id="headerLegend">
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>G</strong> - Game</div>
                <div className="col-md-12 col-lg-3"><strong>Wk</strong> - Week</div>
                <div className="col-md-12 col-lg-3"><strong>Opp</strong> - Opponent</div>
                <div className="col-md-12 col-lg-3"><strong>W/L</strong> - Win / Loss</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>GS</strong> - Game Started</div>
                <div className="col-md-12 col-lg-3"><strong>Snaps</strong> - Snaps Played</div>
                <div className="col-md-12 col-lg-3"><strong>Snap Pct</strong> - Percentage Snaps Played</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>Pass Atts</strong> - Pass Attempts</div>
                <div className="col-md-12 col-lg-3"><strong>Pass Comps</strong> - Pass Completions</div>
                <div className="col-md-12 col-lg-3"><strong>INTs</strong> - Interceptions Thrown</div>
                <div className="col-md-12 col-lg-3"><strong>Pass Yds</strong> - Passing Yards</div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3"><strong>Recs</strong> - Pass Receptions</div>
                <div className="col-md-12 col-lg-3"><strong>Rec Yds</strong> - Receiving Yards</div>
                <div className="col-md-12 col-lg-3"><strong>Rec TDs</strong> - Receiving Touchdowns</div>
                <div className="col-md-12 col-lg-3"></div>
              </div>
            </div>
          </div>
        </div>) : ''))}
    </div>
  );
}
 
export default PlayerData;