import React, { useState } from 'react';
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";
import axios from "axios";

async function enroll(credentials) {
  var API_URL;
  if (process.env.NODE_ENV === 'development') API_URL = 'http://localhost:8000/';
  else API_URL = 'https://prop-jockey.herokuapp.com/';

  return fetch(API_URL + 'pj/enroll', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'token': 'XkjQzRQEsm2Ptvcj6kd6LqWL' },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

export default function Enroll () {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [enrolling, setEnrolling] = useState(false);
  const [enrolled, setEnrolled] = useState(false);

  const handleSubmit = async e => {
    // Prevent form submission posting and reloading page
    e.preventDefault();

    // Declare variables and clear errors
    var processEnrollment = true;
    setEmailError(0);
    setErrorMsg('');
    setEnrolled(false);

    // If email is not valid
    // if (email.trim().indexOf('@') < 1 || email.trim().length < 5 || email.trim().indexOf('.') < 1) { setErrorMsg("Enter valid email"); setEmailError(1); processEnrollment = false; }
    if (emailValidator(email.trim()) === null) { setErrorMsg("Enter valid email"); setEmailError(1); processEnrollment = false; }
    
    // If all criteria met, enroll
    if (processEnrollment) {
      // Let user know they are being enrolled
      setEnrolling(true);

      // // Try and get user location data
      // var latitude = '', longitude = '';
      // if ("geolocation" in navigator) {
      //   navigator.geolocation.getCurrentPosition(function(position) {
      //     // console.log("Latitude is :", position.coords.latitude);
      //     // console.log("Longitude is :", position.coords.longitude);
      //     latitude = position.coords.latitude;
      //     longitude = position.coords.longitude;
      //   });
      // }
      // console.log(longitude + ' - ' + latitude);

      // Get user location & IP
      var ip = '', city = '', state = '', postal = '', country = '';
      try {
        var res = await axios.get('https://geolocation-db.com/json/');
        ip = res.data.IPv4;
        city = res.data.city;
        state = res.data.state;
        postal = res.data.postal;
        country = res.data.country_code;
      } catch (e) { console.log('private'); }

      // Add user to waitlist
      const enrollment = await enroll({
        email: email.trim().toLowerCase(),
        ip: ip,
        city: city,
        state: state,
        zip: postal,
        country: country
      });

      setEnrolling(false);
      // If enrollment failed, inform user
      if (Object.keys(enrollment).length > 0 && enrollment.enrolled === 'EXISTS') setErrorMsg("Email already on the waitlist");
      // If enrollment created, clear fields
      else if (Object.keys(enrollment).length > 0 && enrollment.enrolled === 1) {
        document.getElementById('email').value = '';

        setEnrolled(true);
      }
    }
  }
  
  /*
   * Helper function to validate email format
  */
  const emailValidator = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  return (
    <div className="container d-flex justify-content-center mt-5">
      <div className="custom-shadow p-3 w-350 rounded">
        <h6 className="text-header mb-3">We are currently in beta, enter your email to join the waitlist</h6>

        <form onSubmit={handleSubmit} data-bs-theme="dark">
          <div className="form-floating mb-3">
            <input type="text" className={"form-control text-white " + (emailError === 1 ? "border-error" : "")} id="email" placeholder="Email" onChange={e => setEmail(e.target.value)} />
            <label className={"form-control-label-light " + (emailError === 1 ? "text-error" : "")} htmlFor="email"><strong>Email</strong></label>
          </div>
          
          {!enrolling && !enrolled ? <button type="submit" className="btn btn-success w-100 px-4" id="joinBtn" disabled={(enrolling ? true : false)}><strong>Join Waitlist</strong></button> : '' }
        </form>

        {errorMsg !== '' ? <div className="text-danger fs-14 mt-3">{errorMsg}</div> : ''}

        {enrolling ? 
          <div className="text-center mt-5">
            <div className="spinner-grow spinner-grow text-header" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        : ''}

        {enrolled ? <h4 className="text-success text-center mt-5"><strong>Thank you for joining the waitlist! We will be in touch soon</strong></h4> : ''}
      </div>
    </div>
  );
}